export const isItemValid = item => {
    if (item.lot_number && item.expiration_date) {
        const expirationDate = new Date(item.expiration_date);
        return expirationDate.getTime() > Date.now();
    } else {
        return false;
    }
};


// yyyy-mm-dd -> mm-dd-yyyy
export const formatDate = dateString => {
    if (!dateString || dateString.length < 10) {
        return "";
    } else {
        return `${dateString.slice(5, 10)}-${dateString.slice(0, 4)}`;        
    }
};