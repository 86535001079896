import Box from '@mui/material/Box';

import React, { useEffect, useState } from 'react';
import Divider from '@mui/material/Divider';
import WarehouseShipment from './WarehouseShipment';

function ShipPanel(props) {


    return(
        <Box className={props.isMobile ? "mobile-box" : "desktop-box"} style={{width:"100%", height:"50vh"}} display="flex">
            <Box display="flex" style={{height:"100%", marginTop:"15px"}}>
                {props.ky ?
                    <WarehouseShipment {...props} orders={props.orders} items={props.ky_items} trucks={props.ky_trucks} setTrucks={props.setKyTrucks} wh_name="Kentucky" method={props.ky_method} setMethod={props.setKYMethod} />
                : null}
                {props.pa && props.ky ? <Divider sx={{ml:"15px", mr:"15px"}} orientation="vertical" flexItem /> : null}
                {props.pa ? 
                    <WarehouseShipment {...props} orders={props.orders} items={props.pa_items} trucks={props.pa_trucks} setTrucks={props.setPaTrucks} wh_name="Pennsylvania" method={props.pa_method} setMethod={props.setPAMethod} />
                : null}
            </Box>   
        </Box>
    )


}

export default ShipPanel;