import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import orderHelpers from '../../helpers/orders';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import {lighten } from '@mui/material/styles';

const millisecondsPerDay = 1000 * 60 * 60 * 24;


const icons = {
    severe: <ErrorOutlineIcon />,
    warning: <WarningAmberIcon />,
    new: <FiberNewIcon />
}

const columns = [ 
    {
        field: 'vendor_name',
        headerName: 'Vendor',
        flex: 1,
        editable: false,
        minWidth: 200
    }, 
    {
        field: 'orders',
        headerName: 'Unprocessed Orders',
        flex: 1,
        editable: false,
        minWidth: 100
    }, 
    {
        field: 'last_download',
        headerName: 'Last Download',
        flex: 1,
        editable: false,
        minWidth: 150
    },
    {
        field: 'fulfillment_priority',
        headerName: 'Priority',
        flex: 1,
        editable: false,
        minWidth: 100
    },
    {
        headerName: null,
        field: "",
        sortable: false,
        editable: false,
        minWidth: 50,
        renderCell: (params) => {
            return (
                <Box display="flex" style={{width:"100%"}}>
                    <Box style={{margin:"auto"}}>
                    {icons[params.row.icon]}
                    </Box>
                </Box>
            )
        }
    }
]


function ApiStatusTable(props) {
    const [rows, setRows] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);

    const company = props.company;
    const warehouse = props.warehouse;
    const token = props.token;
    const setLoading = props.setLoading;

    useEffect(() => {
        const getVendors = async() => {
            setLoading(true)
            let vendor_orders = await orderHelpers.getUnprocessedOrders(company, warehouse, token);
            let orders = vendor_orders.map((obj, index) => {
                obj.id = index + 1;
                if(!obj.last_download) obj.last_download = "Never";
                if(obj.last_download === "Never") {
                    obj.class = "new";
                    obj.icon = "new"
                    return obj;
                }
                let now = (new Date()).getTime();
                let priority = obj.fulfillment_priority || 3;
                if(!obj.fulfillment_priority) obj.fulfillment_priority = priority;
                let last = obj.last_download;
                let orders = obj.orders;
                let color_icon = {};
                if(priority === 1) {
                    if(last <= now - millisecondsPerDay / 2) color_icon = {color: "severe", icon: "severe"};
                }
                else if(priority === 2) {
                    if(last <= now - millisecondsPerDay) color_icon = {color: "severe", icon: "severe"};
                }
                else if(priority === 3) {
                    if(last <= now - millisecondsPerDay * 3) color_icon = {color: "severe", icon: "severe"};
                    if(last <= now - millisecondsPerDay * 10 && orders === 0) color_icon = {color: "inactive", icon: null};
                }
                if(orders > 100) color_icon = {color: "warning", icon: null};
                if(orders > 500) color_icon = {color: "error", icon: "warning"};
                if(orders > 700) color_icon = {color: "severe", icon: "severe"};
                obj.class = color_icon.color;
                obj.icon = color_icon.icon;
                return obj;
            })
            console.log(orders)
            setRows(orders);
            setLoading(false);
        }
        getVendors();
        return () => {
            setRows([])
        }
    }, [refresh, company, warehouse, token, setLoading])

    
    const downloadOrders = async() => {
        props.setLoading(true);
        let selRows = selectedRows;
        for(let vendor of selRows) {
            console.log("Downloading Orders For ", vendor);
            await orderHelpers.vendorRequest(vendor, "orders", props.company, props.token);
            console.log("Done!");
        }
        setTimeout(() => { props.setLoading(false)}, 5000);
    }

    const sendTracking = async() => {
        props.setLoading(true);
        let selRows = selectedRows;
        for(let vendor of selRows) {
            console.log("Sending Tracking For ", vendor);
            await orderHelpers.vendorRequest(vendor, "shipping", props.company, props.token);
            console.log("Done!");
        }
        setTimeout(() => { props.setLoading(false)}, 5000);
    }

    const sendInvoices = async() => {
        props.setLoading(true);
        let selRows = selectedRows;
        for(let vendor of selRows) {
            console.log("Sending Invoices For ", vendor);
            await orderHelpers.vendorRequest(vendor, "invoice", props.company, props.token);
            console.log("Done!");
        }
        setTimeout(() => { props.setLoading(false)}, 5000);
    }

    const sendInventory = async() => {
        props.setLoading(true);
        let selRows = selectedRows;
        for(let vendor of selRows) {
            console.log("Sending Inventory For ", vendor);
            await orderHelpers.vendorRequest(vendor, "inventory", props.company, props.token);
            console.log("Done!");
        }
        setTimeout(() => { props.setLoading(false)}, 5000);
    }


    return(
        <Box display="flex" flexDirection="column">

        <Box> 
            <Box display="flex">
                {selectedRows.length > 0 ? 
                    <Box style={{width:"30%"}} display="flex">
                        <Box display="flex" flexDirection="column">
                            <Button size="small" disabled={props.isLoading} variant="contained" onClick={()=> downloadOrders()} color="primary" sx={{ml: props.isMobile ? '5px' : '100px', mt: '20px', maxHeight: '45px'}}>Download</Button>
                            <Button size="small" disabled={props.isLoading} variant="contained" onClick={()=> sendTracking()} color="secondary" sx={{ml: props.isMobile ? '5px' : '100px', mt: '20px', maxHeight: '45px'}}>Send Tracking</Button>
                        </Box>
                        <Box display="flex" flexDirection="column">
                            <Button size="small" disabled={props.isLoading} variant="contained" onClick={()=> sendInvoices()} color="warning" sx={{ml: props.isMobile ? '5px' : '100px', mt: '20px', maxHeight: '45px'}}>Send Invoices</Button>
                            <Button size="small" disabled={props.isLoading} variant="contained" onClick={()=> sendInventory()} color="info" sx={{ml: props.isMobile ? '5px' : '100px', mt: '20px', maxHeight: '45px'}}>Send Inventory</Button>
                        </Box>
                    </Box>
                    : null}
                <Button variant='contained' onClick={() => setRefresh(!refresh)} color="success" sx={{ml: props.isMobile ? '20px' : '800px', mt: '20px', maxHeight: '45px'}}>
                    Refresh
                </Button>
            </Box>
            <Box className={props.isMobile ? "mobile-table-box" : "desktop-table-box"} sx={{ 
                    '& .warning': {
                        bgcolor: (theme) => lighten("#FFFF00", 0.5)
                    },
                    '& .error': {
                        bgcolor: (theme) => lighten(theme.palette.warning.light, 0.5)
                    },
                    '& .severe': {
                        bgcolor: (theme) => lighten(theme.palette.error.light, 0.5)
                    },
                    '& .new': {
                        bgcolor: (theme) => lighten(theme.palette.info.light, 0.5)
                    },
                    '& .inactive' : {
                        bgcolor: (theme) => lighten("#D3D3D3", 0.5)
                    }
                }}>
                <DataGrid
                rows={rows}
                className={props.isMobile ? "mobile-table" : "desktop-table"}
                columns={columns}
                rowsPerPageOption = {[25, 50, 100]}
                checkboxSelection = {true}
                onSelectionModelChange = {(ids) => {
                    let filtered = rows.filter((row) => {
                        return ids.includes(row.id) || ids.includes(row.id.toString());
                    })
                    let vendors = filtered.map((obj) => obj.vendor_name)
                    setSelectedRows(vendors);
                }}
                getRowClassName={(params) => params.row.class}
                sortModel = {[{field:'fulfillment_priority', sort: 'asc'}]}
                />
            </Box>
        </Box>
    </Box>
    )
    
}

export default ApiStatusTable;