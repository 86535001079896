import {React, useState} from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

function InfoPopup(props) {

    

        const handleChange = async(e, index) => {
            let temp = props.customer;
            temp[index] = e.target.value;
            props.setCustomer(temp);
        }


        return(
            <Dialog open={props.open} onClose={()=>props.handleClose()}>
            <DialogTitle>
                    Update Customer's Shipping Info:
            </DialogTitle>
            <DialogContent>
                <Box>
                    <TextField sx={{m:"8px"}} label="Name" defaultValue={props.customer.name} onChange={(e)=>handleChange(e, "name")} />
                    <TextField sx={{m:"8px"}} label="Address" defaultValue={props.customer.address1} onChange={(e)=>handleChange(e, "address1")} />
                    <TextField sx={{m:"8px"}} label="Address 2" defaultValue={props.customer.address2} onChange={(e)=>handleChange(e, "address2")} />
                    <TextField sx={{m:"8px"}} label="City" defaultValue={props.customer.city} onChange={(e)=>handleChange(e, "city")} />
                    <TextField sx={{m:"8px"}} label="State" defaultValue={props.customer.state} onChange={(e)=>handleChange(e, "state")} />
                    <TextField sx={{m:"8px"}} label="Zip Code" defaultValue={props.customer.zip_code} onChange={(e)=>handleChange(e, "zip_code")} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>props.handleClose()}>Cancel</Button>
                <Button onClick={()=>props.updateCustomer(props.customer)} autoFocus>
                    Update
                </Button>
            </DialogActions>
        </Dialog>
        )
}

export default InfoPopup;