import {React, useState, useEffect} from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import bulkHelpers from '../../../helpers/bulk';

function ShippingPopup(props) {

        const [shipment, setShipment] = useState({});
        const [carriers, setCarriers] = useState([])


        const [carrier, setCarrier] = useState("");
        const [pro, setPro] = useState("");
        const [bol, setBol] = useState("");
        const [ship, setShip] = useState("");
        const [expected, setExpected] = useState("");
        const [truck, setTruck] = useState("");
        const [price, setPrice] = useState("");
        const [acu, setAcu] = useState("");
        const [pallets, setPallets] = useState("");

        useEffect(() => {
            let temp = {}
            const getCarriers = async() => {
                props.setLoading(true);
                let temp = await bulkHelpers.getCarriers(props.company + "_bulk", props.token);
                console.log(temp);
                setCarriers(temp);
                props.setLoading(false);
            }
            getCarriers();
            setPro(props.order?.pro_number || "")
            setBol(props.order?.bol || "")
            setShip(props.order?.ship_date || "")
            setExpected(props.order?.expected_delivery || "")
            setTruck(props.order?.truck_number || "")
            setPrice(props.order?.shipment_price || "")
            setAcu(props.order?.acu_ship_num || "")
            setPallets(props.order?.total_on_shipments || "")
            setCarrier(props.order?.carrier || "")
        }, [])


        const updateShipment = async() => {
            props.setLoading(true);
            try {
                if(pro && bol && ship && expected && carrier && truck) {
                    let shipment = {
                        pro_number: pro,
                        bol: bol,
                        carrier: carrier,
                        ship_date: ship,
                        expected_delivery: expected,
                        truck_number: truck,
                        shipment_price: price,
                        acu_ship_num: acu,
                        total_on_shipment: pallets
                    }
                    await bulkHelpers.updateShipment(shipment, props.order.vendor_name, props.order.internal_po_number, props.token, props.company + "_bulk");
                    props.handleClose(true);
                }
            }
            catch(err) {
                console.log(err);
            }
            finally {
                props.setLoading(false);
            }
        }


        return(
            <Dialog open={props.open} onClose={()=>props.handleClose()}>
            <DialogTitle>
                    Update Order Shipment Info:
            </DialogTitle>
            <DialogContent>
                <Box>
                    <TextField sx={{m:"8px"}} label="Pro Number *" value={pro} onChange={(e)=>setPro(e.target.value)} />
                    <Select sx={{m:"8px", width:"225px"}} label="Carrier *" defaultValue={carrier || ""} value={carrier} onChange={(e)=>setCarrier(e.target.value)}>
                        {carriers.map((obj, index) => {
                            return <MenuItem value={obj.scac} key={index}>{obj.shorthand}</MenuItem>
                        })}
                    </Select>
                    <TextField sx={{m:"8px"}} label="BOL *" value={bol} onChange={(e)=>setBol(e.target.value)} />
                    <TextField sx={{m:"8px"}} InputLabelProps={{ shrink: true }} type="date" label="Ship Date *" value={ship} onChange={(e)=>setShip(e.target.value)} />
                    <TextField sx={{m:"8px"}} InputLabelProps={{ shrink: true }} type="date" label="Expected Delivery *" value={expected} onChange={(e)=>setExpected(e.target.value)} />
                    <TextField sx={{m:"8px"}} label="Truck Number *" value={truck} onChange={(e)=>setTruck(e.target.value)} />
                    <TextField sx={{m:"8px"}} label="Shipment Price" value={price} onChange={(e)=>setPrice(e.target.value)} />
                    <TextField sx={{m:"8px"}} label="Acumatica Shipment Nbr" value={acu} onChange={(e)=>setAcu(e.target.value)} />
                    <TextField sx={{m:"8px"}} label="Total Pallets On Shipment" value={pallets} onChange={(e)=>setPallets(e.target.value)} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>props.handleClose()}>Cancel</Button>
                <Button onClick={()=>updateShipment()} autoFocus>
                    Update
                </Button>
            </DialogActions>
        </Dialog>
        )
}

export default ShippingPopup;