import React, { useEffect } from 'react';
import { BarChart, XAxis, YAxis, Tooltip, Bar, CartesianGrid, Cell, Legend} from 'recharts';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';


function EfficiencyTable(props) {

    useEffect(() => {
    }, [])

    let chartWidth = 550;
    let chartHeight = 500;
    let cardFont = 25;
/**

 *
 * @param   Number  h       The hue
 * @param   Number  s       The saturation
 * @param   Number  l       The lightness
 * @return  Array           The RGB representation
 */
const hslToRgb = (h, s, l) =>{
    var r, g, b;

    if(s === 0){
        r = g = b = l; 
    }else{
        function hue2rgb(p, q, t){
            if(t < 0) t += 1;
            if(t > 1) t -= 1;
            if(t < 1/6) return p + (q - p) * 6 * t;
            if(t < 1/2) return q;
            if(t < 2/3) return p + (q - p) * (2/3 - t) * 6;
            return p;
        }

        var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        var p = 2 * l - q;
        r = hue2rgb(p, q, h + 1/3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1/3);
    }

    return [Math.floor(r * 255), Math.floor(g * 255), Math.floor(b * 255)];
}

const perc2color = (i, min, max) => {
    var ratio = i;
    if (min> 0 || max < 1) {
        if (i < min) {
            ratio = 0;
        } else if (i > max) {
            ratio = 1;
        } else {
            var range = max - min;
            ratio = (i-min) / range;
        }
    }
    
    var hue = ratio * 1.2 / 3.60;

    // we convert hsl to rgb (saturation 100%, lightness 50%)
    var rgb = hslToRgb(hue, 1, .4);
    // we format to css value and return
    return 'rgb(' + rgb[0] + ',' + rgb[1] + ',' + rgb[2] + ')'; 
}


    return(
        <Box className={props.isMobile ? "mobile-table-box" : "desktop-table-box"} style={{width:"70%"}}>
            <Box display="flex">
            {props.averageDayBuilds.total !== undefined ? (
                <Card>
                    <CardContent>
                    <Typography sx={{ fontSize: cardFont }} color="text.secondary" gutterBottom>
                        Avg Build/Day: {props.averageDayBuilds.total}
                    </Typography>
                    </CardContent>
                </Card>
                ) : null}

                {props.averageDayBuilds.assembly !== undefined ? (
                <Card>
                    <CardContent>
                    <Typography sx={{ fontSize: cardFont }} color="text.secondary" gutterBottom>
                        Touch/Day: {props.averageDayBuilds.assembly}
                    </Typography>
                    </CardContent>
                </Card>
                ) : null}

                {props.averageDayBuilds.no_touch !== undefined ? (
                <Card>
                    <CardContent>
                    <Typography sx={{ fontSize: cardFont }} color="text.secondary" gutterBottom>
                        No Touch/Day: {props.averageDayBuilds.no_touch}
                    </Typography>
                    </CardContent>
                </Card>
                ) : null}



                {props.averageHoursDay ? <Card>
                    <CardContent>
                        <Typography sx={{ fontSize: cardFont }} color="text.secondary" gutterBottom>
                            Avg Hours/Day: {props.averageHoursDay}
                        </Typography>  
                    </CardContent>   
                </Card> : null}
                {props.totalEfficiency ? <Card>
                    <CardContent>
                        <Typography sx={{ fontSize: cardFont }} color="text.secondary" gutterBottom>
                            Efficiency: {props.totalEfficiency}
                        </Typography>  
                    </CardContent>   
                </Card> : null}
            </Box>
            <Box display="flex" justifyContent="space-between">
                <BarChart width={900} height={chartHeight} data={Object.entries(props.efficiency)} margin={{ top: 50, right: 0, bottom: 0, left: 200 }}>
                    <XAxis dataKey="0" label={{ value: "Line", position: "insideBottom", dy: 20 }} />
                    <YAxis dataKey="1" label={{ value: "Efficiency", angle: -90, position: "insideLeft", dx: -20 }} />
                    <Tooltip />
                    <Bar
                        dataKey="1"
                    >
                        {
                            Object.entries(props.efficiency).map(([key, value]) => {
                                let fillColor = perc2color(value/100, .45 , 1);
                                return (
                                    <Cell
                                        key={key}
                                        fill={fillColor}
                                    />
                                );
                            })
                        }
                    </Bar>
                </BarChart>
            </Box>

            <Box display = "flex" justifyContent="space-between">
                <BarChart 
                    width={chartWidth+150} 
                    height={chartHeight}   
                    data={Object.entries(props.averageBuildBreakdown).map(([key, values]) => ({ name: key, assembly: values.assembly, no_touch: values.no_touch })).filter(entry => entry.assembly !== 0 || entry.no_touch !== 0)}  
                    margin={{ top: 20, right: 50, bottom: 50 }} >

                    <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" label={{ value: "Line", position: "insideBottom", dy: 20 }} />
                        <YAxis label={{ value: "Daily Builds", angle: -90, position: "insideLeft", dx: -20 }} />
                        <Tooltip />
                        <Bar dataKey="assembly" stackId="builds" fill="#8884d8" />
                        <Bar dataKey="no_touch" stackId="builds" fill="#82ca9d" />
                   <Legend layout="vertical" align="left" verticalAlign="top"/>
                </BarChart>
                <BarChart width={chartWidth+50} height={chartHeight} data={Object.entries(props.averageHoursBreakdown)} margin={{ top: 20, bottom: 50, left: 0 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="0" label = {{value: "Line", postion: "insideBotom", dy: 20}}/>
                        <YAxis dataKey="1" label = {{value: "Hours", angle: -90, postion: "insideLeft", dx: -20}}/>
                        <Tooltip />
                        <Bar dataKey="1"  fill="#8884d8"/> 
                </BarChart>
            </Box>
        </Box>
    )
}

export default EfficiencyTable;