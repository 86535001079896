
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import inventoryHelpers from '../../helpers/inventory';
import JobsTable from './JobsTable';
import { TextractDocument } from "amazon-textract-response-parser";

function ExtractText(props) {
    const [error, setError] = useState("");
    const [files, setFiles] = useState([])
    const [jobs, setJobs] = useState([]);
    const [refresh, setRefresh] = useState(false);


    useEffect(() => {
        const getJobs = async() => {
            let jobs = await inventoryHelpers.getExtractJobs(props.token, props.company);
            setJobs(jobs.jobs)
        }
        getJobs()
    }, [refresh])

    const downloadData = async(job_id, file_name) => {
        props.setLoading(true)
        setError("");
        let res;
        try {
            res = await inventoryHelpers.downloadJob(job_id, props.company, props.token);
        }
        catch(err) {
            console.log(err);
            setError("File Not Ready Yet - Try again in a few minutes.")
            props.setLoading(false);
            return;
        }
        try {
            const zlib = require("zlib");
            let uncompressed =  JSON.parse(zlib.inflateSync(Buffer.from(res.data, 'base64')));;
            const doc = new TextractDocument(uncompressed);
            let out = [];
            for(const page of doc.iterPages()) {
                for(let i = 0; i < page.nTables; i++) {
                    let table = page.tableAtIndex(i);
                    for(const row of table.iterRows()) {
                        let out_row = [];
                        for(const cell of row.iterCells()) {
                            out_row.push(cell.text);
                        }
                        if((out.length > 0 && out_row[0] === out[0][0]) || (out_row[0] === "CHECK NUMBER") || (out_row[3] === "FURHAVEN PET PRODUCTS")) continue;
                        out.push(out_row);
                    }
                }
            }
            let csv = out.map(row => row.map(String).map(obj => obj.replaceAll('"', '""')).map(obj=> `"${obj}"`).join(',')).join("\r\n");
            let file = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
            let a = document.createElement("a");
            let url = URL.createObjectURL(file);
            a.href = url;
            a.download = file_name.replace(".pdf", "") + ".csv";
            a.click();
        }
        catch(err) {
            console.log(err);
            setError("Error Processing Results.");
        }
        finally {
            props.setLoading(false);
        }
        
    }

    const extractText = async() => {
        for(let file of files) {
            console.log(file)
            let fileReader = new FileReader();
            fileReader.onload = async() => {
                let body = JSON.stringify({file: Buffer.from(fileReader.result), file_name: file.name});
                console.log(body);
                let res = await inventoryHelpers.extractText(body, props.company, props.token);
                console.log(res);
            }
            fileReader.readAsArrayBuffer(file)
        }
    }


    return(
        <Box display="flex" flexDirection="column" className={props.isMobile ? "mobile-top" : "desktop-top"}>
            <Box className={props.isMobile ? "mobile-box" : "desktop-box"}>
                <h1>PDF Extract Text</h1> 
                {error !== "" ? <Alert style={{marginTop:"24px", color:"white", width:"80%"}} severity="error" className={`${props.company} ${props.warehouse} error`}>{error}</Alert> : null}
                <input type="file" name="file" onChange={(e) => setFiles(e.target.files)} />
                <Button onClick={()=>extractText()}>Extract</Button>
                <JobsTable downloadData={downloadData} refresh={refresh} setRefresh={setRefresh} jobs={jobs} {...props} />
            </Box>
        </Box>
    )
}

export default ExtractText