import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import warehouseHelpers from '../../helpers/warehouse';
import EfficiencyTable from './EfficiencyTable';
import EfficiencyReportQueryForm from './EfficiencyReportQueryForm';


function EfficiencyReport(props) {


    const [warehouse, setWarehouse] = useState([]);
    const [shift, setShift] = useState([]);
    const [builds, setBuilds] = useState([]);
    const [averageBuildBreakdown, setAverageBuildBreakdown] = useState([]);
    const [averageHoursBreakdown, setAverageHoursBreakdown] = useState([]);
    const [averageStaffBreakdown, setAverageStaffBreakdown] = useState([]);
    const [totalBuilds, setTotalBuilds] = useState([]);
    const [hourlyBuilds, setHourlyBuilds] = useState([]);
    const [averageDayBuilds, setAverageDayBuilds] = useState([]);
    const [averageWorkerDay, setAverageWorkerDay] = useState([]);
    const [averageHoursDay, setAverageHoursDay] = useState([]);
    const [startDate, setStartDate] = useState([]);
    const [endDate, setEndDate] = useState([]);
    const [efficiency, setEfficiency] = useState([]);
    const [totalEfficiency, setTotalEfficiency] = useState([]);


    const _ = require('lodash');

    useEffect(() => {
        setShift("Both");
    }, [])


    const search = async() => {
        props.setLoading(true);
        let download_builds = await downloadBuilds()
        let workers = await downloadWorkers()
        let num_of_days = await findDayDifference();
        await getWorkers(workers, num_of_days);
        await getBuilds(download_builds, num_of_days);
        await getEfficiencyByGroup();
        props.setLoading(false);

    }

    const downloadBuilds = async() => {
        let params = await getParams();
        let res = await warehouseHelpers.getBuildsByWarehouse(props.company, props.token, params);
        return res;
    }

    const getBuilds = async(download_builds, num_of_days) => {
        let filtered_builds  = await separateShifts(download_builds, 'build_date');
        let hourly_builds  = await seperateByHour(download_builds, 'build_date');
        let hourly_sum = await calculateHourlySum(hourly_builds);
        let type_builds = await separateTouchNoTouch(filtered_builds);
        let group_sum = await calculateSumOfEachGroup(type_builds);
        let avg_build_breakdown = await calculateAverageGroupBuild(group_sum, num_of_days);
        let totals = await calculateTotalBuild(group_sum);

        let averages = await calculateAverageBuild(totals, num_of_days);

        setHourlyBuilds(hourly_sum);
        setBuilds(group_sum);
        setAverageBuildBreakdown(avg_build_breakdown);
        setTotalBuilds(totals);
        setAverageDayBuilds(averages);
    }

    const downloadWorkers = async() => {
        let params = await getParams();
        let res = await warehouseHelpers.getWorkersByGroup(props.company, props.token, params);
        return res;
    }

    const getWorkers = async(res, num_of_days) => {
        if(res){

            let workers_by_day = await groupObjects(res.workers, 'day');

            let workers_by_day_shift = await separateShifts(workers_by_day, 'time_on');

            let sums_and_averages = await getDistinctNumberofWorkersandHours(workers_by_day_shift,);
            let average_staff_day = await calculateAverageStaff(sums_and_averages, num_of_days);
            let average_hours_day = await calculateAverageHours(sums_and_averages, num_of_days);

            let workers_by_group = await groupObjects(res.workers, 'group');

            let workers_by_group_shift = await separateShifts(workers_by_group, 'time_on')
            let sums_and_averages_by_group = await getDistinctNumberofWorkersandHours(workers_by_group_shift);
            let average_staff_breakdown = await calculateAverageStaffBreakdown(sums_and_averages_by_group, num_of_days);
            let average_hours_breakdown = await calculateAverageHoursBreakdown(sums_and_averages_by_group, num_of_days);


            setAverageWorkerDay(average_staff_day);
            setAverageHoursDay(average_hours_day);
            setAverageStaffBreakdown(average_staff_breakdown);
            setAverageHoursBreakdown(average_hours_breakdown);
        }
    }

    const separateShifts = async(arr, attr) => {
        if(shift === 'Both') return arr;
        let filtered_arr = {};
        for(let key in arr){
            filtered_arr[key] = arr[key].filter(obj => {
                let time_on_date = new Date(obj[attr]);
                time_on_date.setHours(time_on_date.getHours() + 8);
                let first_shift_date_start = new Date(time_on_date);
                first_shift_date_start.setHours(4, 0, 0, 0);
                let first_shift_date_end = new Date(time_on_date);
                first_shift_date_end.setHours(16, 0, 0, 0);
                if(shift === 'First'){
                    return time_on_date > first_shift_date_start && time_on_date < first_shift_date_end;
                }    
                else{
                    return time_on_date > first_shift_date_end || time_on_date < first_shift_date_start;
                }
            });

        }
        return filtered_arr;
    }

    const seperateByHour = async(input_data, attr) => {
        let result = {};
        let data = Array.isArray(input_data) ? input_data : Object.entries(input_data);
        data.forEach(([key, values]) => {
            let separated_data = {};
        
            for (let hour = 0; hour < 24; hour++) {
                separated_data[hour] = [];
            }
        
            values.forEach(obj => {
                let hour = new Date(obj[attr]).getUTCHours();
                separated_data[hour].push(obj);
            });
        
            result[key] = separated_data;
            });
        return result;
    }
        

    const separateTouchNoTouch = async(builds) => {
        let split_builds = {};
        for (let key in builds) {
            let assembly = [];
            let no_touch = [];
            for (let obj of builds[key]) {
                if (obj.labor_time === null) {
                    no_touch.push(obj);
                } else {
                    assembly.push(obj);
                }
            }
            split_builds[key] = {
                assembly: assembly,
                no_touch: no_touch
            };
        }

        return split_builds;
    }

    const getParams = async() => {
        await setDefaultDateValues();
        let formatted_state_date = await warehouseHelpers.convertDate(startDate, -4);
        let formatted_end_date = await warehouseHelpers.convertDate(endDate, 20);
        let params = {
            wh: warehouse,
            start_date: formatted_state_date, 
            end_date: formatted_end_date,
            shift: shift
        }
        return params;
    }

    const findDayDifference = async() => {
        let day_difference = (endDate - startDate)/86400000
        day_difference = Math.ceil(day_difference) + 1;
        return day_difference;
    }

    const setDefaultDateValues = async() => {
        let today = new Date();
        today.setHours(0,0,0,0);
        if(startDate.length === 0){
            setStartDate(today);
        }
        if(endDate.length === 0){
            let tomorrow = new Date(today);
            tomorrow.setDate(today.getDate() + 1);
            tomorrow.setHours(5,0,0,0);
            setEndDate(tomorrow);
        }
    }

    const calculateTotalBuild = async(builds) => {
        let totals = { total: 0, no_touch_totals: 0, assembly_totals: 0 };
        for (let key in builds) {
            totals.total += builds[key].total;
            totals.no_touch_totals += builds[key].no_touch;
            totals.assembly_totals += builds[key].assembly;
          }
        return totals;
    }

    const calculateAverageBuild = async(totals, num_of_days) => {
        let totals_no_touch = totals.no_touch_totals/num_of_days;
        let totals_assembly = totals.assembly_totals/num_of_days;
        let total = totals.total/num_of_days;
        let total_to_return = {
            no_touch: totals_no_touch.toFixed(2),
            assembly: totals_assembly.toFixed(2),
            total: total.toFixed(2)
        }
        return total_to_return;
    }

    const getDistinctNumberofWorkersandHours = async(obj) => {
        let result_obj = {};
        for (let key in obj) {
            let result = obj[key];
            let grouped_result = {};
            
            for (let obj of result) {
                let key2 = obj.group;
    
                if (!grouped_result[key2]) {
                    grouped_result[key2] = {
                        total_time: 0,
                        uniqueWorkerIds: new Set(),
                    };
                }
                if (!grouped_result[key2].uniqueWorkerIds.has(obj.worker_id)) {
                    grouped_result[key2].uniqueWorkerIds.add(obj.worker_id);
                }
                grouped_result[key2].total_time += await convertToSeconds(obj.time_difference);
            }
    
            result_obj[key] = grouped_result;
        }
        return result_obj;
    }

    const calculateAverageStaff = async(obj, num_of_days) =>{
        let sum = 0;
        for(let key in obj){
            for(let key2 in obj[key]){
                sum += obj[key][key2].uniqueWorkerIds.size;
            }
        }
        return (sum/num_of_days).toFixed(2);
    }

    const calculateAverageHours = async(obj, num_of_days) =>{
        let sum = 0;
        for(let key in obj){
            for(let key2 in obj[key]){
                sum += obj[key][key2].total_time;
            }
        }
        let avg_time_secs = sum/num_of_days;
        let avg_time_hours = avg_time_secs/3600;
        return avg_time_hours.toFixed(2);
    }

    const calculateSumOfEachGroup = async(res) => {
        let sum_builds = {};
        for (let key in res) {
            let quantities_assembly = res[key].assembly.map(item => item.quantity);
            let total_quantity_assembly = quantities_assembly.reduce((sum, quantity) => sum + quantity, 0);

            let quantities_no_touch = res[key].no_touch.map(item => item.quantity);
            let total_quantity_no_touch = quantities_no_touch.reduce((sum, quantity) => sum + quantity, 0);

            sum_builds[key] = {
                assembly: total_quantity_assembly,
                no_touch: total_quantity_no_touch,
                total: total_quantity_assembly + total_quantity_no_touch
            };
          }
        return sum_builds;
    }

    const calculateHourlySum = async(res) => {
        let result = {};

        Object.entries(res).forEach(([key, values]) => {
          let summed_Data = {};
          Object.entries(values).forEach(([hour, objects]) => {
            let total_quantity = objects.reduce((sum, obj) => sum + (obj.quantity || 0), 0);
            summed_Data[hour] = total_quantity;
          });
          result[key] = summed_Data;
        });
      
        return result;
      }

    const calculateAverageGroupBuild = async(sums, num_of_days) => {
        let ave_builds = {};
        for (let key in sums){
            if(!_.isEmpty(sums[key])){
                let avg_assembly = sums[key].assembly/num_of_days;
                let avg_no_touch = sums[key].no_touch/num_of_days;
                ave_builds[key] = {
                    assembly: avg_assembly.toFixed(2),
                    no_touch: avg_no_touch.toFixed(2)
                };
            }
        }
        return ave_builds;
    }

    const calculateAverageHoursBreakdown = async(sums, num_of_days) => {
        let ave_builds = {};
        for (let key in sums) {
            if(!_.isEmpty(sums[key])){
                let inner_object = sums[key][key];
                ave_builds[key] = (inner_object.total_time/3600)/num_of_days;
                ave_builds[key] = parseFloat(ave_builds[key].toFixed(2));
            }
        }
        return ave_builds;
    }

    const calculateAverageStaffBreakdown = async(sums, num_of_days) => {
        let ave_builds = {};
        for (let key in sums) {
            if(!_.isEmpty(sums[key])){
                let inner_object = sums[key][key];
                ave_builds[key] = inner_object.uniqueWorkerIds.size/num_of_days;
            }
        }
        return ave_builds;
    }

    const groupObjects = async(workers, groupBy) => {
        return workers.reduce((acc, obj) => {
            let group_key = obj[groupBy];
            if(!acc[group_key]) acc[group_key] = [];
            acc[group_key].push(obj);
            return acc;
        }, {})
    }

    const convertToSeconds = async(time) => {
        let [hours, minutes, seconds] = time.split(':').map(Number);
        let total_seconds = hours * 3600 + minutes * 60 + seconds;
        return total_seconds;
    };

    const getEfficiencyByGroup = async() => {
        let params = {};
        params = await getParams();
        let res = await warehouseHelpers.getEfficiencyBuild(props.company, props.token, params);
        if(res.data.efficiency) {
            let efficiency_breakdown = await fixToTwoDecimals(res.data.efficiency);
            setEfficiency(efficiency_breakdown);
        }
        res.data.efficiency.total > 0 ? setTotalEfficiency((res.data.efficiency.total)) : setTotalEfficiency(0);
        return res;
    }

    const fixToTwoDecimals = async(arr) => {
        for(let val in arr){
            arr[val] = arr[val] ? parseFloat(arr[val].toFixed(2)) : 0;
        }
        return arr;
    }

    return(
        <Box display="flex" flexDirection="column" className={props.isMobile ? "mobile-top" : "desktop-top"}>
            <Box className={props.isMobile ? "mobile-box" : "desktop-box"}>
                <h1>Efficiency Report</h1> 
                <EfficiencyReportQueryForm
                    warehouse={warehouse}
                    setWarehouse={setWarehouse}
                    shift={shift}
                    setShift={setShift}
                    search={search}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    startDate={startDate?.full}
                    endDate={endDate?.full}
                 />
                {totalBuilds.total > 0 ? <EfficiencyTable  
                    getBuilds={getBuilds}
                    getWorkers={getWorkers}
                    totalBuilds={totalBuilds}
                    builds={builds}
                    hourlyBuilds={hourlyBuilds}
                    averageBuildBreakdown={averageBuildBreakdown}
                    averageStaffBreakdown={averageStaffBreakdown}
                    averageHoursBreakdown={averageHoursBreakdown}
                    efficiency={efficiency}
                    totalEfficiency={totalEfficiency}
                    averageWorkerDay={averageWorkerDay}
                    averageHoursDay={averageHoursDay}  
                    averageDayBuilds={averageDayBuilds}
                    {...props} /> : null }
            
            </Box>
        </Box>
    )
}

export default EfficiencyReport;