import React, {useRef, useState, useEffect} from 'react';
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box';
import bulkHelpers from '../../helpers/bulk'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button';



function BulkInvoiceDetails(props) { 

    const [order, setOrder] = useState(props.order || {});
    const [cost, setCost] = useState(props.order?.invoice_cost);
    const [invoiceNum, setInvoiceNum] = useState(props.order?.acu_invoice_num);

    useEffect(() => {
        if(props.order) {
            setCost(props.order.invoice_cost);
            setInvoiceNum(props.order.acu_invoice_num)
            setOrder(props.order);
        }
        else {
            setOrder({})
            setCost(null)
            setInvoiceNum(null)
        }
    }, [props.order])

    const saveInvoiceInfo = async() => {
        props.setLoading(true);
        props.toast.promise(saveInvoiceInfoHelper(), {
            success: () => {
                props.setLoading(false);
                props.setOpenDetails([])
                return "Successfully Updated Invoice";
            },
            error: (err) => {
                props.setLoading(false);
                console.log(err);
                return err.message;
            }
        })
    }

    const saveInvoiceInfoHelper = async() => {
        await bulkHelpers.updateBulkInvoice({acu_invoice_num: invoiceNum, invoice_cost: cost, internal_po_number: order.internal_po_number, vendor_name: order.vendor_name}, props.company + "_bulk", props.token)
    }

    return (
        <Drawer open={Object.keys(order).length > 0} onClose={props.setOpenDetails} anchor="bottom">
            <Box display="flex" flexDirection="column"  className={props.isMobile ? "mobile-table-box" : "desktop-table-box"}  style={{height: "600px", width:"100%"}}>
                <Box display="flex" style={{marginLeft: "auto", marginRight: "auto"}}>
                    <TextField label="Acumatica Invoice Cost" value={cost} onChange={(e) => setCost(e.target.value)} style={{marginRight: "20px"}} />
                    <TextField label="Invoice Number" value={invoiceNum} onChange={(e) => setInvoiceNum(e.target.value)} />
                </Box>
                <Button disabled={props.loading} variant="contained" style={{width:"24.5%", marginLeft: "auto", marginRight: "auto", marginTop:"20px"}} onClick={()=>saveInvoiceInfo()}>Save</Button>

            </Box>   
        </Drawer>
    )

}

export default BulkInvoiceDetails;


