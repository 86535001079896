import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import bulkHelpers from "../../helpers/bulk";


function UploadForm(props) {
    const [file, changeFile] = useState(null)
    const [ordernbr, setOrderNbr] = useState(null);

    function clearFile() {
        changeFile(null);
    } 

    const createFromOrdernbr = async(nbr) => {
        props.setLoading(true);
        await bulkHelpers.createLabelsForOrder(nbr, setOrderNbr, props.company + "_bulk", props.user.name, props.token)
        setOrderNbr(null)
        document.getElementById("ordernbr").value = null;
        props.setLoading(false);
    }

    return (
        <Box display="flex" flexDirection="column" className={props.isMobile ? "mobile-box" : "desktop-box"}>
            {(file && !ordernbr) || (!ordernbr && !file) ? <Box display="flex" flexDirection="column"><input disabled={ordernbr} onChange={(event) => changeFile(event.target.files[0])} accept=".csv, .xlsx, .xlx" className="fileUpload" style={{display: 'none'}} id="contained-button-file" type="file" />
                <label htmlFor="contained-button-file">
                    <Button style={{width:props.isMobile ? "100px" : "50%"}} color="success" variant="contained" component="span" className="uploadButton">Upload</Button>
                </label>
                {file && !ordernbr ? <Box display="flex" style={{margin:"auto", marginTop:"10px"}}><h4 style={{margin:"auto"}}>{file.name}</h4> <Button variant="outlined" color="error" size="small" style={{fontSize:"10px", margin:"auto", marginLeft:"10px"}} onClick={()=>clearFile()}>Delete</Button></Box> 
                : 
                null} 
                <Button disabled={props.isLoading || file === null} style={{marginTop:"20px", width:props.isMobile ? "100px" : "50%"}} variant="contained" color="secondary" onClick={() => {bulkHelpers.submitFile(file, changeFile, props.company + "_bulk", props.user.name, props.token)}}>Submit</Button>
            </Box>: null}
            {(ordernbr && !file) || (!ordernbr && !file) ? <Box display="flex" flexDirection="column" style={{marginTop:"20px"}} className={props.isMobile ? "mobile-box" : "desktop-box"}>
                <label for="ordernbr">Acumatica Order Number</label>
                <input onChange={(e) => setOrderNbr(e.target.value)} disabled={file} id="ordernbr" type="text" style={{width:"50%"}} />
                <Button disabled={props.isLoading || !ordernbr} style={{marginTop:"20px", width:props.isMobile ? "100px" : "50%"}} variant="contained" color="secondary" onClick={() => {createFromOrdernbr(ordernbr)}}>Submit</Button>
            </Box>
            : null}
        </Box>
    )
}

export default UploadForm;