import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import ChangeTable from './ChangeTable';
import ChangeDetails from './ChangeDetails';
import bulkHelpers from '../../helpers/bulk';
function ChangeOrders(props) {

        const [selected, setSelected] = useState([]);
        const [selectedRequest, setSelectedRequest] = useState(null);
        const [error, setError] = useState("")
        const [requests, setRequests] = useState([]);
        const [refresh, setRefresh] = useState(false);

        const handleSelect = async(obj) => {
            setSelectedRequest(requests.filter(inner => inner.id === obj[0])[0]);
            setSelected(obj.filter(entry => !selected.includes(entry)))
        }


        const handleClose = async() => {
            setSelected([]);
            setSelectedRequest(null);
        }

        useEffect(() => {
            console.log("downloadRequests");
            const downloadRequests = async() => {
                try {
                    props.setLoading(true);
                    setError("");
                    let req = await bulkHelpers.getOrderChangeRequests(props.token, props.company + "_bulk");
                    let out = {};
                    for(let row of req.data) {
                        if(!(row.request_id in out)) {
                            out[row.request_id] = {
                                request_id: row.request_id,
                                change_date: row.change_date,
                                internal_po_number: row.internal_po_number,
                                request_status: row.request_status,
                                type: row.type,
                                vendor_name: row.vendor_name,
                                details: []
                            }
                        }
                        out[row.request_id].details.push({
                            type: row.sub_type,
                            detail: row.detail,
                            value: row.value
                        })
                    }
                    let arr = [];
                    let index = 1;
                    for(let id in out) {
                        out[id].id = index;
                        arr.push(out[id]);
                        index++;
                    }
                    setRequests(arr);
                    console.log(arr)
                }
                catch(err) {
                    setError(err.message);
                    console.log(err);
                }
                finally {
                    props.setLoading(false);
                }
            }
            downloadRequests()
        }, [refresh])
        return(
            <Box display="flex" flexDirection="column" className={props.isMobile ? "mobile-top" : "desktop-top"}>
                <Box className={props.isMobile ? "mobile-box" : "desktop-box"}>
                    <h1>Incoming Order Change Requests</h1> 
                    <Button onClick={()=>setRefresh(!refresh)} variant="contained" >Refresh</Button>
                    {error !== "" ? <Alert style={{marginTop:"24px", color:"white", width:"80%"}} severity="error" className={`${props.company} ${props.warehouse} error`}>{error}</Alert> : null}
                    <ChangeTable selected={selected} handleSelect={handleSelect} requests={requests} {...props} />
                    <ChangeDetails open={selected.length === 1} order={selectedRequest} handleClose={handleClose} {...props} />
                </Box>
            </Box>
        )
    
}

export default ChangeOrders