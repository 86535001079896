import Box from '@mui/material/Box';

import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import TruckPopup from './TruckPopup';

function WarehouseShipment(props) {

    const [openTrucks, setOpenTrucks] = useState(false);
    const [truck_max, setTruckMax] = useState({});
    const [truckTab, setTruckTab] = useState(0);

    useEffect(()=> {
        let temp = {};
        for(let item of props.items) {
            let qty = item.case_packs
            temp[item.internal_sku] = qty;
        }
        setTruckMax(temp);
    }, []);

    const handleTruckChange = async(qty, index, sku) => {
        let temp = [...props.trucks];
        let truck = temp[index];
        if((truck_max[sku] === 0 && (truck[sku] < Number(qty) || !truck[sku]))) return;
        console.log(truck)
        if(!(sku in truck)) {
            truck[sku] = 0;
        }
        truck[sku] = Number(qty);
        console.log(temp)
        props.setTrucks(temp);
        await adjustMax(sku)
    }

    const setTruckCount = async(count) => {
        let out = [];
        for(let i = 0; i < count; i++) {
            out.push({})
        }
        props.setTrucks(out);
    }

    
    const adjustMax = async(sku) => {
        let total = props.items.filter(obj => obj.internal_sku === sku).reduce((a, b) => a + b.case_packs, 0);
        for(let truck of props.trucks) {
            let qty = truck[sku] || 0;
            total -= qty;
        }
        let temp = truck_max;
        temp[sku] = total;
        setTruckMax(temp);
    }

    const saveTrucks = async() => {
        props.setLoading(true);
        let temp = props.orders;
        for(let order of temp) {
            let decided = order.items.filter(obj => obj.decision === props.wh_name);
            let skus = [...new Set(decided.map(obj => obj.internal_sku))]
            for(let sku of skus) {
                let items = decided.filter(obj => obj.internal_sku === sku);
                let j = 1;
                for(let truck of props.trucks) {
                    for(let i = 0; i < truck[sku] || 0; i++) {
                        items[i].truck = j;
                    }     
                    j++;               
                }
            }
        }
        props.setOrders(temp);
        props.setLoading(false);
        setOpenTrucks(false);
    }

    return(
            <Box display="flex" flexDirection={"column"} style={{width:"200px"}}>
                <h4>{props.wh_name}</h4>
                <Select value={props.method} onChange={(e) => {
                    props.setMethod(e.target.value)
                    }}>
                    <MenuItem value={"LTL"}>LTL</MenuItem>
                    <MenuItem value={"FTL"}>FTL</MenuItem>
                    <MenuItem value={"PARCEL"}>PARCEL</MenuItem>
                </Select>
                {/* {props.method === "FTL" ? // Going to automate the truck splitting. If a vendor ever needs to individually designate their trucks, start here.
                    <Box display="flex" flexDirection="column">
                        <TruckPopup {...props} saveTrucks={saveTrucks} maxes={truck_max} open={openTrucks} tab={truckTab} setTab={setTruckTab} setOpenTrucks={setOpenTrucks} trucks={props.trucks} items={props.items} handleChange={handleTruckChange}/>
                        <TextField InputProps={{inputProps:{min:1}}}  sx={{mt:"15px"}} type="number" label="# of Trucks" value={props.trucks?.length || 1} onChange={(e)=>setTruckCount(e.target.value)} />
                        {props.trucks?.length > 1 ? 
                            <Box>
                                <Button disabled={props.orders.length !== 1} style={{marginTop:"15px"}} variant="contained" onClick={() => setOpenTrucks(true)}>Designate Trucks</Button>
                                {props.orders.length !== 1 ? 
                                <span style={{color: "red"}}>Select Order Individually To Assign Trucks</span>
                                : null}
                            </Box>
                        : null}
                    </Box>
                : null} */}
            </Box>
    )


}

export default WarehouseShipment;