import { DataGrid, GridToolbar  } from '@mui/x-data-grid';
import React from 'react';
import Box from '@mui/material/Box';

const columns = [ 
    {
        field: 'order_number',
        headerName: "Order",
        flex: 1,
        editable: false,
        minWidth: 100
    },
    {
        field: 'customer',
        headerName: 'Customer',
        flex: 1,
        editable: false,
        minWidth: 200
    }, 
    {
        field: "sku",
        headerName: "SKU",
        flex: 1,
        editable: false,
        minWidth: 100
    },
    {
        field: 'quantity',
        headerName: 'Quantity',
        flex: 1,
        editable: true,
        type: 'number',
        minWidth: 50
    },
    {
        field: 'group_number',
        headerName: "Line",
        editable: false,
        minWidth: 100,
    },
    {
        field: 'build_date',
        headerName: "Date",
        editable: false,
        minWidth: 300,
    }

]


function DailyBuildsTable(props) {
 
    return(
        <Box className={props.isMobile ? "mobile-table-box" : "desktop-table-box"} style={{width:"70%"}}>
            <DataGrid
                loading={props.loading}
                slots={{ toolbar: GridToolbar }} 
                style={{width:"100%"}}
                rows={props.group_data}
                className={props.isMobile ? "mobile-table" : "desktop-table"}
                columns={columns}
                rowsPerPageOption = {[25, 50, 100]}
                checkboxSelection = {false}
                sortModel = {[{field:'date_build', sort: 'asc'}]}
                getRowId={props.getRowId}
                onCellEditCommit={props.handleEditCellChange}
                onCellEditStop={props.handleCellEditStop}
            />
        </Box>   
    )


}

export default DailyBuildsTable;