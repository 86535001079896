import axios from 'axios';
import getJWT from './token';

let api = `https://api.${process.env.REACT_APP_BASE_URL || "mattk.commerce-ims.com"}`;


const acumaticaHelpers = {

    getAutobuilds: async function(company_id, token) {
        const userToken = await getJWT(token);
        console.log('get log');
        const url = `${api}/acumatica/autobuild`
        try {
            let res = await axios.get(url, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` } })
            let builds = res.data;
            builds.forEach((row, i) => {
                row['id'] = i;
            })
            return builds
        } catch (err) {
            return err;
        }
    },

    startAutobuild: async function(company_id, token) {
        const userToken = await getJWT(token);
        const url = `${api}/acumatica/autobuild`
        try {
            await axios.post(url, {}, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` } })
            return;
        } catch (err) {
            return err;
        }
    },

    createShipments: async function(company_id, token) {
        const userToken = await getJWT(token);
        const url = `${api}/dropship/acumatica/create_shipments`
        try {
            await axios.post(url, {}, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` } })
            return;
        } catch (err) {
            return err;
        }
    }, 

    getAcumaticaOrderDetail: async function(acu_order_num, company_id, token) {
        const userToken = await getJWT(token);
        const url = `${api}/acumatica/salesOrderDetail`
        const params = {ordernbr: acu_order_num}
        try {
            const res = await axios.get(url, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` }, params });
            return res.data.SalesOrderDetailBySODetails;
        } catch (err) {
            return err;
        }
    }

}

export default acumaticaHelpers;