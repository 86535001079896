import axios from 'axios';
import 'dotenv/config';
import getJWT from './token';
import zlib from 'zlib';

let api = `https://api.${process.env.REACT_APP_BASE_URL === "commerce-ims.com" ? "" : "tims."}furhaven-production.com`

const warehouseHelpers = {

    getWorkers: async function(company_id, token){
        const userToken = await getJWT(token);
        const url = `${api}/v1/operations/getAllWorkers`;
        try {
            let res = await axios.get(url, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` } })
            let out = res.data.workers.sort((a, b) => {
                if(a.warehouse !== b.warehouse) {
                    if(a.warehouse === "KY") return -1;
                    return 1;
                }
                return Number(a.worker_id) - Number(b.worker_id);
            })
            return out;
        } catch (err) {
            console.log(err);
            throw err;
        }
    },

    getWorkersbyWarehouse: async function(company_id, token, target_warehouse){
        const userToken = await getJWT(token);
        const url = `${api}/v1/operations/getAllWorkers`;
        try {
            let res = await axios.get(url, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` } })
            let warehouse_workers = res.data.workers.filter(obj => obj.warehouse === target_warehouse);
            return warehouse_workers;
        } catch (err) {
            console.log(err);
            throw err;
        }
    },

    getTimes: async function(company_id, token, params){
        const userToken = await getJWT(token);
        const url = `${api}/v1/operations/employee/times`;
        try {
            let res = await axios.get(url, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` }, params})
            let out = res.data;
            return out.times;
        } catch (err) {
            console.log(err);
            throw err;
        }
    },

    getEfficiencyTime: async function(company_id, token){
        const userToken = await getJWT(token);
        const url = `${api}/v1/operations/efficiencyTimes`;
        try {
            let res = await axios.get(url, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` }})
            let out = res.data;
            return out;
        } catch (err) {
            console.log(err);
            throw err;
        }
    },

    getEfficiencyBuild: async function(company_id, token, params){
        const userToken = await getJWT(token);
        const url = `${api}/v1/operations/efficiencyByGroups`;
        try {
            let res = await axios.get(url, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` }, params})
            return res;
        } catch (err) {
            console.log(err);
            throw err;
        }
    },

    getEfficiencyExpected: async function(company_id, token){
        const userToken = await getJWT(token);
        const url = `${api}/v1/operations/efficiencyExpected`
        try {
            let res = await axios.get(url, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` }})
            let out = res.data;
            return out.times;
        } catch (err) {
            console.log(err);
            throw err;
        }
    },

    getBuildByGroup: async function(company_id, token){
        const userToken = await getJWT(token);
        const date = new Date();
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        
        const formattedDate = `${year}-${month}-${day}`;
        const url = `${api}/v1/operations/buildsByGroup/`+formattedDate
        try {
            let res = await axios.get(url, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` }})
            let out = res.data;
            return out;
        } catch (err) {
            console.log(err);
            throw err;
        }
    },

    getWorkersByGroup: async function(company_id, token, params){
        const userToken = await getJWT(token);
        const url = `${api}/v1/operations/workersByGroup`
        try {
            let res = await axios.get(url, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` }, params})
            let out = res.data;
            return out;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }, 


    getBuildsByWarehouse: async function(company_id, token, params){
        const userToken = await getJWT(token);
        const url = `${api}/v1/operations/warehouseBuilds`;
        try {
            let res = await axios.get(url, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` }, params })
            return res.data.builds;
        } catch (err) {
            console.log(err);
            throw err;
        }
    },

    updateOperationsBuild: async function(company_id, token, params){
        const userToken = await getJWT(token);
        const url = `${api}/v1/operations/updateBuild`
        try {
            let res = await axios.put(url, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` }, params})
            let out = res.data;
            return out;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }, 

    convertDate: async function(date, hoursToAdd=-4){
        if(date.length !== 0){
            let returnedDate = new Date(date);
            returnedDate.setHours(returnedDate.getHours() + hoursToAdd);
            let formattedDate = returnedDate.toISOString().slice(0,19).replace("T", " ");
            return formattedDate;
        }
        else return null
    }
}

export default warehouseHelpers;