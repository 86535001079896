
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {API} from 'aws-amplify';

function ShipmentInfo(props) {
    const [forms, setForms] = useState([]);
    const [refresh, setRefresh] = useState(false);


    const refreshBoards = async() => {
        props.toast.promise(refreshGraphQL(), {success: "Refreshed Boards!", error: "Failed to Refresh Boards."})
    }



    const refreshGraphQL = async() => {
        try {
            await API.graphql({query: `
            mutation TriggerRefresh {
                triggerRefresh
            }`})
        }
        catch(err) {
            console.log(err)
            throw err;
        }
    }

    return(
        <Box display="flex" flexDirection="column" className={props.isMobile ? "mobile-top" : "desktop-top"}>
            <Box className={props.isMobile ? "mobile-box" : "desktop-box"}>
                <h1>Shipment Info</h1> 
                <Button onClick={()=>refreshBoards()}>Refresh Screens</Button>
            </Box>
        </Box>
    )
}

export default ShipmentInfo