import React, {useState, useEffect} from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import orderHelpers from "../../helpers/orders";
import vendorHelpers from '../../helpers/vendors';

function DsExport(props) {

    const [vendors, setVendors] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [vendor, setVendor] = useState(null);

    useEffect(() => {
        const getVendors = async() => {
            props.setLoading(true);
            let vendorList = await vendorHelpers.getVendors(props.company, props.token)
            setVendors(vendorList.vendors)
            props.setLoading(false);
        }
        getVendors();
    }, [])

    const getExport = async() => {

        props.toast.promise(downloadOrders(), {
            success: (res) => {
                return "Exported"
            },
            error: (err) => {
                return err.message;
            }
        });
    }

    const downloadOrders = async() => {
        props.setLoading(true);
        let error = null;
        try {
            let body = {
                start_date: startDate,
                end_date: endDate,
                vendor_name: vendor ? vendor.replace(/_/g, " ") : null
            }
            console.log(body);
            let res = await orderHelpers.getOrders(props.company, body, props.token)
            let csv = await formatOrders(res)
            let blob = new Blob([csv], {type: "text/csv"})
            let uri = window.URL.createObjectURL(blob);
            console.log("URI", uri)
            let a = document.createElement("a");
            a.setAttribute("href", uri);
            a.setAttribute("download", vendor + "_export.csv");
            document.body.appendChild(a);
            a.click();
        }
        catch(err) {
            console.log(err);
            error = err;
        }
        finally {
            props.setLoading(false);
            if(error) throw error;
        }
    }

    const formatOrders = async(res) => {
        console.log(res)
        if(res.length === 0) return "";
        let headers = Object.keys(res[0]);
        let out = [headers];
        for(let row of res) {
            let out_row = [];
            for(let header of headers) {
                out_row.push(row[header]);
            }
            out.push(out_row);
        }
        return await arrayToCSV(out);
    }

    // lambda snippet from https://www.30secondsofcode.org/js/s/array-to-csv/
    const arrayToCSV = (arr, delimiter = ',') =>
        arr
            .map(v =>
            v.map(x => (isNaN(x) ? `"${x.replace(/"/g, '""')}"` : x)).join(delimiter)
            )
            .join('\n');

    const handleDateChange = (date, isStart) => {
            if(isStart) setStartDate(date ? new Date(date).toISOString().substring(0, 10) : null);
            else setEndDate(date ? new Date(date).toISOString().substring(0, 10) : null);
    }

    return (
        <Box className={props.isMobile ? "mobile-top" : "desktop-top"} sx = {{ display: 'flex', flexDirection: 'column'}}>
                <Box display="flex" flexDirection="column" className={props.isMobile ? "mobile-box" : "desktop-box"}>
                    <h1>Dropship Export</h1> 
                    <Box className={props.isMobile ? "mobile-box" : "desktop-box"} sx={{display: 'flex', flexDirection: props.isMobile ? 'column' : 'row', mb: '10px'}}>
                        <FormControl sx={{ mr: '15px', minWidth: 120 }}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    label="Start Date"
                                    name='orderStartDate'
                                    onChange={(event) => handleDateChange(event, true)}
                                    value={startDate ? startDate : null}
                                    renderInput={(params) => <TextField {...params} />}
                                    clearable={true}
                                />
                            </LocalizationProvider>
                        </FormControl>
                        <FormControl sx={{ mr: '10px', minWidth: 150 }}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    label="End Date"
                                    name='orderEndDate'
                                    onChange={(event) =>  handleDateChange(event, false)}
                                    value={endDate ? endDate : null}
                                    renderInput={(params) => <TextField {...params} />}
                                    clearable={true}
                                />
                            </LocalizationProvider>
                        </FormControl>
                        <FormControl sx={{ minWidth: 150 }}>
                            <InputLabel id="vendorInput">Vendor</InputLabel>
                            <Select
                                name='vendor'   
                                label="Vendor"        
                                onChange={(e) => setVendor(e.target.value)}
                                defaultValue=''
                            >
                                {/* <MenuItem value=''>None</MenuItem> */}
                                {vendors ? vendors.map((vendor, index) => 
                                    <MenuItem key={index} value={vendor.toLowerCase().replace(/\s/g , "_")}>{vendor}</MenuItem>
                                ) : null}
                            </Select>
                        </FormControl>
                        <Button style={{marginLeft:"10px"}} variant="contained" onClick={() => getExport()}>Export</Button>
                    </Box>
                </Box>
            </Box>
    )
}

export default DsExport;