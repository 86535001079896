import {React, useState, createRef} from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Papa from 'papaparse';
import bulkHelpers from '../../helpers/bulk';
const buttonRef = createRef();

const HEADERS = "internal_po_number,vendor_name,pro_number,carrier,bol,ship_date,expected_delivery,truck_number,shipment_price,acu_ship_num,total_on_shipment";

function BulkExportPopup(props) {

    const [carriers, setCarriers] = useState([])
    const [vendor, setVendor] = useState(null);
    const [file, setFile] = useState(null);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);
    const [shipments, setShipments] = useState(null);
    const [uploads, setUploads] = useState([]);
    const [selected, setSelected] = useState([]);


    
    const handleChange = (e) => {
        setVendor(e.target.value);
    }

    const handleOpenDialog = (e) => {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
        buttonRef.current.open(e);
        }
    };

    const handleOnFileLoad = (data, file) => {
        console.log('---------------------------');
        setFile(file);
        setSuccess(false);
        setShipments(data.map(obj => obj.data))
        console.log('---------------------------');
    };
    
    const handleOnError = (err, file, inputElem, reason) => {
        console.log('---------------------------');
        console.log(err);
        console.log('---------------------------');
        setError(`Error Uploading Shipment File: ${file}`)
    };

    const handleRemoveFile = (e) => {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
        buttonRef.current.removeFile(e);
        setFile(null);
        setShipments(null);
        }
    };

    const formatOrders = async(orders) => {
        let out = {};
        for(let row of orders) {
            if(!(row.internal_po_number in out)) {
                out[row.internal_po_number] = row;
                out[row.internal_po_number].total_pieces = 0;
                out[row.internal_po_number].total_price = 0;
                out[row.internal_po_number].details = {};
            }
            if(!(row.internal_sku in out[row.internal_po_number].details)) {
                out[row.internal_po_number].details[row.internal_sku] = {unit_price: row.external_price, quantity: 0, ext_price: 0};
            }
            out[row.internal_po_number].details[row.internal_sku].quantity += row.quantity;
            out[row.internal_po_number].details[row.internal_sku].ext_price += (row.external_price * row.quantity);
            out[row.internal_po_number].total_price += (row.external_price * row.quantity)
            out[row.internal_po_number].total_pieces += row.quantity;
        }
        let out_form = [];
        for(let po in out) {
            let order = out[po];
            for(let sku in order.details) {
                let item = order.details[sku];
                out_form.push({
                    internal_po_number: order.internal_po_number,
                    vendor_name: order.vendor_name,
                    vendor_code: order.vendor_code,
                    order_date: order.order_date,
                    upload_date: order.upload_date,
                    order_pieces: order.total_pieces,
                    order_price: order.total_price.toFixed(2),
                    location: order.location,
                    requested_ship_date: order.requested_ship_date,
                    deliver_by: order.deliver_by,
                    deliver_window_start: order.deliver_window_start,
                    deliver_window_end: order.deliver_window_end,
                    ship_window_start: order.ship_window_start,
                    ship_window_end: order.ship_window_end,
                    requested_pickup: order.requested_pickup,
                    internal_sku: sku,
                    quantity: item.quantity,
                    unit_price: item.unit_price,
                    ext_price: item.ext_price.toFixed(2),
                    decision: "",
                    ship_type: "",
                    KY_qty: "",
                    PA_qty: "",
                    TX_qty: "",
                    IL_qty: ""
                })
            }
        }
        return out_form;
    }

    const downloadExport = async() => {
        props.setLoading(true);
        try {
            let orders = await bulkHelpers.getNewOrders(vendor, props.company + "_bulk", props.token)
            let formatted = await formatOrders(orders);
            let csv = Papa.unparse(formatted);
            let blob = new Blob([csv], {type: "text/csv"})
            let uri = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.setAttribute("href", uri);
            a.setAttribute("download", `NEW_${vendor}_ORDERS_${new Date().toISOString().substring(0, 10)}.csv`);
            document.body.appendChild(a);
            a.click();
        }
        catch(err) {
            console.log(err);
        }
        finally {
            props.setLoading(false);
        }
    }



        return(
            <Dialog open={props.open} onClose={()=>props.handleClose()}>
            <DialogTitle>
                    Bulk New Orders Export:
            </DialogTitle>
            <DialogContent>
                <Box>
                    <Select label="Vendor" value={vendor} onChange={handleChange}>
                        {[...new Set(props.orders.map(obj => obj.vendor_name))].map((obj, index) => <MenuItem value={obj} key={index}>{obj}</MenuItem>)}
                    </Select>
                </Box>
            </DialogContent>
            <DialogActions>
                <Box display="flex" style={{width:"100%"}}>
                    <Button style={{marginLeft:"20px", width:"20%"}} onClick={()=>props.handleClose()} disabled={props.isLoading}>Cancel</Button>
                    <Button style={{marginLeft:"auto", marginRight:"20px", width:"20%"}} onClick={()=>downloadExport()} disabled={props.isLoading || !vendor}>Download</Button>
                </Box>
            </DialogActions>
        </Dialog>
        )
}

export default BulkExportPopup;