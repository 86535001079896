import {React, useState, useEffect} from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import bulkHelpers from '../../../helpers/bulk';

function RoutingPopup(props) {

        const [so, setSo] = useState("");
        const [vendor_name, setVendorName] = useState("");
        const [po, setPo] = useState("")

        const [weight, setWeight] = useState("");
        const [volume, setVolume] = useState("");
        const [ship, setShip] = useState("");

        useEffect(() => {
            setSo(props.order?.acu_order_num);
            setVendorName(props.order?.vendor_name);
            setPo(props.order?.internal_po_number);
        }, [props.order])


        const routeRequest = async() => {
            props.setLoading(true);
            try {
                if(so && po && vendor_name && weight && volume && ship) {
                    let shipment = {
                        acu_order_num: so,
                        internal_po_number: po,
                        vendor_name: vendor_name,
                        pickup_date: ship,
                        volume: volume,
                        weight: weight
                    }
                    console.log(shipment)
                    await bulkHelpers.sendRouteRequest(shipment, props.token, props.company + "_bulk");
                    props.handleClose(true);
                }
            }
            catch(err) {
                console.log(err);
            }
            finally {
                props.setLoading(false);
            }
        }


        return(
            <Dialog open={props.open} onClose={()=>props.handleClose()}>
            <DialogTitle>
                    Update Order Shipment Info:
            </DialogTitle>
            <DialogContent>
                <Box>
                    <TextField sx={{m:"8px"}} label="SO Number" value={so} disabled={true} />
                    <TextField sx={{m:"8px"}} label="Vendor" value={vendor_name} disabled={true} />
                    <TextField sx={{m:"8px"}} label="PO Number" value={po} disabled={true} />
                    <TextField sx={{m:"8px"}} InputLabelProps={{ shrink: true }} type="date" label="Earliest Pickup Date" value={ship} onChange={(e)=>setShip(e.target.value)} />
                    <TextField sx={{m:"8px"}} label="Volume (CF)" value={volume} onChange={(e)=>setVolume(e.target.value)} />
                    <TextField sx={{m:"8px"}} label="Weight (LBS)" value={weight} onChange={(e)=>setWeight(e.target.value)} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>props.handleClose()}>Cancel</Button>
                <Button variant="contained" disabled={props.isLoading} onClick={()=>routeRequest()} autoFocus>
                    Send
                </Button>
            </DialogActions>
        </Dialog>
        )
}

export default RoutingPopup;