import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';

import Button from '@mui/material/Button';
import bulkHelpers from '../../helpers/bulk'
import CarriersTable from './CarriersTable';
import CarriersPopup from './CarriersPopup';

function BulkCarriers(props) {

    const [carriers, setCarriers] = useState([]);
    const [open, setOpen] = useState(false);
    useEffect(() => {
        const getCarriers = async() => {
            props.setLoading(true);
            let temp = await bulkHelpers.getCarriers(props.company + "_bulk", props.token);
            temp = temp.map((obj, index) => {return({...obj, id: index})})
            console.log(temp);
            setCarriers(temp);
            props.setLoading(false);
        }
        getCarriers();
    }, [])

    return(
        <Box display="flex" flexDirection="column" className={props.isMobile ? "mobile-top" : "desktop-top"}>

            <CarriersPopup {...props} open={open} setOpen={setOpen} />
            <Box className={props.isMobile ? "mobile-box" : "desktop-box"}>
                <h1>Bulk Carriers</h1> 
                <Button onClick={() => setOpen(true)}>Add Carrier</Button>
                <CarriersTable {...props} carriers={carriers} />
            </Box>
        </Box>
    )
}

export default BulkCarriers;