import React, {useEffect, useState} from "react";
import Box from '@mui/material/Box';
import {API} from 'aws-amplify';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress'
import bulkHelpers from "../../helpers/bulk";
import '../../css/ShippingDashboard.css';
function ShippingDashboard(props) {

    const [dash, setDash] = useState([])
    const [date, setDate] = useState(Date.now())
    const [refresh, setRefresh] = useState(false);
    const [status, setStatus] = useState(0);
    const [sub, setSub] = useState(false);



    let page_limit = 29;

    let getDash = async() => {
        try {
            setStatus(0)
            let data = await bulkHelpers.getShippingDashboard(props.warehouse);
            let out = data.slice(page_limit * (props.screen - 1), page_limit * props.screen)
            console.log(out)
            setDash(out);
            setDate(Date.now())
            setStatus(1)
        }
        catch(err) {
            console.log(err)
            setStatus(-1)
        }
    }

    useEffect(() =>{
        if(!sub) setSub(API.graphql({
            query: `subscription RefreshSignal {
                refreshSignal
            }`
        }).subscribe({
            next: ({provider, value}) => getDash(),
            error: (error) => getDash()
        })
    )
        getDash();
        return () => {if(sub) sub.unsubscribe();}
    }, [])

    return (
        <Box display="flex" flexDirection="column" style={{width:"100%", height: "100%"}} className={props.isMobile ? "mobile-box" : "desktop-box"}>
                <TableContainer className={props.isMobile ? "mobile-table-box" : "desktop-table-box"}>
                <Table className={props.isMobile ? "mobile-table" : "desktop-table"}  size="small" sx={{zIndex:0}}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" style={{fontWeight: "bold"}}>Vendor Name</TableCell>
                            <TableCell align="center" style={{fontWeight: "bold"}}>Type</TableCell>
                            <TableCell align="center" style={{fontWeight: "bold"}}>Shipping Order</TableCell>
                            <TableCell align="center" style={{fontWeight: "bold"}}>Ship Date</TableCell>
                            <TableCell align="center" style={{fontWeight: "bold"}}>Ready</TableCell>
                            <TableCell align="center" style={{fontWeight: "bold"}}>Freight Co</TableCell>
                            <TableCell align="center" style={{fontWeight: "bold"}}>Dock Appt</TableCell>
                            <TableCell align="center" style={{fontWeight: "bold"}}>Destination</TableCell>
                            <TableCell align="center" style={{fontWeight: "bold"}}>Pallets</TableCell>
                            <TableCell align="center" style={{fontWeight: "bold"}}>Pieces</TableCell>
                            <TableCell align="center" style={{fontWeight: "bold"}}>Note</TableCell>
                        </TableRow>
                    </TableHead>
                    {dash.length > 0 ? 
                        <TableBody>
                            {dash.map((row, index)=> {
                                let ship_date = Date.parse(row.ship_date);
                                if(row.dock_appointment) ship_date = Date.parse(row.dock_appointment);
                                let class_name = "normal";
                                if(ship_date < date && !row.dock_appointment) class_name = "shipment_late";
                                else if(ship_date < date - (7 * 60 * 60 * 60) && row.status !== 101) class_name = "shipment_close"
                                return(
                                    <TableRow className={`row ${row.vendor_name.replace(/[^a-zA-Z]/g, "")}`}key={index} tabIndex={-1} style={{fontSize:"12px"}}>
                                        <TableCell className={`leading`} align="center">{row.vendor_name}</TableCell>
                                        <TableCell align="center">{row.shipment_type}</TableCell>
                                        <TableCell align="center">{row.acu_order_num}</TableCell>
                                        <TableCell sx={{ '&:last-child td, &:last-child th': { border: 0 } }} className={class_name} align="center">{row.ship_date ? new Date(row.ship_date).toISOString().substring(0, 10) : null}</TableCell>
                                        <TableCell className={class_name !== "normal" && row.status !== 101 ? "shipment_late" : class_name} align="center">{row.status === 101 ? "Yes" : "No"}</TableCell>
                                        <TableCell align="center">{row.carrier}</TableCell>
                                        <TableCell className={class_name !== "normal" && !row.dock_appointment ? "shipment_late" : class_name} align="center">{row.dock_appointment ? new Date(row.dock_appointment).toISOString().substring(0, 10) : null}</TableCell>
                                        <TableCell align="center">{row.destination}</TableCell>
                                        <TableCell align="center">{row.pallets || null}</TableCell>
                                        <TableCell align="center">{row.pieces || null}</TableCell>
                                        <TableCell align="center">{row.note}</TableCell>
                                    </TableRow> 
                                )
                            })}
                        </TableBody>
                    : <TableBody />}
                </Table>
                </TableContainer>
                <Box style={{position: "absolute", bottom: "130px", right: "10px"}}>
                    {status === 0 ? <CircularProgress  /> : status === -1 ? <CircularProgress variant="determinate" color="error" value={100} /> : null}

                </Box>
        </Box>
    )
}

export default ShippingDashboard;