import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import {lighten } from '@mui/material/styles';

function WarehousePriorityTable(props) {

    
    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            flex:1,
            editable:false
        },
        {
            field: 'vendor_name',
            headerName: 'Vendor',
            minWidth: 250,
            flex:1, 
            editable: false
        }, 

        {
            field: 'default_warehouse',
            headerName: 'Warehouse',
            minWidth: 200,
            flex:1, 
            editable: false
        }, 
        {
            headerName: 'Swap Warehouse',
            renderCell: (params) => {
                let other_wh = params.row.default_warehouse === "Kentucky" ? "Pennsylvania" : "Kentucky"
                return(
                    <Button key={params.row.key} variant="contained" size="small" style={{backgroundColor: other_wh === "Kentucky" ? "#0099FF" : "#CEB08D"}} onClick={()=>props.updateWarehouse(params.row.vendor_name, other_wh)}>Swap to {other_wh === "Kentucky" ? "KY" : "PA"}</Button>
                )
            },
            minWidth: 400,
            flex:1, 
            editable: false
        }, 
    ];



    return(
        <Box className={props.isMobile ? "mobile-box" : "desktop-box"} sx = {{display: 'flex', flexDirection: 'row'}}>
            <Box className={props.isMobile ? "mobile-table-box" : "desktop-table-box"} style={{ height: 1000}} sx={{ 
                        '& .failed': {
                            bgcolor: (theme) => lighten(theme.palette.error.light, 0.5)
                        }
                        }}>
                <DataGrid
                className={props.isMobile ? "mobile-table" : "desktop-table"} 
                rows={props.vendors}
                columns={columns}
                rowsPerPageOption = {[25, 50, 100]}
                sortModel = {[{field: 'id', sort: 'asc'}]}
                />
            </Box>            
        </Box>
    )
}

export default WarehousePriorityTable