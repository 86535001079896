import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText'
import React, {useState} from 'react';

function OrderInfo(props) {

    let date_types = ["Ship Window Start", "Ship Window End", "Requested Pickup", "Requested Ship Date", "Requested Delivery", "Delivery Window Start", "Delivery Window End"]
    return ( 
        <Box style={{width:"40%"}} display="flex" flexDirection="column">
            <List dense>
                <ListItem>
                    <ListItemText primary="Order Number" primaryTypographyProps={{variant:"h4"}} secondaryTypographyProps={{variant:"h5"}} secondary={props.order.internal_po_number} />
                </ListItem>
            </List>
            <Box display="flex">
                <List dense>
                    <ListItem>
                        <ListItemText primary="Customer Name" secondary={props.order.vendor_name} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Order Status" secondary={props.order.order_status} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Order Date" secondary={props.order.order_date ? props.order.order_date.substring(0, 10) : "N/A"}/>
                    </ListItem>
                    <ListItem>
                            <ListItemText primary="Acumatica Order" secondary={props.order.acu_order_num || "N/A"}/>
                    </ListItem>
                </List>
                <List dense >
                    {date_types.map((obj, index) => {
                        let key = obj.replace(/ /g, "_").toLowerCase();
                        if(!props.order[key] && !props.requested[key]) return null;
                        let order_date = (props.order[key] || "").substring(0, 10);
                        let requested_date = (props.requested[key] || "").substring(0, 10);
                        return(
                            <ListItem key={index}>
                                <ListItemText primary={obj} secondary={order_date === requested_date ? order_date : 
                                <Box>
                                    <Box style={{textDecoration:"line-through"}}>{order_date || "N/A"}</Box>
                                    <Box style={{color:"red",fontStyle:"italic" }}>{requested_date}</Box>
                                </Box>
                            }/>  
                            </ListItem>
                        )
                    })}
                    <ListItem>
                        <ListItemText primary="Total Price" secondary={props.order.price === props.requested.price ? `$${props.order.price}` : 
                                <Box>
                                    <Box style={{textDecoration:"line-through"}}>${props.order.price}</Box>
                                    <Box style={{color:"red",fontStyle:"italic" }}>${props.requested.price}</Box>
                                </Box>
                            }/>  
                    </ListItem>
                </List>
            </Box>
            <Box>
                <Button variant="contained" onClick={() => props.setOpen(true)}>View Requested Changes</Button>
            </Box>
        </Box>
    )
}

export default OrderInfo;