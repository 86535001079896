
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Sticker from './Sticker'
import inventoryHelpers from '../../helpers/inventory';
import {jsPDF} from "jspdf"


function VendorStickers(props) {


    const downloadFile = async(uri, name) => {
        let a = document.createElement("a");
        a.setAttribute("href", uri);
        a.setAttribute("download", name);
        document.body.appendChild(a);
        a.click();
    }

    const downloadAsinTemplate = async() => {
        let csv = "SKU,QTY";
        let blob = new Blob([csv], {type: "text/csv"})
        let uri = window.URL.createObjectURL(blob);
        await downloadFile(uri, "ASIN_STICKER_TEMPLATE.csv")
    }
        
    const downloadLowesTemplate = async() => {
        let csv = "SKU,DESC,BARCODE,COUNTRY_OF_ORIGIN,PER_BOX,LABEL_QTY";
        let blob = new Blob([csv], {type: "text/csv"})
        let uri = window.URL.createObjectURL(blob);
        await downloadFile(uri, "LOWES_STICKER_TEMPLATE.csv")
    }

    const generateAsinLabels = async(items, generic=false) => {
        console.log("generic", generic)
        props.setLoading(true);
        let error = null;
        try {
            let asins_obj = await inventoryHelpers.getAsins(props.company, props.token);
            let asins_raw = asins_obj.asins;
            let inv = asins_obj.items;
            console.log("asins_raw", asins_raw)
            console.log("inv", inv)
            let asins = await formatAsins(asins_raw);
            let label_data = await formatLabelData(items, asins, inv);
            console.log(label_data)
            let barcodes = await generateBarcodes(label_data);
            await generatePDF(label_data, barcodes, generic);
        }
        catch(err) {
            console.log(err);
            error = err;
        }
        finally {
            props.setLoading(false);
            if(error) throw error;
        }
    }

    const generateBarcodes = async(label_data) => {
        let out = {};
        let upcs = [...new Set(label_data.map(obj => obj.UPC.replace(/-/g, "")))]
        const JsBarcode = require ('jsbarcode');
        for(let upc of upcs) {
            let barcode = document.createElement("img");
            JsBarcode(barcode, upc, {displayValue: false, width: 1, height:40})
            out[upc] = barcode;
        }
        return out;
    }

    const generatePDF = async(label_data, barcodes, generic=false) => {
        const doc = new jsPDF({
            orientation: "landscape",
            unit: "in",
            format: [4, 1]
        });
        let i = 0;
        console.log(label_data);
        console.log(barcodes);
        for(let row of label_data) {
            console.log(row.UPC)
            if(i > 0)
                doc.addPage();
            i++;
            doc.addImage(barcodes[row.UPC.replace(/-/g, "")].src, 2.6, -0.05, 1.3, 1)
            doc.setFontSize(10);
            doc.text(2.785, 0.92, row.UPC.replace(/-/g, ""))
            if(!generic) {
                doc.setFontSize(16);
                doc.setFont("Helvetica", "bold")
                doc.text(0.13, 0.25, row.ASIN);
            }
            doc.setFont("Helvetica", "")
            doc.setFontSize(row["Amazon Description"].length > 132 ? 6 : 7)
            doc.text(0.13, 0.4, row["Amazon Description"], {maxWidth:2.3});
            doc.setFontSize(16);
            doc.text(0.13, 0.85, row.Item);
        }
        doc.save(`${generic ? "GENERIC" : "ASINS" }_LABELS_${new Date().toISOString().replace(/\./g, "_").replace(/:/g, "_").replace(/-/g, "_")}.pdf`)
    }

    const formatLabelData = async(rows, asins, inv) => {
        let out = [];
        console.log(rows)
        for(let row of rows) {
            let qty = row.QTY;
            let sku = row.SKU;
            let out_row = asins[sku];
            if(!out_row || out_row["Amazon Description"] === "") {
                let inv_row = inv.filter(obj => obj.sku === sku);
                console.log("inv_row", inv_row)
                if(!inv_row || inv_row.length < 0) continue;
                out_row["Amazon Description"] = inv_row[0].description;
            }
            for(let i = 0; i < qty; i++) {
                out.push(out_row)
            }
        }
        return out;
    }

    const formatAsins = (asins) => {
        let out = {};
        for(let row of asins) {
            out[row.Item] = row;
        }
        return out;
    }




    const downloadLowes = async(lowesItems) => {
        props.setLoading(true);
        let error = null;
        try {
            let barcodes = await generateLowesBarcodes(lowesItems);
            await generateLowesLabels(lowesItems, barcodes);
        }
        catch(err) {
            console.log(err);
            error = err;
        }
        finally {
            props.setLoading(false);
            if(error) throw error;
        }
    }

    const generateLowesBarcodes = async(label_data) => {
        let out = {};
        let upcs = [...new Set(label_data.map(obj => obj.BARCODE.replace(/-/g, "")))]
        const JsBarcode = require ('jsbarcode');
        for(let upc of upcs) {
            let barcode = document.createElement("img");
            JsBarcode(barcode, upc, {
                format: "ITF14"
            })
            out[upc] = barcode;
        }
        return out;
    }


    const generateLowesLabels = async(label_data, barcodes) => {
        const doc = new jsPDF({
            orientation: "landscape",
            unit: "in",
            format: [4, 1]
        });
        let i = 0;
        console.log(label_data);
        console.log(barcodes);
        for(let row of label_data) {
            console.log(row.BARCODE)
            for(let j = 0; j < Number(row.LABEL_QTY); j++) {
                if(i > 0)
                doc.addPage();
                i++;
                doc.addImage(barcodes[row.BARCODE.replace(/-/g, "")].src, 2.6, 0, 1.3, 1)
                doc.rect(2.5, 0.01, 1.45, .054, 'F');
                doc.rect(2.5, .78, 1.45, .054, "F")
                doc.setFontSize(10);
                doc.setFont("Helvetica", "")
                doc.text(0.10, 0.15, "COUNTRY OF ORIGIN:")
                doc.text(1.7, 0.15, row.COUNTRY_OF_ORIGIN);
                doc.text(0.10, 0.35, "MODEL NO:")
                doc.text(1.7, 0.35, row.SKU);
                doc.text(0.10, 0.60, "DESCRIPTION:")
                doc.text(1.7, 0.60, row.DESC, {maxWidth:2.3});
                doc.text(0.10, .85, "QUANTITY:");
                doc.text(1.7, .85, row.PER_BOX + " EACH")
            }
        }
        doc.save(`LOWES_LABELS_${new Date().toISOString().replace(/\./g, "_").replace(/:/g, "_").replace(/-/g, "_")}.pdf`)
    }

    return(
        <Box display="flex" flexDirection="column" className={props.isMobile ? "mobile-top" : "desktop-top"}>
            <h1>Generate Vendor Stickers</h1> 
            <Box className={props.isMobile ? "mobile-box" : "desktop-box"} display="flex">
                <Sticker style={{marginRight:"20px"}} type={"Generic"} downloadTemplate={downloadAsinTemplate} generateLabels={generateAsinLabels}  />
                <Sticker style={{marginRight:"20px"}} type={"ASIN"} downloadTemplate={downloadAsinTemplate} generateLabels={generateAsinLabels}  />
                <Sticker type={"Lowes"} downloadTemplate={downloadLowesTemplate} generateLabels={downloadLowes}  />
            </Box>
        </Box>
    )
}

export default VendorStickers