import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { CSVReader } from 'react-papaparse';
import orderHelpers from '../../helpers/orders';


const buttonRef = React.createRef();

function DecisionImport(props) {

    const HEADERS = "internal_po_number,vendor_name,vendor_code,order_date,upload_date,order_pieces,order_price,location,requested_ship_date,deliver_by,deliver_window_start,deliver_window_end,ship_window_start,ship_window_end,requested_pickup,internal_sku,quantity,unit_price,ext_price,decision,ship_type,KY_qty,PA_qty,TX_qty,IL_qty"
    const VALID_DECISIONS = {KY: "Kentucky", PA: "Pennsylvania", TX: "Texas", PF: "Illinois", IL: "Illinois", PL: "Partial", SP: "Split", CN: "Cancel", DC: "Discontinued", BO: "Back Ordered"}
    const VALID_SHIP_TYPES = ["LTL", "FTL", "PARCEL"]
    
    const [file, setFile] = useState(null);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [orders, setOrders] = useState(null);
  const [uploads, setUploads] = useState([]);
  const [selected, setSelected] = useState([]);

    const handleOpenDialog = (e) => {
      // Note that the ref is set async, so it might be null at some point
      if (buttonRef.current) {
        buttonRef.current.open(e);
      }
    };

    const handleOnFileLoad = (data, file) => {
        console.log('---------------------------');
        setFile(file);
        setSuccess(false);
        props.toast.promise(formatOrders(data), {
            success: (res) => {
                console.log(res);
                setOrders(res)
                return "File Valid"
            },
            error: (err) => {
                console.log(err);
                setFile(null);
                return err.message;
            }
        })
        console.log('---------------------------');
    };
  
    const handleOnError = (err, file, inputElem, reason) => {
      console.log('---------------------------');
      console.log(err);
      console.log('---------------------------');
      setError(`Error Uploading Order File: ${file}`)
    };

    const formatOrders = async (data) => {
        let arr = data.map(obj => obj.data);
        if(arr.some(obj => Object.keys(obj).toString() !== HEADERS)) throw new Error("Invalid File Headers");
        if(arr.some(obj => !(obj.decision in VALID_DECISIONS) || (obj.decision !== "CN" && obj.decision !== "DC" && !VALID_SHIP_TYPES.includes(obj.ship_type)))) throw new Error("Invalid Decisions or Ship Types");
        let pos = [...new Set(arr.map(obj=>`${obj.internal_po_number}|${obj.vendor_name}|${obj.vendor_code}`))]
        let out = [];
        for(let po of pos) {
            let split = po.split("|");
            let ord = arr.filter(obj=>obj.internal_po_number===split[0] && obj.vendor_name===split[1] && obj.vendor_code === split[2]);
            let temp = null;
            for(let row of ord) {
                if(!temp) {
                    temp = row;
                    temp.items = [];
                }
                temp.items.push({
                    internal_sku: row.internal_sku,
                    quantity: row.quantity,
                    decision: VALID_DECISIONS[row.decision],
                    fulfill_ky: row.KY_qty,
                    fulfill_pa: row.PA_qty,
                    fulfill_tx: row.TX_qty,
                    fulfill_il: row.IL_qty,
                    ship_method: row.ship_type
                })
            }
            out.push(temp);
        }
        return out;
    }

    const uploadFile = async () => {
      props.setLoading(true);
      setError("")
      setSuccess(false)
      for(let order of orders) {
        await orderHelpers.saveBulkOrderDecisions(props.company + "_bulk", props.token, order, props.ignore_cancel)
      }

    //   let upload = await orderHelpers.uploadOrders(props.company, body, props.token);
    //   if(upload === true) {
    //     setSuccess(true);
    //     handleRemoveFile()
    //     setTimeout(() => {
    //       getUploads()
    //     }, 5000)
    //   } else {
    //     setError(`Error Uploading Order File: ${upload}`);
    //   }
      props.setLoading(false);
    }
    
  
    const handleRemoveFile = (e) => {
      // Note that the ref is set async, so it might be null at some point
      if (buttonRef.current) {
        buttonRef.current.removeFile(e);
        setFile(null);
        setOrders(null);
      }
    };



        return(
            <CSVReader
                ref={buttonRef}
                onFileLoad={handleOnFileLoad}
                onError={handleOnError}
                noClick
                noDrag
                config={{
                    header: true,
                    skipEmptyLines: true
                }}
                style={{}}
                >
                {({ file_obj }) => (
                    <aside>
                    <Button variant='contained' onClick={handleOpenDialog} className={`${props.company} ${props.warehouse} secondary`}
                    // sx={{mr: '10px', mb: '10px'}}
                    >Browse file</Button>
                    <div>
                        {file_obj && file_obj.name}
                    </div>
                    {file !== null ? 
                        <Box 
                        // sx={{flexDirection: 'row', mt: '10px'}}
                        >
                        <Button variant='contained' onClick={uploadFile} className={`${props.company} ${props.warehouse} secondary`}
                        // sx={{mr: '10px'}}
                        >Upload</Button>
                        <Button variant='contained' sx={{ml:"30px"}} onClick={handleRemoveFile} className={`${props.company} ${props.warehouse} tertiary`}>Remove</Button>
                        </Box>
                    : null}
                    </aside>
                )}
            </CSVReader>
    )
    
}

export default DecisionImport