import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import {lighten } from '@mui/material/styles';

const columns = [ 
    {
        field: 'internal_po_number',
        headerName: "PO Number",
        flex: 1,
        editable: false,
        minWidth: 200,
        maxWidth: 200
    },
    {
        field: 'vendor_name',
        headerName: 'Vendor',
        flex: 1,
        editable: false,
        minWidth: 150,
    }, 
    {
        field: "acu_order_num",
        headerName: "SO Number",
        flex: 1,
        editable: false,
        minWidth: 150,
    },
    {
        field: 'acu_invoice_num',
        headerName: 'Invoice Number',
        flex: 1,
        editable: false,
        minWidth: 250,
    },
    {
        field: 'quantity',
        headerName: "IMS Cost",
        sortable: false,
        editable: false,
        minWidth: 250,   
        valueGetter: (params) => {
            if(!params.row.quantity) return "N/A";
            return `$${params.row.quantity}`
        },              
    },
    {
        field: 'invoice_cost',
        headerName: "Acumatica Cost",
        sortable: false,
        editable: false,
        minWidth: 250,       
        valueGetter: (params) => {
            if(!params.row.invoice_cost) return "N/A";
            return `$${params.row.invoice_cost}`
        },                       
    },
    {
        field: 'vendor_status',
        headerName: "Status",
        sortable: false,
        editable: false,
        minWidth: 250,       
        valueGetter: (params) => {
            switch(params.row.vendor_status) {
                case 701:
                    return "Sent";
                case -701:
                    return "Error"
                default:
                    return "Not Sent";
            }
        },                       
    }


]


function BulkInvoiceTable(props) {

    const getClass = (row) => {
        if(row.invoice_cost === row.quantity) {
            if(row.vendor_status === 701) return "match"
            return "";
        }  
        return "mismatch"
    }


    return(
        <Box className={props.isMobile ? "mobile-table-box" : "desktop-table-box"} style={{width:"100%"}} sx={{ 
            '& .mismatch': {
                bgcolor: (theme) => lighten(theme.palette.error.light, 0.5)
            },
            '& .match': {
                bgcolor: (theme) => lighten(theme.palette.success.light, 0.5)
            }
            }}>
            <DataGrid
                style={{width:"90%"}}
                rows={props.invoices}
                className={props.isMobile ? "mobile-table" : "desktop-table"}
                columns={columns}
                rowsPerPageOption = {[25, 50, 100]}
                checkboxSelection = {false}
                getRowClassName={(params) => getClass(params.row)}
                sortModel = {[{field:'id', sort: 'desc'}]}
                onRowClick = {props.setOpenDetails}
            />
        </Box>   
    )


}

export default BulkInvoiceTable;