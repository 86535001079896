import {React} from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import orderHelpers from "../../helpers/orders";

function Invoices(props) {

    const downloadInvoices = async() => {
        props.setLoading(true);
        await orderHelpers.downloadInvoices(props.token, props.company);
        props.setLoading(false);
    }

    return (
        <Box className={props.isMobile ? "mobile-top" : "desktop-top"} sx = {{ display: 'flex', flexDirection: 'column'}}>
                <Box display="flex" flexDirection="column" className={props.isMobile ? "mobile-box" : "desktop-box"}>
                    <h1>Invoices</h1> 
                    <Box>
                        <Button className={`${props.company} ${props.warehouse} secondary`} variant="contained" onClick={() => downloadInvoices()}>Download Acumatica Invoices</Button>
                    </Box>
                </Box>
            </Box>
    )
}

export default Invoices;