import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import AutoComplete, { createFilterOptions } from '@mui/material/Autocomplete';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SearchIcon from '@mui/icons-material/Search';

function OrderQueryForm(props) {

    const filter = createFilterOptions();

    const order_statuses = [
        {value: 100, name: "order_created"},
        {value: 101, name: "order_acknowledged"},
        {value: 102, name: "order_built"},
        {value: 103, name: "order_staged"},
        {value: 104, name: "order_shipped"},
        {value: 199, name: "order_cancelled"}
    ];

    const shipment_statuses = [
        {value: 200, name: "shipment_created"},
        {value: 201, name: "shipment_confirmed"},
        {value: 299, name: "shipment_reshipped"}
    ];

    const acumatica_statuses = [
        {value: 400, name: "acumatica_processing"},
        {value: 401, name: "acumatica_order_created"},
        {value: 402, name: "acumatica_shipment_created"},
        {value: -402, name: "acumatica_shipment_failed"},
        {value: -401, name: "acumatica_order_failed"},
    ];

    const warehouses = [
        {value: "Kentucky", name: "Kentucky"},
        {value: "Pennsylvania", name: "Pennsylvania"}
    ]

    const bulkVendors = props.vendorList?.map(value => ({ value: value, name: value}));
    
    const bulkVendorsGrouped = bulkVendors?.map((option) => {
        const firstLetter = option.value[0].toUpperCase();
        return {
           firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
           ...option,
        };
     });

     return(
        <Box className = {props.isMobile ? "mobile-box" : "desktop-box"} sx = {{display: 'flex', flexDirection: 'column'}}>
        <Box className = {props.isMobile ? "mobile-box" : "desktop-box"} sx = {{display: 'flex', flexDirection: props.isMobile ? 'column' : 'row', mb: '10px'}}>
            <FormControl sx = {{ width: '20.2%', mr: "0.6%" }}>
                <LocalizationProvider dateAdapter = {AdapterMoment}>
                    <DatePicker
                        label = "Start Date"
                        name = 'orderStartDate'
                        onChange = {(event) => props.handleDateChange(event, 'startDate')}
                        value = {props.startDate ? props.startDate : null}
                        renderInput = {(params) => <TextField {...params} />}
                        clearable = {true}
                    />
                </LocalizationProvider>
            </FormControl>
            <FormControl sx = {{ width: '20.2%', mr: "0.6%" }}>
                <LocalizationProvider dateAdapter = {AdapterMoment}>
                    <DatePicker
                        label = "End Date"
                        name = 'orderEndDate'
                        onChange = {(event) => props.handleDateChange(event, 'endDate')}
                        value = {props.endDate ? props.endDate : null}
                        renderInput = {(params) => <TextField {...params} />}
                        clearable = {true}
                    />
                </LocalizationProvider>
            </FormControl>
            <FormControl sx = {{ width: '20.2%', mr: "0.6%" }}>
                <AutoComplete
                    value = {props.vendor}
                    onChange = {(event, newValue) => {
                            props.setVendor(newValue ? newValue.value : null);
                        }
                    }
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id = "vendor"  
                    options = {bulkVendorsGrouped.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                    groupBy = {(option) => option.firstLetter}
                    getOptionLabel = {(option) => {
                        if (typeof option === 'string') {
                            return option;
                        }
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                            return option.value;
                    }}
                    renderOption = {(props, option) => {
                        return (
                            <li {...props}>
                                {`${option.value}`}
                            </li>
                        );
                    }}
                    freeSolo
                    forcePopupIcon = {true}
                    renderInput = {(params) => (
                        <TextField {...params} label = "Vendor" />
                    )}
                />
            </FormControl>
            <Tooltip title = {"Clear all filters."}  placement = 'right' >
                <span>
                    <IconButton 
                        aria-label = "clearAllFilters" 
                        onClick = {props.clearAllFilters} 
                        sx = {{mt: '5px', color: '#96605a'}}>
                        <FilterAltOffIcon />
                    </IconButton>
                </span>
            </Tooltip>
        </Box>
        <Box className = {props.isMobile ? "mobile-box" : "desktop-box"} sx = {{flexDirection: 'row', mb: '10px'}}>
            <TextField 
                name = 'acumaticaOrderNum'
                value = {props.acumaticaOrderNum}
                label = 'Acumatica Order #'
                onChange = {props.handleChange}
                sx = {{ width: '20.2%', mr: "0.6%" }}
            />
            <TextField 
                name = 'internalPoNumber'
                value = {props.internalPoNumber}
                label = 'Internal PO #'
                onChange = {props.handleChange}
                sx = {{ width: '20.2%', mr: "0.6%" }}
            />
            <TextField 
                name = 'externalPoNumber'
                value = {props.externalPoNumber}
                label = 'External PO #'
                onChange = {props.handleChange}
                sx = {{ width: '20.2%', mr: "0.6%" }}
            />
        </Box>
        <Box className = {props.isMobile ? "mobile-box" : "desktop-box"} sx = {{flexDirection: 'row', mb: '10px'}}>
            <TextField 
                name = 'sku'
                value = {props.sku}
                label = 'SKU'
                onChange = {props.handleChange}
                sx = {{ width: '20.2%', mr: "0.6%" }}
            />
            <TextField 
                name = 'proNumber'
                value = {props.proNumber}
                label = 'PRO Number'
                onChange = {props.handleChange}
                sx = {{ width: '20.2%', mr: "0.6%" }}
            />
            <TextField 
                name = 'uploadId'
                value = {props.uploadId}
                label = 'Upload ID'
                onChange = {props.handleChange}
                sx = {{ width: '20.2%', mr: "0.6%" }}
            />
            { props.orders.length > 0 ?
                <Button 
                    onClick = {() => props.exportOrders(props.orders)} 
                    endIcon = {<FileDownloadIcon />}
                    size = 'large'
                    sx = {{mt: '5px', fontWeight: 'bold', color: '#7d5265'}}>
                    Export
                </Button>
            : null }
        </Box>
        <Box className = {props.isMobile ? "mobile-box" : "desktop-box"} sx = {{flexDirection: 'row', mb: '10px'}}>
            <FormControl sx = {{ width: '15%', mr: "0.6%" }}>
                <AutoComplete
                    value = {props.warehouse}
                    onChange = {(event, newValue) => {
                        props.setWarehouse(newValue ? newValue.value : null);
                    }
                }
                    filterOptions = {(options, params) => {
                        const filtered = filter(options, params);
                        return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id = "warehouse"
                    options = {warehouses}
                    getOptionLabel = {(option) => {
                        if (typeof option === 'string') {
                            return option;
                        }
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                            return option.value;
                    }}
                    renderOption = {(props, option) => {
                        return (
                            <li {...props}>
                                {`${option.value}`}
                            </li>
                        );
                    }}
                    freeSolo
                    forcePopupIcon = {true}
                    renderInput = {(params) => (
                        <TextField {...params} label = "Warehouse" />
                    )}
                />
            </FormControl>
           <FormControl sx = {{ width: '15%', mr: '0.6%' }}>
                <AutoComplete
                    value = {props.orderStatus ? order_statuses.find(option => option.value === props.orderStatus) : null}
                    key = {props.orderStatus}
                    onChange = {(event, newValue) => {
                        props.setOrderStatus(newValue ? newValue.value : null);
                    }}
                    id = "orderStatus"
                    options = {order_statuses}
                    getOptionLabel = {(option) => {
                        if (option.name !== "") {
                            let first = option.name[0].toUpperCase() + option.name.substring(1, option.name.indexOf("_"));
                            let last = option.name.substring(option.name.indexOf("_") + 1);
                            return first + " " + last[0].toUpperCase() + last.substring(1);
                        }
                        return option.name;
                    }}
                    renderOption = {(props, option) => {
                        let name = option.name;
                        if (name !== "") {
                            let first = name[0].toUpperCase() + name.substring(1, name.indexOf("_"));
                            let last = name.substring(name.indexOf("_") + 1);
                            name = first + " " + last[0].toUpperCase() + last.substring(1);
                        }
                        return (
                            <li {...props}>
                                {name}
                            </li>
                        );
                    }}
                    freeSolo
                    forcePopupIcon = {true}
                    renderInput = {(params) => (
                        <TextField {...params} label = "Order Status" />
                    )}
                />
            </FormControl>
            <FormControl sx = {{ width: '15%', mr: '0.6%' }}>
                <AutoComplete
                    value = {acumatica_statuses.find(option => option.value === props.acumaticaStatus) || null}
                    key = {props.acumaticaStatus}
                    onChange = {(event, newValue) => {
                        props.setAcumaticaStatus(newValue ? newValue.value : null);
                    }}
                    id = "acumaticaStatus"
                    options = {acumatica_statuses}
                    getOptionLabel = {(option) => {
                        let name = "";
                        if (option.value) {
                            let temp = option.name;
                            while (temp.includes("_")) {
                                name += temp[0].toUpperCase() + temp.substring(1, temp.indexOf("_")) + " ";
                                temp = temp.substring(temp.indexOf("_") + 1);
                            }
                            name = name + temp[0].toUpperCase() + temp.substring(1);
                        }
                        return name;
                    }}
                    renderOption = {(props, option) => {
                        let name = "";
                        if (option.value) {
                            let temp = option.name;
                            while (temp.includes("_")) {
                                name += temp[0].toUpperCase() + temp.substring(1, temp.indexOf("_")) + " ";
                                temp = temp.substring(temp.indexOf("_") + 1);
                            }
                            name = name + temp[0].toUpperCase() + temp.substring(1);
                        }
                        return (
                            <li {...props}>
                                {name}
                            </li>
                        );
                    }}
                    freeSolo
                    forcePopupIcon = {true}
                    renderInput = {(params) => (
                        <TextField {...params} label = "Acumatica Status" />
                    )}
                />
            </FormControl>
            <FormControl sx = {{ width: '15%', mr: '0.6%' }}>
                <AutoComplete
                    value = {shipment_statuses.find(option => option.value === props.shipmentStatus) || null}
                    key = {props.shipmentStatus}
                    onChange = {(event, newValue) => {
                        props.setShipmentStatus(newValue ? newValue.value : null);
                    }}
                    id = "shipmentStatus"
                    options = {shipment_statuses}
                    getOptionLabel = {(option) => {
                        if (option.name !== "") {
                            let first = option.name[0].toUpperCase() + option.name.substring(1, option.name.indexOf("_"));
                            let last = option.name.substring(option.name.indexOf("_") + 1);
                            return first + " " + last[0].toUpperCase() + last.substring(1);
                        }
                        return option.name;
                    }}
                    renderOption = {(props, option) => {
                        let name = option.name;
                        if (name !== "") {
                            let first = name[0].toUpperCase() + name.substring(1, name.indexOf("_"));
                            let last = name.substring(name.indexOf("_") + 1);
                            name = first + " " + last[0].toUpperCase() + last.substring(1);
                        }
                        return (
                            <li {...props}>
                                {name}
                            </li>
                        );
                    }}
                    freeSolo
                    forcePopupIcon = {true}
                    renderInput = {(params) => (
                        <TextField {...params} label = "Shipment Status" />
                    )}
                />
            </FormControl>
            <Button 
                onClick = {props.getOrders} 
                endIcon = {<SearchIcon />}
                size = 'large'
                sx = {{mt: '10px', fontWeight: 'bold', color: '#84B375'}}>
                Search
            </Button>
        </Box>
    </Box>
    )
}

export default OrderQueryForm;