import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import DailyBuildsQueryForm from './DailyBuildsQueryForm';
import warehouseHelpers from '../../helpers/warehouse';
import DailyBuildsTable from './DailyBuildsTable';

function DailyBuilds(props) {

    const [builds, setBuilds] = useState([]);
    const [groups, setGroups] = useState([]);
    const [line, setLine] = useState([]);
    const [group_data, setGroupData] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [warehouse, setWarehouse] = useState("");


    useEffect(() => {
    }, [])


    const clear = async() => {
        setWarehouse("");
        setGroups([]);
        setLine("");
    }

    const updateWarehouse = async(value) => {
        props.setLoading(true);
        setWarehouse(value);
        let params = { 
            wh: value,
            reported: 0
        };
        let builds = await warehouseHelpers.getBuildsByWarehouse(props.company, props.token, params);
        if(builds){
            setBuilds(builds);
            setGroups(Object.keys(builds));
        }
        props.setLoading(false);
    }

    const loadGroupData = async() => {
        props.setLoading(true);
        setGroupData(builds[line]);
        props.setLoading(false);
    }

    const updateRow = async(params, row) => {
        let updatedParams = await splitID(params);
        await warehouseHelpers.updateOperationsBuild(props.company, props.token, updatedParams);
    }

    const splitID = async(params) =>{
        let separateString = params.id.split('*');
        let [buildDate, sku, orderNbr, line, cust] = separateString;
        let split_obj = {
            build_date: buildDate,
            sku: sku,
            order_number: orderNbr,
            group_number: line,
            customer: cust,
            new_quantity: parseInt(params.value),
            id: params.id
        }
        return split_obj;
        
    }

    const getRowId = (row) =>{
        return (row.build_date + "*" + row.sku + "*" + row.order_number + "*" + row.group_number + "*" + row.customer);
    } 

    const handleEditCellChange = async(params, event) =>{
        await updateRow(params);
    }

    return(
        <Box display="flex" flexDirection="column" className={props.isMobile ? "mobile-top" : "desktop-top"}>
            <Box className={props.isMobile ? "mobile-box" : "desktop-box"}>
                <h1>Builds</h1> 
                <DailyBuildsQueryForm 
                    {...props} 
                    startDate={startDate} 
                    endDate={endDate} 
                    setStartDate={setStartDate} 
                    setEndDate={setEndDate} 
                    warehouse={warehouse} 
                    updateWarehouse={updateWarehouse} 
                    setWarehouse={setWarehouse} 
                    groups={groups} 
                    setGroups={setGroups} 
                    group_data={group_data}
                    setGroupData={setGroupData}
                    loadGroupData={loadGroupData}
                    line={line}
                    setLine={setLine} 
                    clear={clear} />
                {group_data && group_data.length > 0 ? 
                    <DailyBuildsTable 
                        {...props} 
                        group_data={group_data} 
                        updateRow={updateRow} 
                        handleEditCellChange = {handleEditCellChange}
                        getRowId={getRowId}
                    /> : null}
            </Box>
        </Box>
    )
}

export default DailyBuilds