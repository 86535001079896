import {React, useState, useEffect} from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import bulkHelpers from '../../helpers/bulk';

function CarriersPopup(props) {


        const [carrier, setCarrier] = useState("");
        const [scac, setScac] = useState("");
        const [shorthand, setShorthand] = useState("");




        const insertCarrier = async() => {
            props.setLoading(true);
            try {
                if(carrier && scac && shorthand) {
                    let c = {
                        carrier: carrier,
                        scac: scac,
                        shorthand: shorthand
                    }
                    props.toast.promise(bulkHelpers.insertCarrier(JSON.stringify(c), props.token, props.company + "_bulk"), {
                        loading: "Uploading...",
                        success: (res) => {
                            props.setOpen(false);
                            return `Carrier Added!`
                        },
                        error: (err) => `${err.message}`
                    })                    
                }
            }
            catch(err) {
                console.log(err);
            }
            finally {
                props.setLoading(false);
            }
        }


        return(
            <Dialog open={props.open} onClose={()=>props.setOpen(false)}>
            <DialogTitle>
                    Update Order Shipment Info:
            </DialogTitle>
            <DialogContent>
                <Box>
                    <TextField sx={{m:"8px"}} label="Carrier Name" value={carrier} onChange={(e)=>setCarrier(e.target.value)} />
                    <TextField sx={{m:"8px"}} label="SCAC" value={scac} onChange={(e)=>setScac(e.target.value)} />
                    <TextField sx={{m:"8px"}} label="Shorthand" value={shorthand} onChange={(e)=>setShorthand(e.target.value)} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>props.setOpen(false)}>Cancel</Button>
                <Button onClick={()=>insertCarrier()} autoFocus>
                    Update
                </Button>
            </DialogActions>
        </Dialog>
        )
}

export default CarriersPopup;