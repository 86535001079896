import Box from '@mui/material/Box';

import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import bulkHelpers from '../../helpers/bulk';
function ShortItemsPopup(props) {

    const [changes, setChanges] = useState({})

    const handleChange = async(e, wh, item) => {
        let val = Number(e.target.value);
        let oth_wh = "pa";
        if(wh === "pa") oth_wh = "ky";
        if(val + (item["fulfill_" + oth_wh] || 0) > item.quantity) {
            e.target.value = item["fulfill_" + wh];
            return;
        }
        let temp = props.order;
        let index = temp.items.findIndex(obj => obj.internal_sku === item.internal_sku);
        temp.items[index]["fulfill_" + wh] = val;
        props.setOrder(temp);

    }

    const saveChanges = async(sku, qty) => {
        let temp = JSON.parse(JSON.stringify(changes))
        temp[sku] = qty;
        setChanges(temp);
    }

    const save = async() => {
        props.toast.promise(saveHelper(), {
            success: "Saved Shorts",
            error: "Failed to Save Shorts"
        })
    }

    const saveHelper = async() => {
        try {
            let cancel_skus = Object.keys(changes).filter(ele => changes[ele]);
            // await bulkHelpers.saveChanges(changes, props.company, props.token);
            // props.handleClose();
            console.log(cancel_skus)
            if(window.confirm(`Short the following items from order ${props.po}?
            
            ${cancel_skus}`)) {
                props.setLoading(true);
                await bulkHelpers.saveItemShorts(JSON.stringify({items: cancel_skus, internal_po_number: props.po, vendor_name: props.vendor_name}), props.company + "_bulk", props.token);
                props.setLoading(false);
            }
        }
        catch(err) {
            console.log(err);
            throw err;
        }
    }

    const handleCheck = async(sku, e) => {
        let temp = JSON.parse(JSON.stringify(changes))
        temp[sku] = e.target.checked;
        setChanges(temp);
    }

    return(
        <Dialog open={props.open} onClose={()=>props.handleClose()}>
            <DialogTitle>
                    Short Items on Order {props.internal_po_number}:
            </DialogTitle>
            <DialogContent>
                <Box className={props.isMobile ? "mobile-table-box" : "desktop-table-box"} style={{width:"100%"}}>
                    <TableContainer className={props.isMobile ? "mobile-table-box" : "desktop-table-box"}>
                        <Table className={props.isMobile ? "mobile-table" : "desktop-table"}  sx={{zIndex:0}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" style={{fontWeight: "bold"}}>SKU</TableCell>
                                    <TableCell align="center" style={{fontWeight: "bold"}}>Order Quantity</TableCell>
                                    <TableCell align="center" style={{fontWeight: "bold"}}>Cancel</TableCell>
                                </TableRow>
                            </TableHead>
                                <TableBody>
                                    {props.items.map((row, index) => {
                                        return(
                                            <TableRow key={index} tabIndex={-1}>
                                                <TableCell align="center">{row.internal_sku}</TableCell>
                                                <TableCell align="center">{row.quantity}</TableCell>
                                                <TableCell align="center"><Checkbox name="Cancel" onChange={(e)=>handleCheck(row.internal_sku, e)} /></TableCell>
                                            </TableRow> 
                                        )
                                    })}
                                </TableBody>
                        </Table>
                        </TableContainer>
                </Box>   
                </DialogContent>
                <DialogActions>
                    <Box display="flex" style={{width:"100%"}}>
                        <Button style={{marginLeft:"20px", width:"20%"}} onClick={()=>props.handleClose()} disabled={props.isLoading}>Cancel</Button>
                        <Button style={{marginLeft:"auto", marginRight:"20px", width:"20%"}} onClick={()=>save()} disabled={props.isLoading}>Save</Button>
                    </Box>
                </DialogActions>
        </Dialog>
    )


}

export default ShortItemsPopup;