import {React, useState} from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';

function ExportPopup(props) {




    
        return(
            <Dialog open={props.open} onClose={()=>props.handleClose()}>
            <DialogTitle>
                    Select Date Range to Export:
            </DialogTitle>
            <DialogContent>
                <Box display="flex" flexDirection="column" style={{height: "200px"}}>
                    <LocalizationProvider dateAdapter={AdapterMoment} style={{marginTop:"10px"}}>
                        <DatePicker label="Start Range" value={props.startDate} onChange={(new_date) => {props.setStartDate(new_date)}} renderInput={(params) => <TextField style={{margin:"auto", marginTop:"10px"}} {...params} />} />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterMoment} style={{marginTop:"10px"}}> 
                        <DatePicker label="End Range" value={props.endDate} onChange={(new_date) => {props.setEndDate(new_date)}} renderInput={(params) => <TextField style={{margin:"auto", marginTop:"10px"}} {...params} />} />
                    </LocalizationProvider>
                    <FormControl style={{margin:"auto", marginTop:"10px", width:"100%"}}>
                        <InputLabel id="warehouse">Warehouse</InputLabel>
                        <Select
                            name='warehouse'   
                            label="Warehouse"        
                            onChange={(new_wh) => props.setWh(new_wh.target.value)}
                            defaultValue={props.wh||props.warehouse}
                        >
                            <MenuItem value='kentucky'>Kentucky</MenuItem>
                            <MenuItem value="pennsylvania">Pennsylvania</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>props.handleClose()}>Cancel</Button>
                <Button variant="contained" onClick={()=>props.exportOrders(props.startDate, props.endDate, props.wh)} autoFocus>
                    Export
                </Button>
            </DialogActions>
        </Dialog>
        )
}

export default ExportPopup;