import React, {useEffect, useState} from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

function JobTable(props) {


    return (
        <Box display="flex" flexDirection="column" sx={{mt:"30px"}} className={props.isMobile ? "mobile-box" : "desktop-box"}>
            <Button color="primary" variant="contained" style={{marginTop:"10px", marginBottom:"10px", width:props.isMobile ? "100px" : "50%"}} onClick={()=>props.setRefresh(!props.refresh)}>Refresh</Button>
            {props.jobs ?
                <TableContainer className={props.isMobile ? "mobile-table-box" : "desktop-table-box"} style={{height: "500px", overflowY:"scroll", width:props.isMobile? "100px":"50%"}}>
                <Table className={props.isMobile ? "mobile-table" : "desktop-table"}  sx={{zIndex:0}}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" style={{fontWeight: "bold"}}>Upload Date</TableCell>
                            <TableCell align="center" style={{fontWeight: "bold"}}>File Name</TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center"></TableCell>
                        </TableRow>
                    </TableHead>
                    {props.jobs.length > 0 ? 
                        <TableBody>
                            {props.jobs.length > 0 ? props.jobs.map((row, index)=> {
                                return(
                                    <TableRow key={index} tabIndex={-1}>
                                        <TableCell align="center">{row.date.replace("T", " | ").replace(".000Z", "")}</TableCell>
                                        <TableCell align="center">{row.file_name}</TableCell>
                                        <TableCell align="center"><Button onClick={()=>props.downloadData(row.job_id, row.file_name)} size="small" variant="contained" color="secondary">Download</Button></TableCell>
                                    </TableRow> 
                                )
                            }) : null}
                        </TableBody>
                    : <TableBody />}
                </Table>
                </TableContainer>
            : null}
        </Box>
    )
}

export default JobTable;