import { isItemValid } from './OrderUtils';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { useState } from 'react';
import DialogActions from '@mui/material/DialogActions';


const OrderDetails = props => {

    const [shortItemInfo, setShortItemInfo] = useState({});

    const getItemRowClassName = params => {
        const itemInfo = params.row;
        return isItemValid(itemInfo) ? "green-table-row" : "red-table-row";
    };

    const handleShort = () => {
        props.shortItem({
            internal_sku: shortItemInfo.internal_sku,
            vendor_name: shortItemInfo.vendor_name
        });
        setShortItemInfo({});
    };

    const itemTableColumns = [{
        field: 'description',
        headerName: "Description",
        flex: 1,
        editable: false,
        minWidth: 300,
        maxWidth: 300
    }, {
        field: 'expiration_date',
        headerName: "Expiration date",
        flex: 1,
        editable: false,
        minWidth: 100,
        maxWidth: 200
    }, {
        field: 'internal_sku',
        headerName: "Internal SKU",
        flex: 1,
        editable: false,
        minWidth: 100,
        maxWidth: 150
    }, {
        field: 'lot_id',
        headerName: "Lot ID",
        flex: 1,
        editable: false,
        minWidth: 100,
        maxWidth: 100
    }, {
        field: 'lot_number',
        headerName: "Lot number",
        flex: 1,
        editable: false,
        minWidth: 120,
        maxWidth: 120
    }, {
        field: 'vendor_name',
        headerName: "Vendor name",
        flex: 1,
        editable: false,
        minWidth: 100,
        maxWidth: 200
    }, {
        field: "short_button",
        headerName: "Short",
        renderCell: params => {
            return (
                <Button variant="contained" style={{ backgroundColor: "#666666", color: "#FFFFFF" }} onClick={() => setShortItemInfo(params.row)}>Short</Button>
            );
        }
    }];

    const itemTableRows = props.selectedOrder.items?.map(item => ({
        ...item,
        id: item.internal_sku
    }));

    return (
        <>
            <Dialog open={shortItemInfo.internal_sku !== undefined} onClose={() => setShortItemInfo({})}>
                <DialogTitle>Are you sure you want to short the SKU?</DialogTitle>
                <DialogActions>
                    <Button variant="contained" color="success" onClick={handleShort}>Short SKU</Button>
                    <Button variant="contained" color="error" onClick={() => setShortItemInfo({})}>Cancel</Button>
                </DialogActions>
            </Dialog>
            <Drawer open={Object.keys(props.selectedOrder).length > 0} onClose={() => props.setSelectedOrder({})} anchor="bottom">
                {Object.keys(props.selectedOrder).length > 0 && <Box className={props.isMobile ? "mobile-table-box" : "desktop-table-box"} style={{ height: "500px", width: "80%", marginLeft: "10%" }}>
                    <h2>PO: {props.selectedOrder.internal_po_number}</h2>
                    <h4>Upload Date: {props.selectedOrder.upload_date}</h4>

                    <DataGrid
                        style={{ width: "100%" }}
                        rows={itemTableRows}
                        className={props.isMobile ? "mobile-table" : "desktop-table"}
                        columns={itemTableColumns}
                        rowsPerPageOption={[25, 50, 100]}
                        checkboxSelection={false}
                        getRowClassName={getItemRowClassName}
                        onRowClick={() => { }}
                    />
                </Box>}
            </Drawer>
        </>
    );
};

export default OrderDetails;