import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import BulkInvoiceTable from './BulkInvoiceTable'
import Button from '@mui/material/Button';
import bulkHelpers from '../../helpers/bulk'
import BulkInvoiceDrawer from './BulkInvoiceDrawer'

function BulkInvoices(props) {
    const [refresh, setRefresh] = useState(false);
    const [invoices, setInvoices] = useState([])
    const [openDetail, setOpenDetail] = useState([]);

    useEffect(() => {
        const getInvoices = async() => {
            let res = await bulkHelpers.getBulkInvoices(props.company + "_bulk", props.token)
            console.log(res.data)
            setInvoices(res.data.map((obj, index) => {return {...obj, id: index}}))
        }
        getInvoices();
    }, [refresh])

    
    const setOpenDetails = async(order = {}) => {
        if(Object.keys(order)?.length === 0 ) {
            setOpenDetail(order);
        }
        else {
            setOpenDetail(order.row);
        }
    }

    return(
        <Box display="flex" flexDirection="column" className={props.isMobile ? "mobile-top" : "desktop-top"}>
            <Box className={props.isMobile ? "mobile-box" : "desktop-box"}>
                <h1>Bulk Invoices</h1> 
                <BulkInvoiceDrawer {...props} setOpenDetails={setOpenDetails} order={openDetail} />
                <Button onClick={()=>setRefresh(!refresh)} variant="contained">Refresh</Button>
                <BulkInvoiceTable {...props} setOpenDetails={setOpenDetails} invoices={invoices} />
            </Box>
        </Box>
    )
}

export default BulkInvoices;