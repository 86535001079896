import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import {lighten } from '@mui/material/styles';

const columns = [ 
    {
        field: 'id',
        headerName: "ID",
        flex: 1,
        editable: false,
        minWidth: 100,
        display: false
    },
    {
        field: 'request_id',
        headerName: "Request ID",
        flex: 1,
        editable: false,
        minWidth:100
    },
    {
        field: 'internal_po_number',
        headerName: 'PO',
        flex: 1,
        editable: false,
        minWidth: 200
    }, 
    {
        field: "vendor_name",
        headerName: "Vendor",
        flex:1,
        editable: false,
        minWidth: 200
    },
    {
        field: "type",
        headerName: "Type",
        flex: 1,
        editable: false,
        minWidth: 100
    },
    {
        field: "change_date",
        headerName: "Request Date",
        flex: 1,
        editable: false,
        minWidth: 150,
        renderCell: (params) => {
            return(<Box>{params.row.change_date.substring(0, 10)}</Box>)
        }
    },
    {
        field: "details",
        headerName: "Changes",
        flex: 1,
        editable: false,
        minWidth: 100,
        renderCell: (params) => {
            return(<Box>{params.row.details.length || 0}</Box>)
        }
    },
    {
        field: 'request_status',
        headerName: "Status",
        editable: false,
        minWidth: 200,
        renderCell: (params) => {
            let status;
            switch(params.row.request_status) {
                case 800:
                    status = "New"
                    break;
                case 801:
                    status = "Accepted"
                    break;
                case 899:
                    status = "Rejected"
                    break;
                case -801:
                    status = "Error"
                    break;
            }
            return(
                <Box style={{whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis"}}>{status}</Box>
            )
        },
    }
]


function ChangeTable(props) {

    return(
        <Box className={props.isMobile ? "mobile-table-box" : "desktop-table-box"} style={{width:"70%"}}>
            <DataGrid
                loading={props.loading}
                slots={{ toolbar: GridToolbar }} 
                style={{width:"100%"}}
                rows={props.requests}
                className={props.isMobile ? "mobile-table" : "desktop-table"}
                columns={columns}
                rowsPerPageOption = {[25, 50, 100]}
                checkboxSelection = {false}
                onSelectionModelChange = {(selectionModel) => {
                    props.handleSelect(selectionModel);
                }}
                selectionModel={props.selected}
            />
        </Box>   
    )


}

export default ChangeTable;