import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TransactionTable from './TransactionTable';

function TransactionReport(props) {
    const [page, setPage] = useState(0)


    const handlePageChange = async(e, value) => {
        setPage(value);
    }

        return(
            <Box display="flex" flexDirection="column" className={props.isMobile ? "mobile-top" : "desktop-top"}>
                <Box className={props.isMobile ? "mobile-box" : "desktop-box"}>
                    <h1>EDI Transaction Report</h1> 
                    <Box className={props.isMobile ? "mobile-box" : "desktop-box"}>
                        <Tabs value={page} onChange={handlePageChange}>
                            <Tab align="center" label="Dropship" />
                            <Tab align="center" label="Bulk" />
                        </Tabs>
                        <TransactionTable {...props} type={page === 0 ? "Dropship" : "Bulk"}  />
                    </Box>
                </Box>
            </Box>
        )
}

export default TransactionReport;