import React, { Component } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip'
import bulkHelpers from '../../helpers/bulk';

const columns = [ 
    {
        field: 'gs_id',
        headerName: 'Functional ID',
        minWidth: 100,
        editable: false,
        flex: 1
    },  
    {
        field: 'vendor_name',
        headerName: 'Vendor',
        minWidth: 100,
        editable: false,
        flex: 1
    }, 
    {
        field: 'message_date',
        headerName: 'Message Date',
        minWidth: 100,
        editable: false,
        flex: 1,
        renderCell: (params) => {
            let date = new Date(params.row.message_date).toLocaleString("en-US", {timeZone: "America/Los_Angeles"}).replace("T", " ").replace("Z", " ").replace(".000", "");
            return(
                <Tooltip title={date}>
                    <Box style={{whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis"}}>{date}</Box>
                </Tooltip>
            )
        }
    },
    {
        field: 'internal_po_number',
        headerName: 'Internal PO #',
        minWidth: 100,
        editable: false,
        flex: 1
    },
    {
        field: 'status',
        headerName: 'Status',
        minWidth: 100,
        editable: false,
        flex: 1
    },  
    {
        field: 'type',
        headerName: 'Message Type',
        minWidth: 100,
        editable: false,
        flex: 1
    },  
    {
        field: 'direction',
        headerName: 'Direction',
        minWidth: 100,
        editable: false,
        flex: 1
    }, 
    {
        field:"id",
        headerName: "Download",
        minWidth: 100,
        editable: false,
        renderCell: (params) => {
            return (<Box id={params.row.id}><Button  size="small"  onClick={()=>downloadFile(params.row.gs_id, params.row.vendor_name, params.row.type, params.row.direction)}>Download</Button></Box>)
        },
        flex: 1

    }
]

let downloadFile = null;

function TransactionData(props) {

    downloadFile = async(gs_id, vendor_name, type, direction) => {
        props.toast.promise(getFile(gs_id, vendor_name, type, direction), {
            success: "Downloaded",
            error: "Failed to Download Message"
        });
    }

    const getFile = async(gs_id, vendor_name, type, direction) => {
        let res;
        console.log("gs_id", gs_id);
        console.log("vendor_name", vendor_name);
        console.log("type", type);
        console.log("direction", direction);
        if(props.type === "Bulk")
            res = await bulkHelpers.downloadBulkTransaction({gs_id: gs_id, vendor_name: vendor_name, type: type, direction:direction}, props.company + "_bulk", props.token);
        else 
            res = await bulkHelpers.downloadDropshipTransaction({gs_id: gs_id, vendor_name: vendor_name, type: type, direction:direction}, props.company, props.token);
        console.log(res.data);
        let a = document.createElement("a");
        a.setAttribute("href", res.data);
        a.setAttribute("download", gs_id);
        a.setAttribute("target", "_blank")
        document.body.appendChild(a);
        a.click();
    }

        return(
            <Box className={props.isMobile ? "mobile-box" : "desktop:box"} sx={{display: 'flex', flexDirection: 'column'}}>
                <Box className={props.isMobile ? "mobile-table-box" : "desktop-table-box"} style={{ height: 1000, marginTop: '50px'}}>
                    <DataGrid
                    className={props.isMobile ? "mobile-table" : "desktop-table"}
                    rows={props.transactions}
                    columns={columns}
                    rowsPerPageOption = {[25, 50, 100]}
                    // onCellClick={(params, event) => {
                    //     console.log({params, event})
                    // }}
                    />
                </Box>
            </Box>
        )
    
}

export default TransactionData;