import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import warehouseHelpers from '../../helpers/warehouse';
import HourlyBuildsTable from './HourlyBuildsTable';
import HourlyBuildsQueryForm from './HourlyBuildsQueryForm';


function HourlyBuilds(props) {


    const [warehouse, setWarehouse] = useState();
    const [shift, setShift] = useState("First");
    const [hourlyBuilds, setHourlyBuilds] = useState(null);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [totalBuilds, setTotalBuilds] = useState();



    const _ = require('lodash');

    useEffect(() => {
        let intervalId = setInterval(() => {
            setWarehouse(localStorage.getItem("warehouse"));
            setShift(localStorage.getItem("shift"));
            setStartDate(localStorage.getItem("startDate"));
            setEndDate(localStorage.getItem("endDate"));
            console.log("REFRESH!");
            search();
          }, 1800000 );
          return () => clearInterval(intervalId);
        }, []);

    const search = async() => {
        props.setLoading(true);
        let download_builds = await downloadBuilds()
        let num_of_days = await findDayDifference();
        await getBuilds(download_builds, num_of_days);
        props.setLoading(false);
    }

    const downloadBuilds = async() => {
        let params = await getParams();
        let res = await warehouseHelpers.getBuildsByWarehouse(props.company, props.token, params);
        return res;
    }

    const getBuilds = async(download_builds, num_of_days) => {
        let hourly_builds  = await seperateByHour(download_builds, 'build_date');
        let sum = await calculateHourlySum(hourly_builds);
        if(shift !== "Both") sum = await seperateShifts(sum);
        let total = calculateTotalBuild(sum);
        setTotalBuilds(total);
        let formatted_obj = await convertTimeFormat(sum);
        setHourlyBuilds(formatted_obj);
    }

    const seperateShifts = async(input) => {
        let result = {};
        for (let key in input) {
            if (input.hasOwnProperty(key)) {
                let time_data = input[key];
                let shift_start = 5;
                let shift_end = 16;

                let shift_filtered_values = {};

                if(shift === 'First'){
                    for (let [index, value] of Object.entries(time_data)) {
                        if (index >= shift_start && index <= shift_end && value > 0) {
                            shift_filtered_values[index] = value;
                        }
                    }
                }
                else{
                    for (let [index, value] of Object.entries(time_data)) {
                        if ((index < shift_start || index > shift_end) && value > 0) {
                            shift_filtered_values[index] = value;
                        }
                    }
                }
                if (Object.keys(shift_filtered_values).length > 0) {
                    result[key] = shift_filtered_values;
                }
            }

        }  
        return result;
    }

    const seperateByHour = async(inputData, attr) => {
        let result = {};
        let data = Array.isArray(inputData) ? inputData : Object.entries(inputData);
        data.forEach(([key, values]) => {
            let separated_data = {};
        
            for (let hour = 0; hour < 24; hour++) {
                separated_data[hour] = [];
            }
        
            values.forEach(obj => {
              let hour = new Date(obj[attr]).getUTCHours();
              separated_data[hour].push(obj);
            });
        
            result[key] = separated_data;
          });
        return result;
    };

    const getParams = async() => {
        await setDefaultDateValues();
        let formatted_state_date = await warehouseHelpers.convertDate(localStorage.getItem("startDate"), -4);
        let formatted_end_date = await warehouseHelpers.convertDate(localStorage.getItem("endDate"), 20);
        let params = {
            wh: localStorage.getItem("warehouse"),
            start_date: formatted_state_date, 
            end_date: formatted_end_date,
            shift: localStorage.getItem("shift")
        }
        return params;
    }

    const findDayDifference = async() => {
        let day_difference = (endDate - startDate)/86400000
        day_difference = Math.ceil(day_difference) + 1;
        return day_difference;
    }

    const setDefaultDateValues = async() => {
        let today = new Date();
        today.setHours(0,0,0,0);
        if(localStorage.getItem("startDate").length === 0){
            setStartDate(today);
        }
        if(localStorage.getItem("endDate").length === 0){
            let tomorrow = new Date(today);
            tomorrow.setDate(today.getDate() + 1);
            tomorrow.setHours(5,0,0,0);
            setEndDate(tomorrow);
        }
    }

    const calculateHourlySum = async(res) => {
        let result = {};

        Object.entries(res).forEach(([key, values]) => {
          let summed_data = {};
          Object.entries(values).forEach(([hour, objects]) => {
            let total_quantity = objects.reduce((sum, obj) => sum + (obj.quantity || 0), 0);
            summed_data[hour] = total_quantity;
          });
          result[key] = summed_data;
        });
      
        return result;
    }    

    const convertTimeFormat = async(input) => {
        let out = {};
        let order = Object.keys(input); // Maintain the order of keys
    
        order.forEach((key) => {
            let hour_data = input[key];
            let formatted_hour_data = {};
    
            for (let i = 5; i <= 28; i++) {
                let hour = (i % 12) || 12; // Convert to 12-hour format
                let ampm = i < 12 || i >= 24 ? 'AM' : 'PM';
                let timeKey = `${hour}:00 ${ampm}`;
                formatted_hour_data[timeKey] = hour_data[i % 24] || 0;
            }
    
            out[key] = formatted_hour_data;
        });

        return out;
    }

    const handleWarehouseChange = (val) => {
        setWarehouse(val);
        localStorage.setItem("warehouse", val);
    }

    const handleShiftChange = (val) => {
        setShift(val);
        localStorage.setItem("shift", val);
    }

    const handleStartDateChange = (val) =>{
        setStartDate(val);
        localStorage.setItem("startDate", val);
    }
    
    const handleEndDateChange= (val) =>{
        setEndDate(val);
        localStorage.setItem("endDate",val);
    }
    
    const calculateTotalBuild = (builds) => {
        let total = 0;

        Object.values(builds).forEach(innner_obj => {
            Object.values(innner_obj).forEach(value => {
                total += value;
            });
        });
        return total;
    }

    return(
        <Box display="flex" flexDirection="column" className={props.isMobile ? "mobile-top" : "desktop-top"}>
            <Box className={props.isMobile ? "mobile-box" : "desktop-box"}>
                <h1>Hourly Builds</h1> 
                <HourlyBuildsQueryForm
                    warehouse={warehouse}
                    handleWarehouseChange={handleWarehouseChange}
                    handleShiftChange={handleShiftChange}
                    handleStartDateChange={handleStartDateChange}
                    handleEndDateChange={handleEndDateChange}
                    shift={shift}
                    search={search}
                    startDate={startDate?.full}
                    endDate={endDate?.full}
                 />
               {hourlyBuilds !== null && !props.error  ? <HourlyBuildsTable  
                    shift={shift}
                    getBuilds={getBuilds}
                    hourlyBuilds={hourlyBuilds}
                    setHourlyBuilds={setHourlyBuilds}
                    totalBuilds={totalBuilds}
                    {...props} /> : null}
            
            </Box>
        </Box>
    )
}

export default HourlyBuilds;