import React, {useEffect, useState} from 'react';
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import CircularProgress from '@mui/material/CircularProgress';
import ListItemText from '@mui/material/ListItemText'
import bulkHelpers from '../../helpers/bulk';
import orderHelpers from '../../helpers/orders';
import RequestDetails from './RequestDetails';
import ChangePopup from './ChangePopup';
import OrderInfo from './OrderInfo';
import CustomerInfo from './CustomerInfo';

function ChangeDetails(props) { 
    const [order, setOrder] = useState({})
    const [open, setOpen] = useState(false);
    const [requested, setRequested] = useState({});

    let changes = null;

    useEffect(() => {
        const downloadOrder = async() => {
            if(!props.order) return;
            changes = props.order.details
            console.log("downloadOrder");
            let error = null;
            try {
                props.setLoading(true)
                
                let out = [];
                let out_items = [];
                let j = 1;
                let i_price = 0;
                let ex_price = 0;
                let body = {internal_po_number: props.order.internal_po_number, vendor_name: props.order.vendor_name}
                let res = await orderHelpers.getBulkOrders(props.company + "_bulk", body, props.token);
                if(res.length === 0) {
                    setOrder({});
                    return;
                }
                let res_out = res[0];
                res_out.items = [];
                let skus = [...new Set(res.map(obj => obj.internal_sku))];
                let i = 1;
                for(let sku of skus) {
                    let items = res.filter(obj => obj.internal_sku === sku);
                    let count = items.reduce((a, b) => a + b.quantity, 0);
                    let item = items[0];
                    let kentucky = items.filter(obj => obj.decision === "Kentucky").reduce((a, b) => a + b.quantity, 0);
                    let pennsylvania = items.filter(obj => obj.decision === "Pennsylvania").reduce((a, b) => a + b.quantity, 0);
                    let backordered = items.filter(obj => obj.decision === "Back Ordered").reduce((a, b) => a + b.quantity, 0);
                    let discontinued = items.filter(obj => obj.decision === "Discontinued").reduce((a, b) => a + b.quantity, 0);
                    let cancelled = items.filter(obj => obj.decision === "Cancel").reduce((a, b) => a + b.quantity, 0);

                    res_out.items.push({
                        id: i,
                        internal_sku: item.internal_sku,
                        external_sku: item.external_sku,
                        external_price: item.external_price || 0,
                        internal_price: item.internal_price || 0,
                        quantity: count,
                        kentucky: kentucky,
                        pennsylvania: pennsylvania,
                        backordered: backordered,
                        cancelled: cancelled,
                        discontinued: discontinued
                    });
                    out_items.push({
                        id: j,
                        internal_po_number: props.order.internal_po_number,
                        internal_sku: item.internal_sku,
                        external_sku: item.external_sku,
                        external_price: item.external_price || 0,
                        internal_price: item.internal_price || 0,
                        quantity: count,
                        kentucky: kentucky,
                        pennsylvania: pennsylvania,
                        backordered: backordered,
                        cancelled: cancelled,
                        discontinued: discontinued
                    })
                    i_price += item.internal_price || 0;
                    ex_price += item.external_price || 0;
                    j++;
                    i++;
                }
                let temp = JSON.parse(JSON.stringify(res_out));
                for(let change of changes) {
                    switch(change.type) {
                        case "date_change":
                            let index = "";
                            switch(change.detail) {
                                case "Delivery":
                                    index = "deliver_by"
                                    break;
                                case "Requested Ship":
                                    index = "requested_ship_date";
                                    break;
                                case "Ship Window Start":
                                    index = "requested_ship_window_start";
                                    break;
                                case "Ship Window End":
                                    index = "requested_ship_window_end";
                                    break;
                                case "Requested Pickup":
                                    index = "requested_pickup";
                                    break;
                            }
                            let val = change.value;
                            if(!val.includes("-")) val = [change.value.slice(0, 4), change.value.slice(4,6), change.value.slice(6,8)].join('-');
                            temp[index] = val
                            break;
                        case "address_change":
                            let addr = change.value.split("|");
                            if(change.detail === "Bill-To") {
                                temp.billing_attention = addr[0];
                                temp.billing_address1 = addr[1];
                                temp.billing_city = addr[2];
                                temp.billing_state = addr[3];
                                temp.billing_zip_code = addr[4];
                            }
                            else {
                                temp.attention = addr[0];
                                temp.location = addr[1];
                                temp.address1 = addr[2];
                                temp.city = addr[3];
                                temp.state = addr[4];
                                temp.zip_code = addr[5];
                            }
                            break;
                        case "update_qty":
                            let found = false;
                            for(let row of temp.items) {
                                if(row.internal_sku === change.detail) {
                                    row.quantity = change.value;
                                    found=true;
                                }
                            }
                            if(!found) {
                                temp.items.push({
                                    internal_sku: change.detail,
                                    quantity: change.value
                                })
                                res_out.items.push({
                                    internal_sku: change.detail,
                                    quantity: 0
                                })                         
                            }
                            break;
                        case "update_price":
                            for(let row of temp.items) {
                                if(row.internal_sku === change.detail) {
                                    row.external_price = change.value;
                                    break;
                                }
                            }
                            break;
                        case "cancel":
                            for(let row of temp.items) {
                                if(row.internal_sku === change.detail) {
                                    row.quantity = 0;
                                    break;
                                }
                            }
                            break;
                    }
                }
                res_out.price = res_out.items.reduce((a, b) => a + ((b.quantity || 0) * (b.external_price|| 0)), 0);
                temp.price = temp.items.reduce((a, b) => a + ((b.quantity || 0) * (b.external_price|| 0)), 0);
                setRequested(temp);
                setOrder(res_out);
            }
            catch(err) {
                console.log(err);
                error = err;
            }
            finally {
                props.setLoading(false);
                if(error) throw error;
            }
        }
        downloadOrder()
    }, [props.order])

    const acceptChanges = async() => {
        let req = JSON.stringify({request_id: props.order.request_id, internal_po_number: order.internal_po_number, vendor_name: order.vendor_name})
        props.toast.promise(bulkHelpers.acceptChanges(req, props.company + "_bulk", props.token), {
            success: () => {
                props.handleClose();
                return <Box display="flex" flexDirection="column">
                    Sent Request {props.order.request_id} For Processing.
                    <b>YOU WILL NEED TO UPDATE ACUMATICA MANUALLY.</b>
                </Box>
            },
            error: (err) => {
                return `Error: ${err.message}`
            }
        })
    }

    return (
        <Drawer open={props.open} onClose={props.handleClose} anchor="bottom">
            <ChangePopup {...props} changes={props.order?.details} open={open} setOpen={setOpen} />
            {props.isLoading ? 
            <Box style={{height: "500px"}}  display="flex" flexDirection="column" sx={{textAlign: "center", m:"auto", mb:"80px", pt:"20px", width:"80%"}}>
                <CircularProgress size="200px" color="success" style={{margin:"auto"}} />
                <div style={{fontSize:"50px", marginTop:"-200px"}}>Loading...</div>
            </Box> : 
            <Box style={{height: "500px"}}  display="flex" sx={{m:"auto", mb:"80px", pt:"20px", width:"90%"}}>
                <OrderInfo {...props} order={order} requested={requested} setOpen={setOpen} />
                <Divider sx={{ml:"10px", mr:"10px"}} orientation="vertical" flexItem />
                <CustomerInfo {...props} order={order} requested={requested} />
                <Divider sx={{ml:"10px", mr:"10px"}} orientation="vertical" flexItem />
                <RequestDetails items={order?.items} changes={requested?.items} {...props} />
                <Button onClick={acceptChanges} variant="contained" color="success" style={{marginLeft:"auto", marginRight:"0", padding: "30px", width:"120px"}}>Approve</Button>
            </Box>
        }
        </Drawer>
    )

}

export default ChangeDetails;


