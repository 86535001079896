
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import inventoryHelpers from '../../helpers/inventory';
import { CSVReader } from 'react-papaparse';
import {jsPDF} from "jspdf"


function Sticker(props) {
    const buttonRef = React.createRef();
    const [error, setError] = useState("");
    const [file, setFile] = useState(null);
    const [success, setSuccess] = useState(false);
    const [items, setItems] = useState(null);
    const [type, setType] = useState(props.type);
    
    const handleOpenDialog = (e) => {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
            buttonRef.current.open(e);
        }
    };

    const generateLabels = async(out) => {
        try {
            setError("");
            let is_generic = type === "Generic"
            await props.generateLabels(out, is_generic);
        }
        catch(err) {
            console.log(err);
            setError(err.message);
        }
    }

    const handleOnError = (err, file, inputElem, reason) => {
        console.log('---------------------------');
        console.log(err);
        console.log('---------------------------');
        setError(`Error: ${file}`)
    };
    
    const handleRemoveFile = (e) => {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
            buttonRef.current.removeFile(e);
            setFile(null);
            setItems(null);
        }
    };

    const formatItems = (data) => {
        const itemArray = [];
        data.forEach(item =>{
            itemArray.push(item.data)
        })
        return itemArray;
    }



    const handleOnFileLoad = (data, file) => {
        console.log('---------------------------');
        setFile(file);
        setSuccess(false);
        const new_items = formatItems(data);
        setItems(new_items);
        console.log('---------------------------');
    };
    

    return(
        <Box className={props.isMobile ? "mobile-box" : "desktop-box"} display="flex" flexDirection="column" style={{padding: "10px", marginLeft: "20px", width: "15%", border: "1px solid black"}}>
            <h4>{props.type} Stickers</h4>
            <Button onClick={props.downloadTemplate}>Download Template</Button>
            {error !== "" ? <Alert style={{marginTop:"24px", color:"white", width:"80%"}} severity="error" className={`${props.company} ${props.warehouse} error`}>{error}</Alert> : null}
            <CSVReader
                ref={buttonRef}
                onFileLoad={handleOnFileLoad}
                onError={handleOnError}
                noClick
                noDrag
                config={{
                    header: true,
                    skipEmptyLines: true
                    }}
                    style={{}}
                    >
                    {({ file_obj }) => (
                    <aside>
                        <Button variant='contained' onClick={handleOpenDialog} className={`${props.company} ${props.warehouse} secondary`} style={{margin:"auto"}}>Browse file</Button>
                        <div>
                        {file_obj && file_obj.name}
                        </div>
                        {file !== null ? 
                        <Box style={{marginTop:"10px"}}>
                            <Button variant='contained' onClick={() => generateLabels(items)} className={`${props.company} ${props.warehouse} secondary`}
                            // sx={{mr: '10px'}}
                            >Upload</Button>
                            <Button variant='contained' sx={{ml:"30px"}} onClick={handleRemoveFile} className={`${props.company} ${props.warehouse} tertiary`}>Remove</Button>
                        </Box>
                        : null}
                    </aside>)}
            </CSVReader>
        </Box>
    )
}

export default Sticker