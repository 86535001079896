import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab';
import BulkOrderTableTemplate from './BulkOrderTableTemplate';
import ProcessingDrawer from './Processing/ProcessingDrawer';
import ShippingPopup from './Shipping/ShippingPopup';
import RoutingPopup from './Shipping/RoutingPopup';
import bulkHelpers from '../../helpers/bulk'

function BulkOrders(props) {
    const [value, setValue] = useState(0);
    const [selectedOrder, setSelectedOrder] = useState([]);
    const [processing, setProcessing] = useState(false);
    const [shipOverride, setShipOverride] = useState(false);
    const [openRoute, setOpenRoute] = useState(false);

    const processOrder = async() => {
        setProcessing(true);
    }

    const resendAcumatica = async() => {
        props.setLoading(true);
        for(let order of selectedOrder) {
            console.log(order)
            let body = {internal_po_number: order.internal_po_number, warehouse: order.warehouse, vendor_name: order.vendor_name, vendor: order.vendor_name};
            console.log(body);
            await bulkHelpers.resendToAcumatica(props.company + "_bulk", body, props.token)
        }
        props.setLoading(false);
    }

    const forceBuilt = async() => {
        if(window.confirm(`Set Status of Order ${selectedOrder[0].acu_order_num} to 'Built'?`)) {
            props.setLoading(true);
            await bulkHelpers.forceOrderBuilt(props.company + "_bulk", selectedOrder[0].acu_order_num, selectedOrder[0].internal_po_number, selectedOrder[0].vendor_name, props.token);
            props.setLoading(false);
        }
    }

    const overrideShipInfo = async() => {
        setShipOverride(true);
    }

    const setCloseShipOverride = async() => {
        setShipOverride(false);
    }

    const confirmShipment = async() => {
        if(window.confirm(`Confirm Shipment of Order ${selectedOrder[0].internal_po_number}? This will not confirm the shipment in Acumatica.`)) {
            props.setLoading(true);
            await bulkHelpers.confirmShipment(props.company + "_bulk", selectedOrder[0].internal_po_number, selectedOrder[0].vendor_name, props.token);
            props.setLoading(false);
        }
    }

    const getLabels = async() => {
        props.setLoading(true);
        let company = props.company[0].toUpperCase() + props.company.substring(1) + "_bulk";
        for(let order of selectedOrder) {
            try {
                await bulkHelpers.downloadBoxLabelFile(`${company}/${order.internal_po_number}.pdf`, company, props.token)
            }
            catch(err) {
                console.log("Error downloading Box Labels");
            }
            try {
                await bulkHelpers.downloadPalletLabelFile(`${company}/${order.internal_po_number}.pdf`, company, props.token)
            }
            catch(err) {
                console.log("Error downloading Pallet Labels");
            }
        }
        props.setLoading(false);
    }

    const regenerateBoxLabels = async() => {
        if(window.confirm(`Regenerate Box Labels for Order ${selectedOrder[0].internal_po_number}? This cannot be undone. Any previously printed labels will need to be destroyed.`)) {
            props.setLoading(true);
            await bulkHelpers.regenerateBoxLabels(selectedOrder[0].internal_po_number, selectedOrder[0].external_po_number, selectedOrder[0].vendor_name, selectedOrder[0].warehouse, props.company + "_bulk", props.token);
            await new Promise(res => setTimeout(res, 20000) )
            props.setLoading(false);
        }
    }

    const openAcumaticaOrderTab = async() => {
        for(let order of selectedOrder) {
            let type = "SO";
            let company = "Sandbox";
            if(process.env.REACT_APP_BASE_URL === "commerce-ims.com") company = "Furhaven"
            if(order.vendor_name.toLowerCase().includes("worldwise")) type = "IT";
            let url = `https://furhaven.acumatica.com/Pages/SO/SO301000.aspx?OrderType=${type}&OrderNbr=${order.acu_order_num}&Company=${company}`
            let a = document.createElement("a");
            a.href = url;
            a.target = "_blank";
            a.click();
        }
    }

    const generatePalletLabels = async() => {
        if(window.confirm(`Regenerate Pallet Labels for Order ${selectedOrder[0].internal_po_number}? This cannot be undone. Any previously printed labels will need to be destroyed.`)) {
            props.setLoading(true);
            await bulkHelpers.generatePalletLabels(selectedOrder[0].internal_po_number, selectedOrder[0].vendor_name, props.company + "_bulk", props.token);
            await new Promise(res => setTimeout(res, 20000) )
            props.setLoading(false);
        }
    }

    const routeOrder = async() => {
        console.log(props.selectedRows)
        setOpenRoute(true);
    }

    const tables = {
        "New": {
            buttons: [
                {
                    type: "primary",
                    function: processOrder,
                    text: "Process Order"
                }
            ],
        },
        "Not In Acumatica": {
            buttons: [
                {
                    type: "primary",
                    function: processOrder,
                    text: "Update Order"
                },
                {
                    type: "secondary",
                    function: resendAcumatica,
                    text: "Resend To Acumatica"
                }
            ]
        },
        "Not Routed": {
            buttons: [
                {
                    type: "secondary",
                    function: routeOrder,
                    text: "Route Order"
                }
            ],
        },
        "Not Built": {
            buttons: [
                {
                    type: "primary",
                    function: openAcumaticaOrderTab,
                    text: "View In Acumatica"
                },
                {
                    type: "secondary",
                    function: getLabels,
                    text: "Get Labels"
                },
                {
                    function: forceBuilt,
                    text:"Force Built"
                },
                {
                    type: "tertiary",
                    function: regenerateBoxLabels,
                    text: "Regenerate Labels"
                },
                {
                    type: "secondary",
                    function: generatePalletLabels,
                    text: "Generate Pallet Labels"
                }
            ],
        },
        "Not Shipped": {
            buttons: [
                {
                    type: "secondary",
                    function: overrideShipInfo,
                    text: "Add Shipment Information"
                }
            ],
        },
        
        "Ready": {
            buttons: [
                {
                    type: "tertiary",
                    function: overrideShipInfo,
                    text: "Override Shipment"
                },
                {
                    type: "secondary",
                    function: confirmShipment,
                    text: "Confirm Shipment"
                },
                {
                    type: "primary",
                    function: null,
                    text: "Export Order"
                }
            ],

        },
        "Shipped": {
            buttons: [
                {
                    type: "primary",
                    function: null,
                    text: "Export Order"
                }
            ],
        },
        "ASN Sent": {
            buttons: []
        },
        "Invoiced": {
            buttons: [
                {
                    type: "primary",
                    function: null,
                    text: "Export Order"
                },
                {
                    type: "secondary",
                    function: null,
                    text: "Mark Complete"
                }
            ],
        },
        "Complete": {
            buttons: [
                {
                    type: "primary",
                    function: null,
                    text: "Export Order"
                }
            ],
            status: "Complete",
        }
    }
    
    let headers = Object.keys(tables);

    const setCloseRouting = async() =>{
        setOpenRoute(false);
    }


    const handleChange = async(event, newValue) => {
        setSelectedOrder([]);
        setValue(newValue);
    }
    return(
        <Box display="flex" flexDirection="column" className={props.isMobile ? "mobile-top" : "desktop-top"}>
            {processing ? <ProcessingDrawer {...props} ignore_cancel={value===1} order={selectedOrder} processing={processing} setProcessing={setProcessing} /> : null}
            {shipOverride ? <ShippingPopup {...props} handleClose={setCloseShipOverride} order={selectedOrder[0]} open={shipOverride} setOpen={setShipOverride} /> : null}
            {openRoute ? <RoutingPopup {...props} handleClose={setCloseRouting} order={selectedOrder[0]} open={openRoute} setOpen={setOpenRoute} /> : null}
            <Box className={props.isMobile ? "mobile-box" : "desktop-box"}>
                <h1>Bulk Orders</h1> 
                <Tabs value={value} onChange={handleChange}>
                    {headers.map((key, index) => {
                        if(key === headers[headers.length-1]) return (
                            <Tab key={index} align="center" label={key} />
                        )
                        return(
                            <Tab key={index} align="center" label={key} sx={{borderRight: 1}} />
                        )
                    })}
                </Tabs>
                {headers.map((header,index) => {
                    let obj = tables[header];
                    return(
                        value === index ?
                        <BulkOrderTableTemplate setOpenRoute={setOpenRoute} isShipped={header === "Shipped"} selectedRows={selectedOrder} setSelectedRows={setSelectedOrder} key={index} buttons={obj.buttons} status={header} {...props} />
                        : null
                    )
                })}
            </Box>
        </Box>
    )
}

export default BulkOrders;