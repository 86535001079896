import React, { Component } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';

const columns = [ 
    {
        field: 'order_id',
        headerName: 'Order ID',
        minWidth: 100,
        editable: false,
        flex: 1
    },
    {
        field: 'vendor_name',
        headerName: 'Vendor',
        minWidth: 100,
        editable: false,
        flex: 1
    },
    {
        field: 'internal_po_number',
        headerName: 'Internal PO #',
        minWidth: 100,
        editable: false,
        flex: 1
    },
    {
        field: 'tracking_number',
        headerName: 'Tracking Number',
        minWidth: 100,
        editable: false,
        flex: 1
    },
    {
        field: 'carrier',
        headerName: 'Carrier',
        minWidth: 100,
        editable: false,
        flex: 1
    },  
    {
        field: 'method',
        headerName: 'Ship Method',
        minWidth: 100,
        editable: false,
        flex: 1
    },  
]

class OrderDetailTable extends Component {

    componentDidMount(){
        this.props.getOrders()
    }
    render(){
        return(
            <Box className = {this.props.isMobile ? "mobile-box" : "desktop:box"} sx = {{display: 'flex', flexDirection: 'column'}}>
                <Box>
                    <Button variant = 'contained' color="success" onClick = {this.props.showPullsheetTable}>Back</Button>
                </Box>
                <Box className = {this.props.isMobile ? "mobile-table-box" : "desktop-table-box"} style = {{ height: 1000, marginTop: '50px'}}>
                    <DataGrid
                        className = {this.props.isMobile ? "mobile-table" : "desktop-table"}
                        rows = {this.props.orders}
                        columns = {columns}
                        rowsPerPageOption = {[25, 50, 100]}
                        // onCellClick={(params, event) => {
                        //     console.log({params, event})
                        // }}
                        />
                </Box>
            </Box>
        )
    }
}

export default OrderDetailTable;