import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ExportPopup from '../Dashboard/ExportPopup'
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import './styles.css'

const columns = [
    {
        field: 'pullsheet_id',
        headerName: 'ID',
        flex: 1,
        minWidth: 70,
        editable: false,
    }, 
    {
        field: 'date',
        headerName: 'Date',
        flex: 1,
        minWidth: 100,
        editable: false
    },
    {
        field: 'wh_name',
        headerName: "Warehouse",
        flex: 1,
        minWidth: 70,
        editable: false
    },  
    {
        field: 'carrier',
        headerName: 'Carrier',
        flex: 1,
        valueGetter: (params) => {
            if(params.row.carrier.toLowerCase() === "fedex") return "FedEx";
            if(params.row.carrier.toLowerCase() === "usps") return "USPS";
            if(params.row.carrier.toLowerCase() === "ups") return "UPS";
            if(params.row.carrier.toLowerCase() === "amazon") return "Amazon";
            return "UNKNOWN";
        },
        minWidth: 100,
        editable: false
    },  
    {
        field: 'order_count',
        headerName: 'Orders',
        flex: 1,
        minWidth: 70,
        editable: false
    },  
    {
        field: 'piece_count',
        headerName: 'Pieces',
        flex: 1,
        minWidth: 70,
        editable: false
    },  
    {
        field: "type",
        headerName: 'Type',
        valueGetter: (params) => {
            if(!params.row.type) return ""
            return params.row.type.replace("_", " ");
        },
        flex: 1,        
        minWidth: 70,
        editable: false
    }, 
    {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        minWidth: 100,
        editable: false,
        valueGetter: (params) => {
            if(params.row.status === "order_created") return "Unbuilt";
            if(params.row.status === "order_pulled") return "Pulled";
            if(params.row.status === "order_built") return "Built";
            if(params.row.status === "order_staged") return "Staged";
            if(params.row.status === "order_shipped") return "Shipped";
        },
    }
]
function PullsheetsTable(props) {

    const [wh, setWh] = useState("");
    const [open, setOpen] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const handleClose = async() => {
        setOpen(false);
        setStartDate(null);
        setEndDate(null);
    }


    const exportOrders =  async(start, end, warehouse) => {
        console.log(start, end, warehouse)
        let rows = props.pullSheets;
        if(start) {
            rows = rows.filter(obj => moment(obj.date).isSameOrAfter(start))
        }
        if(end) {
            rows = rows.filter(obj => moment(obj.date).isSameOrBefore(end))
        }
        if(warehouse) {
            rows = rows.filter(obj => obj.wh_name.toLowerCase() === warehouse);
        }
        if(rows.length === 0) return;
        let csv_arr = [[Object.keys(rows[0]).join(",")]]

        for(let row of rows) {
            let temp = [];
            for(let key of Object.keys(row)) {
                temp.push(row[key]);
            }
            csv_arr.push(temp.join(","))
        }
        let csv = csv_arr.join("\n");
        let blob = new Blob([csv], {type: "text/csv"})
        let uri = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.setAttribute("href", uri);
        a.setAttribute("download", `FILTERED_PULLSHEETS_${new Date().toISOString().substring(0, 10)}.csv`);
        document.body.appendChild(a);
        a.click();
    }
    

        return(
            <Box display="flex" flexDirection="column" className={props.isMobile ? "mobile-box" : "desktop-box"}>
                <ExportPopup {...props} wh={wh} setWh={setWh} exportOrders={exportOrders} open={open} handleClose={handleClose} setOpen={setOpen} setStartDate={setStartDate} startDate={startDate} endDate={endDate} setEndDate={setEndDate} />
                <Box>
                    <Button variant="contained" onClick={() => props.refresh()} color="primary">Refresh</Button>
                    <Button variant='contained' onClick={props.showPullsheetConfig} sx={{ml: "10px"}} color="success">New Pullsheet</Button>
                    <Button variant="contained" onClick={() => setOpen(true)} sx={{ml: "10px"}} color="secondary">Export</Button>
                    <Box>
                    {
                        props.selected.length === 1 ?
                        <Box sx={{mt:"10px", mb:"10px"}}> 
                            <Button variant='contained' onClick={props.downloadPullsheet} className={`${props.warehouse} ${props.company} primary`}>Download Pullsheet</Button>
                            <Button variant='contained' onClick={props.showOrders} color="success" sx={{ml:"10px"}}>View Orders</Button>
                            <Button variant='contained' sx={{ml:"10px"}} onClick={props.getShippingDocs} color="success">Get Shipping Docs</Button>
                            <Button variant='contained' sx={{ml:"10px"}} onClick={props.getCarrierReport} color="success">Get Carrier Report</Button>
                            <Button variant="contained" sx={{ml:"10px"}} onClick={props.regenPackslips} color="secondary">Regenerate Packslips</Button>
                            {props.acumatica ? <Button variant="contained" sx={{ml:"10px"}} onClick={props.resendAcumatica} color="info">Resend Acumatica</Button> : null}
                            {props.pullSheets.filter(obj => obj.pullsheet_id === props.selected[0])[0].type === "Petsense" ? <Button variant="contained" sx={{ml:"10px"}} onClick={props.getMasterPackslips} color="info">Master Packslips</Button> : null}
                            {props.pullSheets.filter(obj => obj.pullsheet_id === props.selected[0])[0].piece_count > 300 ? <Button variant="contained" sx={{ml:"10px"}} style={{backgroundColor: "#ffbf00"}} onClick={props.split}>Split</Button> : null}
                        </Box>
                        : null
                        
                    }    
                </Box>
                    <Box className={props.isMobile ? "mobile-table-box" : "desktop-table-box"}>
                        <DataGrid
                        className={props.isMobile ? "mobile-table" : "desktop-table"}
                        rows={props.pullSheets.filter((obj) => {
                            if(props.warehouse === "All") return obj;
                            if(obj.wh_name && obj.wh_name.toLowerCase() === props.warehouse) return obj;
                            return null;
                        })}
                        columns={columns}
                        rowsPerPageOption = {[25, 50, 100]}
                        checkboxSelection = {true}
                        onSelectionModelChange = {(selectionModel, details) => {
                            props.updateSelected(selectionModel)
                        }}
                        selectionModel={props.selected}
                        sortModel = {[{field: 'pullsheet_id', sort: 'desc'}]}
                        getRowClassName = {(params) => `pullsheet-table-row-warehouse-${params.row.warehouse}`}
                        loading={props.loading}
                        />
                    </Box>
                </Box>
            </Box>
        )
}

export default PullsheetsTable;