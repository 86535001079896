import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button'
import bulkHelpers from '../../helpers/bulk';
import TransactionQueryForm from './TransactionQueryForm';
import TransactionData from './TransactionData';
function TransactionTable(props) {

    const [refresh, setRefresh] = useState(false);
    const [transactions, setTransactions] = useState([]) 

    useEffect(() => {
        const getTransactions = async() => {
            props.setLoading(true);
            setTransactions([])
            let res = await bulkHelpers.getTransactions(props.type === "Bulk" ? props.company + "_bulk" : props.company, props.token)
            res = res.map((obj, index) => {return {...obj, id: index}})
            setTransactions(res);
            console.log(res);
            props.setLoading(false);
        }
        getTransactions()
    }, [refresh, props.type])

        return(
                <Box className={props.isMobile ? "mobile-box" : "desktop-box"}>
                    <Button variant="contained" size="large" style={{marginTop: "20px", marginBottom: "20px"}} onClick={()=>setRefresh(!refresh)}>Refresh</Button>
                    {/* <TransactionQueryForm {...props} /> */}
                    <TransactionData {...props} transactions={transactions} />
                </Box>
        )
}

export default TransactionTable;

