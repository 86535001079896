import {React, useState, useEffect}from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import DetailsItems from './DetailsItems';
import Alert from '@mui/material/Alert';
import orderHelpers from '../../helpers/orders';
import fileHelpers from '../../helpers/files';

function DetailsPallet(props) {

    const shipment = props.shipment || {items: []};

    const [status, setStatus] = useState({});
    const [shipmentStatus, setShipmentStatus] = useState({});
    const [reshipSuccess, setReshipSuccess] = useState("");


    useEffect(() => {
        const daysInMs = 5 * 24 * 60 * 60 * 1000;
        const cutOff = new Date().getTime() - daysInMs;
        const date = new Date(shipment.date).getTime();
        setStatus(shipment.acumatica_status === "acumatica_processing" ? {name: "Processing", class: "created"} :
            shipment.acumatica_status === "acumatica_order_created" ? {name: "Order Created", class: "staged"} :
            shipment.acumatica_status === "acumatica_shipment_created" ? {name: "Shipment Created", class: "shipped"} :
            shipment.acumatica_status === "acumatica_order_failed" ? {name: "Order Failed", class: "error"} :
            shipment.acumatica_status === "acumatica_shipment_failed" ? {name: "Shipment Failed", class: "error"} : {});
        setShipmentStatus(shipment.shipment_status === "shipment_created" ? date < cutOff ? {name: "Label Created - Late", class: "error", reship: true} : {name:"Label Created", class:"created"} :
            shipment.shipment_status === "shipment_confirmed" ? {name: "Shipped", class: "shipped"} : 
            shipment.shipment_status === "shipment_reshipped" ? {name:"Reshipped", class:"staged"} : {})
    }, [shipment.acumatica_status, shipment.date, shipment.shipment_status])


    return (
            <Box display = "flex" flexDirection = "column" style = {{height: "500px", marginTop:"20px"}}>
                <Typography variant = "h4" align = "center">Pallet {props.palletNum + 1}</Typography>
                <Box display = "flex" flexDirection = "column">
                    <Box display = "flex">
                        <List dense>
                            <ListItem>
                                <ListItemText primary = "Carrier" secondary = {shipment.carrier || "N/A"} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary = "PRO Number" secondary = {shipment.pro_number || "N/A"} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary = "Truck Number" secondary = {shipment.truck_number || "N/A"}/>
                            </ListItem>
                        </List>
                        <List dense>
                            <ListItem>
                                <ListItemText primary = "Acumatica Shipment" secondary = {shipment.acu_ship_num || "N/A"}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText disableTypography 
                                    primary = {<Typography variant = "body2">Acumatica Status</Typography>}
                                    secondary = {<Typography variant = "body2" className = {status.class}>{status.name || "N/A"}</Typography>}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText disableTypography 
                                    primary = {<Typography variant = "body2">Status</Typography>}
                                    secondary = {<Typography variant = "body2" className = {shipmentStatus.class}>{shipmentStatus.name || "N/A"}</Typography>}
                                />
                            </ListItem>
                        </List>
                        <List dense>
                            <ListItem>
                                <ListItemText primary = "Warehouse" secondary = {shipment.warehouse} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary = "Total Quantity" secondary = {shipment.total_on_shipment}/>
                            </ListItem>
                        </List>
                    </Box>
                </Box>
                <Divider flexItem />
                <Box display = "flex" flexDirection = "column" style = {{overflowY:"scroll", height:"60%"}}>
                    <DetailsItems items = {shipment.items} />
                </Box>
            </Box>
    )
}

export default DetailsPallet;