import React from 'react';
import Box from '@mui/material/Box';
import OrderCount from './OrderCount';

function CarrierBucket(props) {

    let warehouses = {
        KY: {
            name: "Kentucky",
            color: "#0099FF"
        },
        PA: {
            name: "Pennsylvania",
            color: "#CEB08D"
        }
    }

    return(
        <Box style={{border: `1px solid ${warehouses[props.warehouse].color}`, marginTop:"10px", padding:"10px", paddingBottom:"10px"}} display="flex" flexDirection="column">
            <h2 style={{color: warehouses[props.warehouse].color, margin:"auto"}}>{warehouses[props.warehouse].name}</h2>
            {Object.keys(props.counts).map((carrier, index) => {
                let obj = props.counts[carrier];
                let types = {
                    assembly_orders: "Assembly",
                    multiples: "Multiples",
                    non_assembly: "Non-Assembly",
                    presses: "Presses",
                    warranties: "Warranties",
                    petsense: "Petsense",
                    sioc: "SIOC",
                    samples: "Samples"
                }
                let cards = Object.keys(obj).map((type, index) => {
                    return (<OrderCount key={index} type={types[type]} count={obj[type]} />)
                })
                return (
                    <Box display="flex" flexDirection="column" style={{margin:"auto"}} key={index}>
                        <h3>{carrier}</h3>
                        <Box display="flex">
                            {cards}
                        </Box>
                    </Box>
                )
                
            })}
        </Box>
    )

}

export default CarrierBucket;