import {React, useState, createRef} from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import bulkHelpers from '../../../helpers/bulk';
import { CSVReader } from 'react-papaparse';

const buttonRef = createRef();

const HEADERS = "internal_po_number,vendor_name,pro_number,carrier,bol,ship_date,expected_delivery,truck_number,shipment_price,acu_ship_num,total_on_shipment";

function BulkUpdatePopup(props) {

    const [carriers, setCarriers] = useState([])

    const [file, setFile] = useState(null);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);
    const [shipments, setShipments] = useState(null);
    const [uploads, setUploads] = useState([]);
    const [selected, setSelected] = useState([]);


    
    const handleOpenDialog = (e) => {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
        buttonRef.current.open(e);
        }
    };

    const handleOnFileLoad = (data, file) => {
        console.log('---------------------------');
        setFile(file);
        setSuccess(false);
        setShipments(data.map(obj => obj.data))
        console.log('---------------------------');
    };
    
    const handleOnError = (err, file, inputElem, reason) => {
        console.log('---------------------------');
        console.log(err);
        console.log('---------------------------');
        setError(`Error Uploading Shipment File: ${file}`)
    };

    const handleRemoveFile = (e) => {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
        buttonRef.current.removeFile(e);
        setFile(null);
        setShipments(null);
        }
    };

    const uploadFile = async(e) => {
        setError("");
        props.toast.promise(saveUpload(), {
            loading: "Uploading...",
            success: (res) => `${res} Shipments Updated!`,
            error: (err) => `${err.message}`
        })
    }

    const saveUpload = async() => {
        let count = 0;
        let e = null;
        try {
            props.setLoading(true);
            setError("");
            await validateUpload();
            let temp = shipments;
            for(let row of temp) {
                row.ship_date = new Date(row.ship_date).toISOString().substring(0,10);
                row.expected_delivery = new Date(row.expected_delivery).toISOString().substring(0, 10);
                count++;
            }
            await bulkHelpers.updateBulkShipment(shipments, props.company + "_bulk", props.token);
            props.handleClose(true);
        }
        catch(err) {
            console.log(err);
            setError(err.message);
            e = err;
        }
        finally {
            props.setLoading(false)
            if(e) throw e;
            return count;
        }
    }

    const validateUpload = () => {
        let keys = HEADERS.split(",");
        let optional = ["shipment_price", "acu_ship_num", "total_on_shipment"]
        for(let row of shipments) {
            for(let key of keys) {
                if(((!row[key] || row[key] === "") && !optional.includes(key)) || (key === "carrier" && row[key]?.length !== 4)) throw new Error(`Invalid Upload File! Missing Value ${key} For Order ${row.internal_po_number}`);
            
            }
        }
        return;
    }

    const downloadTemplate = async() => {
        let csv = HEADERS;
        let blob = new Blob([csv], {type: "text/csv"})
        let uri = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.setAttribute("href", uri);
        a.setAttribute("download", "BULK_SHIPMENT_TEMPLATE.csv");
        document.body.appendChild(a);
        a.click();
    }


        return(
            <Dialog open={props.open} onClose={()=>props.handleClose()}>
            <DialogTitle>
                    Bulk Update Order Shipment Info:
            </DialogTitle>
            <DialogContent>
                <Box>
                    <Button onClick={() => downloadTemplate()}>Download Template</Button>
                    <CSVReader
                        ref={buttonRef}
                        onFileLoad={handleOnFileLoad}
                        onError={handleOnError}
                        noClick
                        noDrag
                        config={{
                            header: true,
                            skipEmptyLines: true
                        }}
                        style={{}}
                        >
                        {({ file_obj }) => (
                        <aside>
                            <Button variant='contained' onClick={handleOpenDialog} className={`${props.company} ${props.warehouse} secondary`}
                            // sx={{mr: '10px', mb: '10px'}}
                            >Browse file</Button>
                            <div>
                            {file_obj && file_obj.name}
                            </div>
                            {file !== null ? 
                            <Box 
                            // sx={{flexDirection: 'row', mt: '10px'}}
                            >
                                <Button variant='contained' onClick={uploadFile} className={`${props.company} ${props.warehouse} secondary`} disabled={props.isLoading}
                                // sx={{mr: '10px'}}
                                >Upload</Button>
                                <Button variant='contained' sx={{ml:"30px"}} disabled={props.isLoading} onClick={handleRemoveFile} className={`${props.company} ${props.warehouse} tertiary`}>Remove</Button>
                            </Box>
                            : null}
                        </aside>
                        )}
                    </CSVReader>
                </Box>
            </DialogContent>
            <DialogActions>
                <Box display="flex">
                    <Button style={{marginLeft:"0", width:"20%"}} onClick={()=>props.handleClose()} disabled={props.isLoading}>Cancel</Button>
                </Box>
            </DialogActions>
        </Dialog>
        )
}

export default BulkUpdatePopup;