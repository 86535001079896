import React from "react";
import Box from '@mui/material/Box';
import PalletUpload from './PalletUpload';
import PalletLabelTable from './PalletLabelTable';
import Button from '@mui/material/Button';
import bulkHelpers from "../../helpers/bulk";

function PalletLabels(props) {

    const HEADERS = ["internal_po_number", "vendor_name", "internal_sku", "box_qty", "pallet_index"]

    const downloadPalletTemplate = async() => {
        const csv = HEADERS.join(",");
        let blob = new Blob([csv], {type: "text/csv"})
        let uri = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.setAttribute("href", uri);
        a.setAttribute("download", `PALLET_LABEL_TEMPLATE.csv`);
        a.click();
    }

    return (
        <Box className={props.isMobile ? "mobile-top" : "desktop-top"} sx = {{ display: 'flex', flexDirection: 'column'}}>
                <Box display="flex" flexDirection="column" className={props.isMobile ? "mobile-box" : "desktop-box"}>
                    <h1>Pallet Labels</h1> 
                    <Button style={{width:props.isMobile ? "200px" : "50%", marginBottom:"10px", marginTop:"10px"}} onClick={() => downloadPalletTemplate()}>Download Template</Button>
                    <PalletUpload headers={HEADERS} {...props} />
                    <PalletLabelTable {...props} />
                </Box>
            </Box>
    )
}

export default PalletLabels;