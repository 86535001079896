import React from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Box from '@mui/material/Box';

function RequestDetails(props) {
    const items = props.items || [];
    const changes = props.changes || [];
    return(
        <Table style={{width:"60%", marginLeft:"30px"}}>
            <TableHead>
                <TableRow>
                    <TableCell>Internal SKU</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Internal Price</TableCell>
                    <TableCell>External Price</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {items.map((item, index) => {
                    return(
                        <TableRow key={index}>
                            <TableCell>{item.internal_sku}</TableCell>
                            <TableCell>{!(changes[index]?.quantity >= 0) || Number(item.quantity) === Number(changes[index]?.quantity) ? item.quantity : 
                                <Box>
                                    <Box style={{textDecoration:"line-through"}}>{item.quantity}</Box>
                                    <Box style={{color:"red",fontStyle:"italic" }}>{changes[index].quantity}</Box>
                                </Box>}
                            </TableCell>
                            <TableCell>{item.internal_price ? `$${item.internal_price}` : "-"}</TableCell>
                            <TableCell>{!(changes[index]?.external_price >= 0) || parseFloat(item.external_price) === parseFloat(changes[index]?.external_price) ? `$${item.external_price}` : 
                                <Box>
                                    <Box style={{textDecoration:"line-through"}}>${item.external_price}</Box>
                                    <Box style={{color:"red",fontStyle:"italic" }}>${changes[index].external_price}</Box>
                                </Box>}
                            </TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    )
}

export default RequestDetails;