import {React, useState} from 'react';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

function ChangePopup(props) {

    



        return(
            <Dialog open={props.open} onClose={()=>props.setOpen(false)}>
            <DialogTitle>
                    Changes Requested By Vendor:
            </DialogTitle>
            <DialogContent>
                <Box display="flex" flexDirection="column" style={{height: "600px"}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Type</TableCell>
                                <TableCell>Detail</TableCell>
                                <TableCell>Value</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.changes ? props.changes.map(obj => {
                                return(
                                    <TableRow>
                                        <TableCell>{obj.type}</TableCell>
                                        <TableCell>{obj.detail}</TableCell>
                                        <TableCell>{obj.value}</TableCell>
                                    </TableRow>
                                )
                            }) : null}
                        </TableBody>
                    </Table>
                </Box>
            </DialogContent>
        </Dialog>
        )
}

export default ChangePopup;