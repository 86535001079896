import React, {useRef, useState, useEffect} from 'react';
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import DetailsPackage from './DetailsPackage';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText'
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography'
import orderHelpers from '../../helpers/orders';
import TransferDialog from './TransferDialog';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import EditOrderInfoPopup from './EditOrderInfoPopup'


function OrderDetails(props) { 

    const order = props.order || {};
    const containerRef = useRef({});

    const [status, setStatus] = useState({});
    const [transferOpen, setTransferOpen] = useState(false);
    const [openEditOrderInfoPopup, setOpenEditOrderInfoPopup] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        setStatus(order.order_status === "order_created" ? {name: "Order Created", class: "created"} :
        order.order_status === "order_pulled" ? {name: "Items Pulled", class: "pulled"} :
        order.order_status === "order_built" ? {name: "Items Built", class: "built"} :
        order.order_status === "order_staged" ? {name: "Order Staged", class: "staged"} :
        order.order_status === "order_shipped" ? {name: "Order Shipped", class: "shipped"} : 
        order.order_status === "order_cancelled" ? {name: "Order Cancelled", class: "cancelled"} :
        order.order_status === "order_production_hold" ? {name: "Order Production Hold", class: "onHold"} : {})
    }, [order.order_status])

    const resendAcumatica = async() => {
        if(!window.confirm(`Resend order ${order.internal_po_number} to Acumatica? This will delete existing shipments and cancel the existing order.`)) return;
        props.setLoading(true);
        await orderHelpers.resendAcumatica(order.internal_po_number, props.company, props.token);
        props.setLoading(false);
    }

    const cancelOrder = async() => {
        try{
            if(!window.confirm(`Cancel order ${order.internal_po_number}?`)) {
                throw Error("Operation Aborted");
            }
            props.setLoading(true);
            let res = await orderHelpers.cancelOrder(order.internal_po_number, order.vendor_name, props.company, props.token);
            if(res.success) setStatus({name: "Order Cancelled", class: "cancelled"});
            else throw new Error("Can't Cancel Order.\n Check if order is on a pullsheet")
        }
        catch(e){
            throw Error(e);
        }
        finally{
            props.setLoading(false);
        }
    }

    const saveHoldOrder = async() => {
        if(!window.confirm(`Hold order ${order.internal_po_number}?`)) return;
        props.setLoading(true);
        let res = await orderHelpers.holdOrder(order.internal_po_number, order.vendor_name, props.company, props.token);
        if(res.success){
            setStatus({name:"Order On Hold", class:"hold"});
        }
        props.setLoading(false);
    }

    const holdOrder = async(e) => {
        setError("");
        props.toast.promise(saveHoldOrder(), {
            loading: "Uploading...",
            success: (res) => `Order On Hold!`,
            error: (err) => `${err.message}`
        })
    }

    const cancelOrderToast = async(e) => {
        setError("");
        try{
            await props.toast.promise(cancelOrder(), {
            loading: "Uploading...",
            success: () => `Order Canceled!`,
            error: (err) => `${err.message}`
            });
        }    
        catch (error) {
            console.error("Unexpected error:", error);
        }
    }

    const transfer = async(items) => {
        props.setLoading(true);
        let out = [];
        for(let item in items) {
            if(items[item]) {
                out.push(item);
            }
        }
        await orderHelpers.transferItems(out, order.internal_po_number, props.company, props.token);
        props.setLoading(false);
        setTransferOpen(false);
        console.log("Order transferred!");
    }


    return (
        <Drawer open = {Object.keys(order).length > 0} onClose = {props.setOpenDetails} anchor = "bottom">
            <TransferDialog {...props} items = {order.items} open = {transferOpen} setOpen = {setTransferOpen} transfer = {transfer} />
            {openEditOrderInfoPopup ? <EditOrderInfoPopup
                {...props}
                open = {openEditOrderInfoPopup} 
                handleClose = {() => setOpenEditOrderInfoPopup(false)} 
                po = {order.internal_po_number} 
                /> : null}
            <Box style = {{height: "600px"}} display = "flex" sx = {{m: "auto", mb: "80px", pt: "20px"}}>
                <List dense>
                    <ListItem>
                        <ListItemText 
                            primary = "Order Number" 
                            primaryTypographyProps = {{variant: "h4"}} 
                            secondaryTypographyProps = {{variant: "h5"}} 
                            secondary = {order.internal_po_number || "N/A"} />
                    </ListItem>
                    <ListItem>
                        <ListItemText 
                            primary = "Order Date" 
                            secondary = {order.order_date ? order.order_date.substring(0, 10) : "N/A"}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText 
                            primary = "Vendor" 
                            secondary = {order.vendor_name || "N/A"} />
                    </ListItem>
                    <ListItem>
                        <ListItemText disableTypography 
                            primary = {<Typography variant = "body2">Order Status</Typography>}
                            secondary = {<Typography variant = "body2" className = {status.class}>{status.name || "N/A"}</Typography>}/>
                    </ListItem>
                    <Stack direction = "row" spacing = {2}>
                        <ListItem>
                            <ListItemText primary = "Acumatica Order" secondary = {order.acu_order_num || "N/A"}/>
                        </ListItem>
                        <Tooltip title = {order.acu_order_num ? "Cannot edit." : "Edit."}  placement = 'right'>
                            <span>
                            <IconButton 
                                size = 'small' 
                                sx = {{mt: 2}}
                                disabled = {order.acu_order_num ? true : false}
                                onClick = {()=>setOpenEditOrderInfoPopup(true)} 
                            >
                                <EditIcon/>
                            </IconButton>
                            </span>
                        </Tooltip>
                    </Stack>
                    <List>
                        <ListItem>
                            {status.name !== "Order Shipped" && status.name !== "Order Cancelled" ? 
                                <Button 
                                    sx = {{mr: "10px"}} 
                                    disabled = {props.isLoading} 
                                    variant = "contained" 
                                    onClick = {()=>cancelOrderToast()} 
                                    className = {`${props.warehouse} ${props.company} error`}>
                                    Cancel Order
                                </Button> 
                            : null}
                            {status.name !== "Order Production Hold" && status.name !== "Order Cancelled" ? 
                                <Button 
                                    sx = {{mr: "10px"}} 
                                    disabled = {props.isLoading} 
                                    variant = "contained" 
                                    onClick = {()=>holdOrder()} 
                                    className = {`${props.warehouse} ${props.company} warning`}>
                                    Hold Order
                                </Button> 
                            : null}
                        </ListItem>
                        <ListItem>
                            {status.name !== "Order Cancelled" ? 
                                <Button 
                                    sx = {{mr: "10px"}} 
                                    variant = "contained" 
                                    disabled = {props.isLoading} 
                                    className = {`${props.warehouse} ${props.company} tertiary`} 
                                    onClick = {()=>resendAcumatica()}>
                                    Resend to Acumatica
                                </Button> 
                            : null}
                            {status.name !== "Order Cancelled" ? 
                                <Button 
                                    variant = "contained" 
                                    disabled = {props.isLoading} 
                                    className = {`${props.warehouse} ${props.company} secondary`} 
                                    onClick = {()=>setTransferOpen(true)}>
                                    Transfer
                                </Button> 
                            : null}
                        </ListItem>
                    </List>
                </List>
                <Divider sx = {{ml: "10px", mr: "10px"}} orientation = "vertical" flexItem />
                <Box display = "flex" flexDirection = "column">
                    <h3>Customer Info</h3>
                    <Box display = "flex">
                        <List dense>
                            <ListItem>
                                <ListItemText  
                                    primary = "Customer Name" 
                                    secondary = {order.name || "N/A"}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText 
                                    primary = "Street Address" 
                                    secondary = {order.address1 || "N/A"}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText 
                                    primary = "Street Address 2" 
                                    secondary = {order.address2 || "N/A"}/>
                            </ListItem>
                        </List>
                        <List dense>
                            <ListItem>
                                <ListItemText 
                                    primary = "City" 
                                    secondary = {order.city || "N/A"} />
                            </ListItem>
                            <ListItem>
                                <ListItemText 
                                    primary = "State" 
                                    secondary = {order.state || "N/A"} />
                            </ListItem>
                            <ListItem>
                                <ListItemText 
                                    primary = "Zip Code" 
                                    secondary = {order.zip_code || "N/A"} />
                            </ListItem>
                        </List>
                    </Box>
                </Box>
                <Divider sx = {{ml: "10px", mr: "10px"}} orientation = "vertical" flexItem />
                {order.shipments ? 
                    <Box display = "flex" flexDirection = "column" >
                        <Tabs value = {props.tab} onChange = {props.setTab}>
                            {order.shipments.map((shipment, index) => {
                                return(
                                    <Tab 
                                        value = {index} 
                                        name = {index} 
                                        key = {index} 
                                        label = {`Package ${index + 1}`} />
                                )
                            })}
                        </Tabs>
                        {order.shipments.map((shipment, index) => {
                            return(
                                <Box ref = {containerRef[index]} key = {index} display = "flex" flexDirection = "column" style = {{overflowX:"hidden"}}>
                                    <Slide mountOnEnter unmountOnExit container = {containerRef[index]?.current} direction = {props.direction} in = {props.tab === index}>
                                        <Box>
                                            {props.tab === index ? 
                                                <DetailsPackage 
                                                    {...props} 
                                                    status = {status} 
                                                    internal_po_number = {order.internal_po_number} 
                                                    packageNum = {props.tab} 
                                                    shipment = {shipment} 
                                                />  
                                            : null}
                                        </Box>
                                    </Slide>
                                </Box>
                            )
                        })}
                        {/* // {order.shipments.length > props.tab ? <DetailsPackage packageNum={props.tab} shipment={order.shipments[props.tab]} /> : null} */}
                    </Box> 
                : null
                }
            </Box>
        </Drawer>
    )

}

export default OrderDetails;


