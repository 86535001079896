import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

function DailyBuildsQueryForm(props) {


    return(
        <Box className={props.isMobile ? "mobile-box" : "desktop-box"} display="flex" flexDirection="column">
            <Box className={props.isMobile ? "mobile-box" : "desktop-box"} display="flex" flexDirection="column">
                <Box className={props.isMobile ? "mobile-box" : "desktop-box"} display="flex" sx={{mt:"20px"}}>
                    <FormControl sx={{minWidth:"300px", ml:"20px", mr:"20px"}}>
                        <InputLabel id="warehouse_label">Warehouse</InputLabel>
                        <Select
                            value={props.warehouse}
                            name='warehouse'   
                            label="Warehouse"        
                            onChange={(e) => props.updateWarehouse(e.target.value)}
                            defaultValue={""}
                            disabled={props.isLoading} 
                        >
                            <MenuItem value="KY">Kentucky</MenuItem>
                            <MenuItem value="PA">Pennsylvania</MenuItem>
                            <MenuItem value="TX">Texas</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{minWidth:"300px"}}>

                        <InputLabel id="line">Line</InputLabel>
                        <Select
                            value={props.group}
                            name='line'   
                            label="Line"
                            onChange={(e) => props.setLine(e.target.value)}
                            defaultValue={""}
                            disabled={props.isLoading} 
                            >                    
                            {props.groups.map((value, index) => (
                            <MenuItem key={index} value={value}>
                                {value}
                            </MenuItem>

                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Box>
            <Box className={props.isMobile ? "mobile-box" : "desktop-box"} display="flex" sx={{mt:"20px", ml:"375px"}}>
                <Button onClick={()=>props.clear()} variant="contained" color="info" size="large" disabled={props.isLoading} >Clear</Button>
                <Button onClick={()=>props.loadGroupData(props.line)} variant="contained" color="success" size="large" sx={{ml:"40px"}} disabled={props.isLoading} >Search</Button>
            </Box>
            
        </Box>
    )
}

export default DailyBuildsQueryForm;