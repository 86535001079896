import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import {React} from 'react';
import Box from '@mui/material/Box';
import Tenant from './Tenant'
import User from './User';
import Divider from '@mui/material/Divider';
import LinearProgress from '@mui/material/LinearProgress';

function Bar(props) {

    return (
        <AppBar elevation={0} sx={{width: "100%", height:"50px", zIndex: props.zIndex}} className={`${props.warehouse} ${props.company} primary ${props.is_sandbox ? "sandbox" : ""}`} position="fixed">
                <Toolbar variant="dense">
                    <Box display="flex" style={{width:"100%", height:"100%"}}>
                        {props.warehouses[props.company] ?
                            <Box display="flex" style={{width:"100%", margin:"auto"}}>
                                {props.isMobile ? <IconButton
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ mr: 2 }}
                                    onClick={() => props.setOpenMenu(!props.openMenu)}
                                >
                                    <MenuIcon />
                                </IconButton> : null}
                                {props.isMobile ? null : <Divider style={{height: "50px"}} sx={{ml:"159px"}} orientation="vertical" flexItem /> } 
                                <Tenant {...props} />
                            </Box>
                            :
                            <Box display="flex" style={{width: "100%", marginLeft:"auto"}}>
                                {props.isMobile ? <IconButton
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ mr: 2 }}
                                    onClick={() => props.setOpenMenu(!props.openMenu)}
                                >
                                    <MenuIcon />
                                </IconButton> : null}
                                <User {...props} />
                            </Box>
                        }
                    </Box>
                </Toolbar>
                {props.isLoading ? <LinearProgress style={{height:"10px",padding:"2px"}} color="success" /> : null}
            </AppBar>
    )
}

export default Bar;