import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import {Calendar, momentLocalizer} from 'react-big-calendar'
import moment from 'moment'
import bulkHelpers from '../../helpers/bulk'

import 'react-big-calendar/lib/css/react-big-calendar.css';

function ProductionCalendar(props) {

    const localizer = momentLocalizer(moment)

    const [pieces, setPieces] = useState([]);
    const [kyPieces, setKYPieces] = useState([]);
    const [paPieces, setPAPieces] = useState([]);
    useEffect(() => {
        const getPieces = async() => {
            let res_raw = await bulkHelpers.getProductionCalendar(props.company, props.token);
            let res = JSON.parse(res_raw)
            let ky_out = [];
            let pa_out = [];
            const oneDay = 24 * 60 * 60 * 1000
            props.setLoading(false)
            let index = 0;
            let daily = 4300;
            for(let row of res?.Kentucky || []) {
                let wh_color = "#0099FF"
                let bg_color = "#FFFFFF";
                let color = wh_color;
                let diff = Math.round((new Date(row.date) - new Date()) / oneDay);
                let timing = "normal"
                if(diff <= 0) {
                    bg_color = "#D22B2B";
                    timing = "late";
                    color = "white"
                }
                else if(diff < 3 && row.qty / daily > 3) {
                    bg_color = "#EC5800";
                    timing = "soon";
                    color = "black"
                }
                else if(diff < 7 && row.qty / daily > 7) {
                    bg_color = "#FFDB58";
                    timing = "upcoming";
                    color = "black"
                }
                
                ky_out.push({
                    id: index,
                    title: `${row.qty} | ${row.vendor_name}`,
                    allDay: true,
                    start: moment(row.date).toDate(),
                    end: moment(row.date).toDate(),
                    borderColor: wh_color,
                    backgroundColor: bg_color,
                    wh: "KY",
                    timing: timing,
                    color: color
                })
                index++;
            }
            for(let row of res?.Pennsylvania || []) {
                let wh_color = "#CEB08D"
                let bg_color = "#FFFFFF";
                let diff = Math.round((new Date(row.date) - new Date()) / oneDay);
                let timing = "normal"
                let color = wh_color;
                if(diff <= 0) {
                    bg_color = "#D22B2B";
                    timing = "late";
                    color = "white"
                }
                else if(diff < 3 && row.qty / daily > 3) {
                    bg_color = "#EC5800";
                    timing = "soon";
                    color = "black"
                }
                else if(diff < 7 && row.qty / daily > 7) {
                    bg_color = "#FFDB58";
                    timing = "upcoming";
                    color = "black"
                }
                
                pa_out.push({
                    id: index,
                    title: `${row.qty}|${row.vendor_name}`,
                    start: moment(row.date).toDate(),
                    end: moment(row.date).toDate(),
                    allDay: true,
                    borderColor: wh_color,
                    backgroundColor: bg_color,
                    wh: "PA",
                    timing: timing,
                    color: color
                })
                index++
            }
            setKYPieces(ky_out);
            setPAPieces(pa_out);
            let out = ky_out.concat(pa_out)
            setPieces(out)
            console.log(out)
        }
        props.setLoading(true);
        getPieces();
    }, [])

    function getClass(e) {
        let out_class = `${e.wh}-${e.timing}`
        return {style: {backgroundColor: e.backgroundColor, border: `2px solid ${e.borderColor}`, color: e.color || e.backgroundColor}}
    }

        return(
            <Box display="flex" flexDirection="column" className={props.isMobile ? "mobile-top" : "desktop-top"}>
                <Box className={props.isMobile ? "mobile-box" : "desktop-box"}>
                    <h1>Production Calendar</h1> 
                    <Box>
                        <Calendar eventPropGetter={getClass} defaultView={"month"} localizer={localizer} events={pieces} style={{height:"1000px", width:"80%"}} />
                    </Box>
                </Box>
            </Box>
        )
}

export default ProductionCalendar;