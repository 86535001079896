import React, { Component } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import {lighten } from '@mui/material/styles';
import orderHelpers from '../../helpers/orders';
import Tooltip from '@mui/material/Tooltip';


class UploadsTable extends Component {

    constructor(props) {
        super(props);
        this.userToken = props.token
        this.dropship_columns = [
            {
                field: 'upload_id',
                headerName: 'ID',
                minWidth: 100,
                flex:1, 
                editable: false
            }, 
            {
                field: 'vendor_name',
                headerName: 'Vendor',
                minWidth: 200,
                flex:1, 
                editable: false
            }, 
            {
                field: 'user',
                headerName: 'User',
                minWidth: 150,
                flex:1, 
                editable: false
            }, 
            {
                field: 'file_name',
                headerName: 'File Name',
                renderCell: (params) => {
                    return(
                        <Tooltip title={params.row.file_name}>
                            <Box style={{whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis"}}>{params.row.file_name}</Box>
                        </Tooltip>
                    )
                },
                minWidth: 200,
                flex:1, 
                editable: false
            }, 
            {
                field: 'upload_date',
                headerName: 'Date',
                minWidht: 200,
                renderCell: (params) => {
                    let date = params.row.upload_date.replace("T", " ").replace("Z", " ").replace(".000", "");
                    return(
                        <Tooltip title={date}>
                            <Box style={{whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis"}}>{date}</Box>
                        </Tooltip>
                    )
                },
                flex: 1,
                editable: false
            },
            {
                field: 'order_count',
                headerName: 'Orders',
                minWidth: 100,
                flex:1, 
                editable: false
            },
            {
                field: 'Kentucky',
                headerName: 'KY',
                minWidth: 100,
                flex: 1,
                editable: false
            },
            {
                field: 'Pennsylvania',
                headerName: 'PA',
                minWidth: 100,
                flex: 1,
                editable: false
            },
            {
                field: 'successful',
                headerName: 'Success',
                minWidth: 100,
                flex:1, 
                editable: false
            },
            {
                field: 'failed',
                headerName: 'Fail',
                minWidth: 100,
                flex:1, 
                editable: false
            },
            {
                field: 'upload_status',
                headerName: 'Status',
                flex:1, 
                minWidth: 100,
                valueGetter: (params) => {
                    if(params.row.upload_status === "upload_deleted") return "Deleted";
                    if(params.row.upload_status === "upload_partially_deleted") return "Partially Deleted";
                    if(params.row.upload_status === "upload_complete") return "Complete";
                    if(params.row.upload_status === "upload_failed") return "Failed";
                    if(params.row.upload_status === "upload_format_not_found") return "Invalid Format";
                    if(params.row.upload_status === "upload_processing") return "Processing";
                },
                editable: false
            }
        ]
        this.bulk_columns = [
            {
                field: 'upload_id',
                headerName: 'ID',
                minWidth: 100,
                flex:1, 
                editable: false
            }, 
            {
                field: 'vendor_name',
                headerName: 'Vendor',
                minWidth: 200,
                flex:1, 
                editable: false
            }, 
            {
                field: 'upload_date',
                headerName: 'Date',
                minWidth: 200,
                renderCell: (params) => {
                    let date = params.row.upload_date.replace("T", " ").replace("Z", " ").replace(".000", "");
                    return(
                        <Tooltip title={date}>
                            <Box style={{whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis"}}>{date}</Box>
                        </Tooltip>
                    )
                },
                flex: 1,
                editable: false
            },
            {
                field: 'order_count',
                headerName: 'Orders',
                minWidth: 100,
                flex:1, 
                editable: false
            },
            {
                field: 'successful',
                headerName: 'Success',
                minWidth: 100,
                flex:1, 
                editable: false
            },
            {
                field: 'failed',
                headerName: 'Fail',
                minWidth: 100,
                flex:1, 
                editable: false
            },
            {
                field: 'upload_status',
                headerName: 'Status',
                flex:1, 
                minWidth: 100,
                valueGetter: (params) => {
                    if(params.row.upload_status === "upload_deleted") return "Deleted";
                    if(params.row.upload_status === "upload_partially_deleted") return "Partially Deleted";
                    if(params.row.upload_status === "upload_complete") return "Complete";
                    if(params.row.upload_status === "upload_failed") return "Failed";
                    if(params.row.upload_status === "upload_format_not_found") return "Invalid Format";
                    if(params.row.upload_status === "upload_processing") return "Processing";
                },
                editable: false
            }
        ]
    }

    deleteUpload = async(upload_id) => {
        console.log("DELETEING", upload_id);
    }

    
    componentDidMount(){
        this.props.getUploads();
    }

    

    getClass(row) {
        if(row.upload_status === "upload_format_not_found") return "invalid_file"
        if(row.upload_status === "upload_complete" && row.failed > 0 && row.successful > 0) return "partial_success";
        if(row.upload_status === "upload_complete" && row.failed > 0 && row.successful === 0) return "failed";
        if(row.upload_status === "upload_complete" && row.failed === 0 && row.successful > 0) return "success";
        if(row.upload_status === "upload_deleted") return "deleted";
        if(row.upload_status === "upload_failed") return "failed";
        return "processing";
    }

    render(){
        return(
            <Box 
            // sx={{display: 'flex', flexDirection: 'column', mt:'25px'}}
            >

            <Box> 
                <Box 
                // sx={{display: 'flex', flexDirection: 'row'}}
                >
                    <h3>File Status</h3>
                    <Button variant='contained' onClick={this.props.getUploads} className={`${this.props.company} ${this.props.warehouse} secondary`}
                    // sx={{ml: this.props.isMobile ? '20px' : '800px', mt: '20px', maxHeight: '45px'}}
                    >
                        Refresh
                    </Button>
                </Box>
                <Box 
                    sx={{ 
                        // height: 1000, 
                        width: '100vw',
                        // overflowX: 'scroll',
                        // mt: '30px',
                        '& .invalid_file': {
                            bgcolor: "#D3D3D3"
                        },
                        '& .deleted': {
                            bgcolor: "#d7d7d79c",
                            color: "#a3a2a2"
                        },
                        '& .partial_success': {
                            bgcolor: (theme) => lighten(theme.palette.warning.light, 0.5)
                        },
                        '& .failed': {
                            bgcolor: (theme) => lighten(theme.palette.error.light, 0.5)
                        },
                        '& .success': {
                            bgcolor: (theme) => lighten(theme.palette.success.light, 0.5)
                        }
                        }}
                    className={this.props.isMobile ? "mobile-table-box" : "desktop-table-box"}
                >
                    <DataGrid
                        className={this.props.isMobile ? "mobile-table" : "desktop-table"}
                        rows={this.props.uploads ? this.props.uploads : []}
                        columns={this.props.is_bulk ? this.bulk_columns : this.dropship_columns}
                        rowsPerPageOption = {[25, 50, 100]}
                        checkboxSelection = {false}
                        onSelectionModelChange={(newSelectionModel) => {
                            this.props.handleSelect(newSelectionModel);
                        }}
                        selectionModel={this.props.selected}
                        getRowClassName={(params) => this.getClass(params.row)}
                        sortModel = {[{field: 'upload_id', sort: 'desc'}]}
                    />
                </Box>
            </Box>
        </Box>
        )
    }
}

export default UploadsTable;