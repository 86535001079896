import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import bulkHelpers from "../../helpers/bulk";
import { CSVReader } from 'react-papaparse';

const buttonRef = React.createRef();

function PalletUpload(props) {
    const [file, setFile] = useState(null)
    const [ordernbr, setOrderNbr] = useState(null);
    const [pallets, setPallets] = useState(null);

        
    const handleOpenDialog = (e) => {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
            buttonRef.current.open(e);
        }
    };

    const handleOnFileLoad = (data, file) => {
        console.log('---------------------------');
        let p = data.map(obj=>obj.data);
        if(p.length === 0) {
            props.toast.error("File Empty");
            setPallets(null);
            setFile(null);
            return;
        }
        let headers = [...new Set(p.map(obj => Object.keys(obj)))];
        let top_headers = JSON.stringify(props.headers);
        if(headers.length !== 1 || !headers.every(obj => JSON.stringify(obj) === top_headers)) {
            props.toast.error("Invalid File Format");
            setPallets(null);
            setFile(null);
            return;
        }
        setFile(file);
        setPallets(p);
        console.log('---------------------------');
    };
    
    const handleOnError = (err, file, inputElem, reason) => {
        console.log('---------------------------');
        console.log(err);
        console.log('---------------------------');
        props.toast.error(`Error Handling Pallet File: ${err}`)
    };

    const handleRemoveFile = (e) => {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
            buttonRef.current.removeFile(e);
            setFile(null);
            setPallets(null);
        }
    };


    const uploadFile = async() => {
        try {
            props.setLoading(true);
            props.toast.promise(bulkHelpers.uploadPalletFile(pallets, props.company + "_bulk", props.token), {
                loading: "Uploading...",
                success: (res) => {
                    console.log(res);
                    return `Successfully Sent Pallet Request For ${res?.success?.length} Orders. ${res?.fail?.length} Requests Failed.`
                },
                error: (err) => `${err.message}`
            })
        }
        catch(err) {
            console.log(err);
        }
        finally { 
            props.setLoading(false);
        }
    }

    return (
        <Box display="flex" flexDirection="column" className={props.isMobile ? "mobile-box" : "desktop-box"}>
            <CSVReader
                    ref={buttonRef}
                    onFileLoad={handleOnFileLoad}
                    onError={handleOnError}
                    noClick
                    noDrag
                    config={{
                        header: true,
                        skipEmptyLines: true
                    }}
                    style={{}}
                    >
                    {({ file_obj }) => (
                    <aside>
                        <Button variant='contained' onClick={handleOpenDialog} className={`${props.company} ${props.warehouse} secondary`}
                        // sx={{mr: '10px', mb: '10px'}}
                        >Browse file</Button>
                        <div>
                        {file_obj && file_obj.name}
                        </div>
                        {file !== null ? 
                        <Box 
                        // sx={{flexDirection: 'row', mt: '10px'}}
                        >
                            <Button variant='contained' onClick={uploadFile} className={`${props.company} ${props.warehouse} secondary`} disabled={props.isLoading}
                            // sx={{mr: '10px'}}
                            >Upload</Button>
                            <Button variant='contained' sx={{ml:"30px"}} disabled={props.isLoading} onClick={handleRemoveFile} className={`${props.company} ${props.warehouse} tertiary`}>Remove</Button>
                        </Box>
                        : null}
                    </aside>
                    )}
                </CSVReader>
        </Box>
    )
}

export default PalletUpload;