import React, {useState, useEffect} from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { CSVReader } from 'react-papaparse';
import orderHelpers from '../../helpers/orders';

function CancelItemPopup(props) {

        const HEADERS = ["internal_po_number", "internal_sku", "vendor_name"]

        const buttonRef = React.createRef();
        const [error, setError] = useState("");
        const [file, setFile] = useState(null);
        const [items, setItems] = useState([]);

        const handleOpenDialog = (e) => {
            // Note that the ref is set async, so it might be null at some point
            if (buttonRef.current) {
                buttonRef.current.open(e);
            }
        };
    
    
        const handleOnError = (err, file, inputElem, reason) => {
            console.log('---------------------------');
            console.log(err);
            console.log('---------------------------');
            setError(`Error: ${file}`)
        };
        
        const handleRemoveFile = (e) => {
            // Note that the ref is set async, so it might be null at some point
            if (buttonRef.current) {
                buttonRef.current.removeFile(e);
                setFile(null);
                setItems(null);
            }
        };
    
        const formatItems = (data) => {
            const itemArray = [];
            data.forEach(item =>{
                itemArray.push(item.data)
            })
            return itemArray;
        }
    
    
    
        const handleOnFileLoad = (data, file) => {
            console.log('---------------------------');
            setFile(file);
            const new_items = formatItems(data);
            setItems(new_items);
            console.log('---------------------------');
        };
        
    
        const downloadTemplate = async() => {
            let csv = HEADERS;
            let blob = new Blob([csv], {type: "text/csv"})
            let uri = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.setAttribute("href", uri);
            a.setAttribute("download", "BULK_CANCEL_TEMPLATE.csv");
            document.body.appendChild(a);
            a.click();
        }
    
        const uploadFile = async() => {
            props.setLoading(true);
            props.toast.promise(orderHelpers.cancelItems(JSON.stringify({data:items}), props.company, props.token), {
                success: (res) => {
                    props.setLoading(false);
                    console.log(res);
                    return `Succesful Uploads: ${res.data.success.length}
                    
                    Failed Uploads: ${res.data.fail.length > 0 ? 0 : res.data.fail.map(obj => `${obj.internal_po_number}, ${obj.internal_sku}\n`)}`
                },
                error: (err) => {
                    props.setLoading(false);
                    console.log(err);
                    return "Failed to Cancel Items.";
                }
            })
        }



        return(
            <Dialog open={props.open} onClose={()=>props.setOpen(false)}>
            <DialogTitle>
                    Upload Items to Cancel:
            </DialogTitle>
            <DialogContent>
                <Box>
                    <Button onClick={()=>downloadTemplate()}>Download Template</Button>
                </Box>
                <CSVReader
                        ref={buttonRef}
                        onFileLoad={handleOnFileLoad}
                        onError={handleOnError}
                        noClick
                        noDrag
                        config={{
                          header: true,
                          skipEmptyLines: true
                        }}
                        style={{}}
                        >
                        {({ file_obj }) => (
                          <aside>
                            <Button variant='contained' onClick={handleOpenDialog} className={`${props.company} ${props.warehouse} secondary`}
                            // sx={{mr: '10px', mb: '10px'}}
                            >Browse file</Button>
                            <div>
                              {file_obj && file_obj.name}
                            </div>
                            {file !== null ? 
                              <Box 
                              // sx={{flexDirection: 'row', mt: '10px'}}
                              >
                                <Button variant='contained' onClick={uploadFile} className={`${props.company} ${props.warehouse} secondary`}
                                // sx={{mr: '10px'}}
                                >Upload</Button>
                                <Button variant='contained' sx={{ml:"30px"}} onClick={handleRemoveFile} className={`${props.company} ${props.warehouse} tertiary`}>Remove</Button>
                              </Box>
                            : null}
                          </aside>
                        )}
                      </CSVReader>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>props.setOpen(false)}>Cancel</Button>

            </DialogActions>
        </Dialog>
        )
}

export default CancelItemPopup;