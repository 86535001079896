import Box from '@mui/material/Box';

import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import orderHelpers from '../../helpers/orders';
import acumaticaHelpers from '../../helpers/acumatica';

function EditOrderInfoPopup(props) {

    const selectedOrder = props.order;
    const company = props.company;
    const token = props.token;
    
    const [acuOrderNum, setAcuOrderNum] = useState('');

    const handleChange = (event) => {
        let func = `set${event.target.name[0].toUpperCase() + event.target.name.substring(1)}`
        eval(func)(event.target.value)
    }

    const formatAcuOrderNum = (acuOrderNum) => {
        while (acuOrderNum.length < 7) {
            acuOrderNum = '0' + acuOrderNum;
        }
        return acuOrderNum;
    }

    const validateAcumaticaOrderNumber = async(acu_order_num, internal_po_number) => {
        try {
            const order = await acumaticaHelpers.getAcumaticaOrderDetail(acu_order_num, company, token);
            if (order.length) {
                const internalPONbr = order[0].CustomerOrderNbr.value;
                if (internalPONbr === internal_po_number) {
                    return true;
                } else {
                    props.toast.error('Acumatica Order Number ' + acu_order_num + ' does not match PO ' + internal_po_number);
                    return false;
                }
            } else {
                props.toast.error('Could not find ' + acu_order_num + ' in Acumatica!');
                return false;
            }
        } catch(err) {
            console.log(err);
        }
    }

    const updateAcumaticaOrderNumber = async(acu_order_num, internal_po_number) => {
        try {
            if(!window.confirm(`Update Acumatica Order# ${formatAcuOrderNum(acu_order_num)} for PO# ${internal_po_number}?`)) {
                return props.toast.error('Cancelled Acumatica Order # update!');
            }
            props.setLoading(true);
            if(!acu_order_num) {
                return props.toast.error('Enter the Acumatica Order # that corresponds to PO# ' + internal_po_number);
            } else {
                acu_order_num = formatAcuOrderNum(acu_order_num);
                const valid = await validateAcumaticaOrderNumber(acu_order_num, internal_po_number);
                if (valid) {
                    const res = await orderHelpers.updateAcuOrderNumByPO(internal_po_number, acu_order_num, company, token);
                    if (res.success) {
                        props.toast.success(`Successfully updated Acumatica Order# ${acu_order_num} for PO# ${internal_po_number}!`);
                        props.handleClose();
                        props.order.acu_order_num = acu_order_num;  
                    } else {
                        props.toast.error(`Failed to update Acumatica Order# ${acu_order_num} for PO# ${internal_po_number}!`);
                    }
                } else {
                    return;
                }
            }
        } catch (err) {
            console.log(err)
        } finally {
            props.setLoading(false);
        }
    }

    return(
        <Dialog open = {props.open} onClose = {()=>props.handleClose()} PaperProps = {{sx: { width: '100%', maxWidth: '400px', margin: 'auto'}}}>
            <Box sx = {{ display: 'flex', justifyContent: 'flex-end', pt: '10px', pr: '3px'}}>
                <Tooltip title = 'Cancel' placement = 'right'>
                    <span>
                        <IconButton 
                            size = 'small' 
                            style = {{marginRight:"2%"}}
                            onClick = {()=>props.handleClose()}
                            color = "error"
                        >
                        <CloseIcon/>
                </IconButton>
                    </span>
                </Tooltip>
            </Box>
            <Box sx = {{display: 'flex', justifyContent: 'center', alignItems: 'center', pl: '10px', pr: '10px', mt: '-15px', mb: '-25px'}}>
                <DialogTitle sx = {{margin: 0}} >
                        Enter Acumatica Order Number
                </DialogTitle>
            </Box>
            <DialogContent>
                <Box className = {props.isMobile ? 'mobile-box' : 'desktop-box'} sx = {{display: 'flex', flexDirection: props.isMobile ? 'column' : 'row', justifyContent: 'center', pb: '20px'}}>
                    <TextField 
                        name = 'acuOrderNum'
                        label = 'Acumatica Order Number'
                        onChange = {handleChange}
                        value = {acuOrderNum}
                        sx = {{width: '65%'}}
                        required
                    />
                    <Tooltip title = 'Save' placement = 'right'>
                        <span>
                            <IconButton 
                                size = 'small' 
                                style = {{marginTop: "10px", marginLeft: "15px"}}
                                onClick = {()=>updateAcumaticaOrderNumber(acuOrderNum, selectedOrder.internal_po_number)}
                                color = "success"
                            >
                                <SaveIcon/>
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default EditOrderInfoPopup;