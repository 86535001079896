import {React, cloneElement} from 'react';
import {useParams} from 'react-router-dom';
import Box from '@mui/material/Box';

function FullScreen(props) {
    let {warehouse, screen} = useParams();
    let validWarehouse = false;

    switch(warehouse) {
        case "Kentucky":
        case "Pennsylvania":
            validWarehouse = true;
    }
    
    return (
        <Box display="flex" style={{minWidth:"100%", minHeight:"100%", position: "fixed", top: 0, left: 0, background: validWarehouse ? "white" : "red"}}>
            {!validWarehouse ? 
                <Box style={{margin: "auto", fontSize: "50px"}}>INVALID WAREHOUSE</Box>
            :
            cloneElement(props.children, {screen: screen, is_sandbox: props.is_sandbox, isLoading: props.isLoading, isMobile:props.isMobile, setLoading: props.setLoading, warehouse: warehouse})}
        </Box>
    )
    
}

export default FullScreen;
