import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import {lighten } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';




function BulkTable(props) {
    let columns = [ 
        {
            field: 'id',
            headerName: "ID",
            flex: 1,
            editable: false,
            minWidth: 50,
            hide: true,
            display: false
        },
        {
            field: 'carrier',
            headerName: 'Carrier',
            flex: 1,
            editable: false,
            minWidth: 100
        },
        {
            field: 'scac',
            headerName: 'SCAC',
            flex: 1,
            editable: false,
            minWidth: 200
        }, 
        {
            field: 'shorthand',
            headerName: 'Shorthand',
            flex: 1,
            editable: false,
            minWidth: 150
        }
    ]


    return(
        <Box className={props.isMobile ? "mobile-table-box" : "desktop-table-box"}>
            <DataGrid
                rows={props.carriers}
                className={props.isMobile ? "mobile-table" : "desktop-table"}
                columns={columns}
                rowsPerPageOption = {[25, 50, 100]}

                sortModel = {[{field:'carrier'}]}
            />
        </Box>   
    )


}

export default BulkTable;