import React, { useState, useEffect} from 'react';
import { BrowserRouter, Route, Navigate, Routes} from 'react-router-dom';
import FileUpload from './components/FileUpload/FileUpload';
import BulkFileUpload from './components/FileUpload/BulkFileUpload';
import ApiStatus from './components/ApiStatus/ApiStatus';
import Dashboard from './components/Dashboard/Dashboard';
import Inventory from './components/Inventory/Inventory';
import Pullsheets from './components/Pullsheets/Pullsheets';
import BulkPullsheets from './components/BulkPullsheets/BulkPullsheets';
import BoxLabels from './components/BoxLabels/BoxLabels'
import DsExport from './components/DsExport/DsExport';
import PalletLabels from './components/PalletLabels/PalletLabels';
import ProductionCalendar from './components/ProductionCalendar/ProductionCalendar';
import HoldOrders from './components/HoldOrders/HoldOrders'
import VendorStickers from './components/VendorStickers/VendorStickers';
import BulkOrders from './components/BulkOrders/BulkOrders';
import ExtractText from './components/ExtractText/ExtractText';
import Invoices from './components/Invoices/Invoices';
import ChangeOrders from './components/ChangeOrders/ChangeOrders';
import BulkStatus from './components/BulkStatus/BulkStatus';
import BulkCarriers from './components/BulkCarriers/BulkCarriers';
import FailedTracking from './components/FailedTracking/FailedTracking';
import WarehousePriority from './components/WarehousePriority/WarehousePriority';
import EmployeeTimes from './components/EmployeeTimes/EmployeeTimes';
import DailyBuilds from './components/DailyBuilds/DailyBuilds';
import ScanForms from './components/ScanForms/ScanForms';
import ShipmentInfo from './components/ShipmentInfo/ShipmentInfo';
import EfficiencyReport from './components/EfficiencyReport/EfficiencyReport';
import HourlyBuilds from './components/HourlyBuilds/HourlyBuilds';
import Autobuild from './components/Autobuild/Autobuild';
import OrderSearch from './components/OrderSearch/OrderSearch';
import BulkOrderSearch from './components/BulkOrderSearch/BulkOrderSearch'
import TransactionReport from './components/TransactionReport/TransactionReport'
import FullScreen from './components/FullScreen';
import ShippingDashboard from './components/ShippingDashboard/ShippingDashboard'
import Lots from './components/Lots/Lots';
import BulkInvoices from './components/BulkInvoices/BulkInvoices';
import Users from './components/Users/Users';
import Log from './components/Log/Log';
import BugReport from './components/BugReport';
import Login from './components/Login';
import RequireAuth from './components/RequireAuth';
import {Amplify, Hub, Logger} from 'aws-amplify';
import {Authenticator} from '@aws-amplify/ui-react';
import {useMediaQuery} from 'react-responsive';
import '@aws-amplify/ui-react/styles.css';
import './css/App.css';

import awsExports from './aws-exports';

Amplify.configure(awsExports);

const logger = new Logger('My-Logger');

const listener = (data) => {
    switch (data.payload.event) {
        case 'signIn':
            logger.info('user signed in');
            break;
        case 'signUp':
            logger.info('user signed up');
            break;
        case 'signOut':
            logger.info('user signed out');
            break;
        case 'signIn_failure':
            logger.error('user sign in failed');
            break;
        case 'tokenRefresh':
            logger.info('token refresh succeeded');
            break;
        case 'tokenRefresh_failure':
            logger.error('token refresh failed');
            break;
        case 'configured':
            logger.info('the Auth module is configured');
            break;
        default:
          return;
    }
}

Hub.listen('auth', listener);

function App() {

  const isMobile = useMediaQuery({query: '(max-width: 1224px)'});

  const [current_company, setCurrentCompany] = useState("");
  const [companies, setCompanies] = useState([]);
  const [scopes, setScopes] = useState([]);
  const [warehouses, setWarehouses] = useState({});
  const [current_warehouse, setCurrentWarehouse] = useState("");
  const [token, setToken] = useState("");
  const [user, setUser] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [openSubMenus, setOpenSubMenus] = useState({});

  const config = {
      furhaven: ["kentucky", "pennsylvania"],
      worldwise: ["kentucky"]
  }

  function checkScopes(permission = "") {
    return scopes.includes(permission) || scopes.includes("admin");
  }

  useEffect(() => {
    if(process.env.REACT_APP_SANDBOX) {
      let title = document.querySelector("title");
      title.innerHTML = "SANDBOX IMS"
      let link = document.querySelector("link[rel~='icon']");
      if(!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = "./sandbox.svg"
    }
  }, [])

  const is_sandbox = process.env.REACT_APP_SANDBOX ? true : false

  function clearUser() {
    setCurrentCompany("");
    setCompanies([])
    setWarehouses({});
    setCurrentWarehouse("");
    setOpenSubMenus({});
    setScopes([]);
    setToken("");
    setUser({})
    console.log("User Cleared.");
  }

  const handleSubMenuClick = async(type) => {
    let out = {};
    let found = false;
    for(let temp in openSubMenus) {
      if(temp === type) {
        found = true;
      }
      else {
        out[temp] = true;
      }
    }
    if(!found) out[type] = true;
    setOpenSubMenus(out);
  }

    return (
      <Authenticator.Provider>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />

            <Route path = '/' element={
              <RequireAuth is_sandbox={is_sandbox} config={config} isMobile={isMobile}  openSubMenus={openSubMenus} handleSubMenuClick={handleSubMenuClick} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
                <Dashboard />
              </RequireAuth> } />

            <Route path = '/uploads' element={
              <RequireAuth is_sandbox={is_sandbox} config={config} isMobile={isMobile}  openSubMenus={openSubMenus} handleSubMenuClick={handleSubMenuClick} route={"uploads"} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
                {checkScopes('dropship') ? 
                  <FileUpload /> : <Navigate to='/' replace />
                }
              </RequireAuth>
              } />

            <Route path = '/bulk_uploads' element={
              <RequireAuth is_sandbox={is_sandbox} config={config} isMobile={isMobile}  openSubMenus={openSubMenus} handleSubMenuClick={handleSubMenuClick} route={"bulk_uploads"} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
                {checkScopes('bulk') ? 
                  <BulkFileUpload /> : <Navigate to='/' replace />
                }
              </RequireAuth>
              } />
            <Route path = '/api_status' element={
              <RequireAuth is_sandbox={is_sandbox} config={config} isMobile={isMobile}  openSubMenus={openSubMenus} handleSubMenuClick={handleSubMenuClick} route={"api_status"} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
                {checkScopes('dropship') ? 
                  <ApiStatus /> : <Navigate to='/'  replace /> 
                }
              </RequireAuth>
            } />


            <Route path = '/failed_tracking' element={            
              <RequireAuth is_sandbox={is_sandbox} config={config} isMobile={isMobile}  openSubMenus={openSubMenus} handleSubMenuClick={handleSubMenuClick} route={"failed_tracking"} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
                {checkScopes('dropship') ? 
                  <FailedTracking /> : <Navigate to='/'  replace /> 
                }
              </RequireAuth>
            } />

            <Route path = '/pullsheets' element={
              <RequireAuth is_sandbox={is_sandbox} config={config} isMobile={isMobile}  openSubMenus={openSubMenus} handleSubMenuClick={handleSubMenuClick} route={"pullsheets"} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
                {checkScopes('dropship') ? 
                  <Pullsheets /> : <Navigate to='/'  replace /> 
                }
              </RequireAuth>
            } />

            <Route path = '/ds_export' element={
              <RequireAuth is_sandbox={is_sandbox} config={config} isMobile={isMobile}  openSubMenus={openSubMenus} handleSubMenuClick={handleSubMenuClick} route={"ds_export"} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
                {checkScopes('dropship') ? 
                  <DsExport /> : <Navigate to='/'  replace /> 
                }
              </RequireAuth>
            } />

            <Route path = '/box_labels' element={
              <RequireAuth is_sandbox={is_sandbox} config={config} isMobile={isMobile}  openSubMenus={openSubMenus} handleSubMenuClick={handleSubMenuClick} route={"box_labels"} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
                {checkScopes('bulk') ?
                  <BoxLabels /> : <Navigate to='/'  replace /> 
                }
              </RequireAuth>
            } />

            <Route path = '/production_calendar' element={
              <RequireAuth is_sandbox={is_sandbox} config={config} isMobile={isMobile}  openSubMenus={openSubMenus} handleSubMenuClick={handleSubMenuClick} route={"production_calendar"} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
                {checkScopes('bulk') ?
                  <ProductionCalendar /> : <Navigate to='/'  replace /> 
                }
              </RequireAuth>
            } />

            <Route path = '/bulk_carriers' element={
              <RequireAuth is_sandbox={is_sandbox} config={config} isMobile={isMobile}  openSubMenus={openSubMenus} handleSubMenuClick={handleSubMenuClick} route={"bulk_carriers"} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
                {checkScopes('bulk') ?
                  <BulkCarriers /> : <Navigate to='/'  replace /> 
                }
              </RequireAuth>
            } />

          <Route path = '/bulk_invoices' element={
              <RequireAuth is_sandbox={is_sandbox} config={config} isMobile={isMobile}  openSubMenus={openSubMenus} handleSubMenuClick={handleSubMenuClick} route={"bulk_invoices"} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
                {checkScopes('bulk') ?
                  <BulkInvoices /> : <Navigate to='/'  replace /> 
                }
              </RequireAuth>
            } />

            <Route path = '/transaction_report' element={
              <RequireAuth is_sandbox={is_sandbox} config={config} isMobile={isMobile}  openSubMenus={openSubMenus} handleSubMenuClick={handleSubMenuClick} route={"transaction_report"} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
                {checkScopes('bulk') || checkScopes('dropship') ?
                  <TransactionReport /> : <Navigate to='/'  replace /> 
                }
              </RequireAuth>
            } />

            <Route path = '/pallet_labels' element={
              <RequireAuth is_sandbox={is_sandbox} config={config} isMobile={isMobile}  openSubMenus={openSubMenus} handleSubMenuClick={handleSubMenuClick} route={"pallet_labels"} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
                {checkScopes('bulk') ?
                  <PalletLabels /> : <Navigate to='/'  replace /> 
                }
              </RequireAuth>
            } />

            <Route path = '/lots' element={
              <RequireAuth is_sandbox={is_sandbox} config={config} isMobile={isMobile}  openSubMenus={openSubMenus} handleSubMenuClick={handleSubMenuClick} route={"lots"} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
                {checkScopes('bulk') ?
                  <Lots /> : <Navigate to='/'  replace /> 
                }
              </RequireAuth>
            } />

            <Route path = '/order_search' element={            
              <RequireAuth is_sandbox={is_sandbox} config={config} isMobile={isMobile}  openSubMenus={openSubMenus} handleSubMenuClick={handleSubMenuClick} route={"order_search"} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
                {checkScopes('dropship') ? 
                  <OrderSearch /> : <Navigate to='/'  replace /> 
                }
              </RequireAuth>
            } />

            <Route path = '/bulk_pullsheets' element={            
              <RequireAuth is_sandbox={is_sandbox} config={config} isMobile={isMobile}  openSubMenus={openSubMenus} handleSubMenuClick={handleSubMenuClick} route={"bulk_pullsheets"} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
                {checkScopes('bulk') ? 
                  <BulkPullsheets /> : <Navigate to='/'  replace /> 
                }
              </RequireAuth>
            } />

            <Route path = '/bulk_search' element={            
              <RequireAuth is_sandbox={is_sandbox} config={config} isMobile={isMobile}  openSubMenus={openSubMenus} handleSubMenuClick={handleSubMenuClick} route={"bulk_search"} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
                {checkScopes('bulk') ? 
                  <BulkOrderSearch /> : <Navigate to='/'  replace /> 
                }
              </RequireAuth>
            } />

            <Route path = "/on_hold" element={
              <RequireAuth is_sandbox={is_sandbox} config={config} isMobile={isMobile}  openSubMenus={openSubMenus} handleSubMenuClick={handleSubMenuClick} route={"on_hold"} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
              {checkScopes('dropship') ?
                <HoldOrders /> : <Navigate to='/'  replace /> 
              }
              </RequireAuth>
            } />

            <Route path = "/warehouse_priority" element={
              <RequireAuth is_sandbox={is_sandbox} config={config} isMobile={isMobile}  openSubMenus={openSubMenus} handleSubMenuClick={handleSubMenuClick} route={"warehouse_priority"} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
              {checkScopes('dropship') ?
                <WarehousePriority /> : <Navigate to='/'  replace /> 
              }
              </RequireAuth>
            } />
            
            <Route path = "/employee_times" element={
              <RequireAuth is_sandbox={is_sandbox} config={config} isMobile={isMobile}  openSubMenus={openSubMenus} handleSubMenuClick={handleSubMenuClick} route={"employee_times"} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
              {checkScopes('warehouse') ?
                <EmployeeTimes /> : <Navigate to='/'  replace /> 
              }
              </RequireAuth>
            } />

            <Route path = "/daily_builds" element={
              <RequireAuth is_sandbox={is_sandbox} config={config} isMobile={isMobile}  openSubMenus={openSubMenus} handleSubMenuClick={handleSubMenuClick} route={"daily_builds"} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
              {checkScopes('warehouse') ?
                <DailyBuilds /> : <Navigate to='/'  replace /> 
              }
              </RequireAuth>
            } />

            <Route path = "/efficiency_report" element={
              <RequireAuth is_sandbox={is_sandbox} config={config} isMobile={isMobile}  openSubMenus={openSubMenus} handleSubMenuClick={handleSubMenuClick} route={"efficiency_report"} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
              {checkScopes('warehouse') ?
                <EfficiencyReport /> : <Navigate to='/'  replace /> 
              }
              </RequireAuth>
            } />

            <Route path = "/hourly_builds" element={
              <RequireAuth is_sandbox={is_sandbox} config={config} isMobile={isMobile}  openSubMenus={openSubMenus} handleSubMenuClick={handleSubMenuClick} route={"hourly_builds"} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
              {checkScopes('warehouse') ?
                <HourlyBuilds /> : <Navigate to='/'  replace /> 
              }
              </RequireAuth>
            } />

            <Route path = '/bulk_orders' element={            
              <RequireAuth is_sandbox={is_sandbox} config={config} isMobile={isMobile}  openSubMenus={openSubMenus} handleSubMenuClick={handleSubMenuClick} route={"bulk_orders"} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
                {checkScopes('bulk') ? 
                  <BulkOrders /> : <Navigate to='/'  replace /> 
                }
              </RequireAuth>
            } />

            <Route path = '/bulk_status' element={            
              <RequireAuth is_sandbox={is_sandbox} config={config} isMobile={isMobile}  openSubMenus={openSubMenus} handleSubMenuClick={handleSubMenuClick} route={"bulk_status"} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
                {checkScopes('bulk') ? 
                  <BulkStatus /> : <Navigate to='/'  replace /> 
                }
              </RequireAuth>
            } />

            <Route path = "/bulk_change" element={
              <RequireAuth is_sandbox={is_sandbox} config={config} isMobile={isMobile}  openSubMenus={openSubMenus} handleSubMenuClick={handleSubMenuClick} route={"bulk_change"} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
              {checkScopes('bulk') ? 
                <ChangeOrders /> : <Navigate to='/'  replace /> 
              }
            </RequireAuth>
          } />

            <Route path = '/inventory' element={
              <RequireAuth is_sandbox={is_sandbox} config={config} isMobile={isMobile}  openSubMenus={openSubMenus} handleSubMenuClick={handleSubMenuClick} route={"inventory"} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
                {checkScopes('inventory') ?
                  <Inventory /> : <Navigate to='/'  replace /> 
                }
              </RequireAuth>
            } />

            <Route path = '/extract_text' element={
              <RequireAuth is_sandbox={is_sandbox} config={config} isMobile={isMobile}  openSubMenus={openSubMenus} handleSubMenuClick={handleSubMenuClick} route={"extract_text"} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
                  <ExtractText />
              </RequireAuth>
            } />
      
            <Route path = '/vendor_stickers' element={
              <RequireAuth is_sandbox={is_sandbox} config={config} isMobile={isMobile}  openSubMenus={openSubMenus} handleSubMenuClick={handleSubMenuClick} route={"vendor_stickers"} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
                  <VendorStickers />
              </RequireAuth>
            } />      

                  
            <Route path = '/usps_scan_form' element={
              <RequireAuth is_sandbox={is_sandbox} config={config} isMobile={isMobile}  openSubMenus={openSubMenus} handleSubMenuClick={handleSubMenuClick} route={"usps_scan_form"} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
                  <ScanForms />
              </RequireAuth>
            } />      

            <Route path = '/autobuild' element={
              <RequireAuth is_sandbox={is_sandbox} config={config} isMobile={isMobile}  openSubMenus={openSubMenus} handleSubMenuClick={handleSubMenuClick} route={"autobuild"} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
                {checkScopes('acumatica') ?
                  <Autobuild /> : <Navigate to='/'  replace /> 
                }
              </RequireAuth>
            } />

            <Route path = '/invoices' element={
              <RequireAuth is_sandbox={is_sandbox} config={config} isMobile={isMobile}  openSubMenus={openSubMenus} handleSubMenuClick={handleSubMenuClick} route={"invoices"} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
                {checkScopes('acumatica') ?
                  <Invoices /> : <Navigate to='/'  replace /> 
                }
              </RequireAuth>
            } />  
            <Route path = '/users' element={
              <RequireAuth is_sandbox={is_sandbox} config={config} isMobile={isMobile}  openSubMenus={openSubMenus} handleSubMenuClick={handleSubMenuClick} route={"users"} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
                {checkScopes() ?
                  <Users /> : <Navigate to='/'  replace /> 
                }
              </RequireAuth>
            } />

            <Route path = '/log' element={
              <RequireAuth is_sandbox={is_sandbox} config={config} isMobile={isMobile}  openSubMenus={openSubMenus} handleSubMenuClick={handleSubMenuClick} route={"log"} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
                {checkScopes() ?
                  <Log /> : <Navigate to='/'  replace /> 
                }
              </RequireAuth>
            } />

          <Route path = '/bugreport' element={
              <RequireAuth is_sandbox={is_sandbox} config={config} isMobile={isMobile}  openSubMenus={openSubMenus} handleSubMenuClick={handleSubMenuClick} route={"bugreport"} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
                  <BugReport /> 
              </RequireAuth>
            } />

          <Route path = '/shipping_dash' element={
              <RequireAuth is_sandbox={is_sandbox} config={config} isMobile={isMobile}  openSubMenus={openSubMenus} handleSubMenuClick={handleSubMenuClick} route={"shipping_dash"} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
                  <ShipmentInfo /> 
              </RequireAuth>
            } />

          <Route path = "/shipping_dash/fullscreen/:warehouse/screen/:screen" element={
            <FullScreen is_sandbox={is_sandbox} config={config} isMobile={isMobile} setLoading={setLoading} isLoading={isLoading} token={token} setToken={setToken} user={user} setUser={setUser}>
              <ShippingDashboard />
            </FullScreen>
          } />

          </Routes>


        </BrowserRouter>
      </Authenticator.Provider>
    )
}

export default App;
