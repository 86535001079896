import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText'
import React, {useState} from 'react';

function CustomerInfo(props) {

    return ( 
        <Box display="flex" flexDirection="column" style={{width:"50%"}}>
            <h3>Customer Info:</h3>
            <Box display="flex" style={{borderTop:"2px solid black"}}>
                <Box style={{minWidth: "80px", maxWidth:"80px", fontWeight:"bold"}}>Shipping Address</Box>
                <List style={{borderLeft:"1px solid black"}} dense>
                    <ListItem>
                        <ListItemText  primary="Attention" secondary={props.order.attention === props.requested.attention ? props.order.attention : 
                            <Box>
                                <Box style={{textDecoration:"line-through"}}>{props.order.attention}</Box>
                                <Box style={{color:"red",fontStyle:"italic" }}>{props.requested.attention}</Box>
                            </Box>
                        }/>                            
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Street Address" secondary={props.order.address1 === props.requested.address1 ? props.order.address1 : 
                            <Box>
                                <Box style={{textDecoration:"line-through"}}>{props.order.address1}</Box>
                                <Box style={{color:"red",fontStyle:"italic" }}>{props.requested.address1}</Box>
                            </Box>
                        }/>      
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Street Address 2" secondary={props.order.address2 || ""}/>
                    </ListItem>
                </List>
                <List dense>
                    <ListItem>
                        <ListItemText primary="Location" secondary={props.order.location === props.requested.location ? props.order.location : 
                            <Box>
                                <Box style={{textDecoration:"line-through"}}>{props.order.location}</Box>
                                <Box style={{color:"red",fontStyle:"italic" }}>{props.requested.location}</Box>
                            </Box>
                        }/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="City" secondary={props.order.city === props.requested.city ? props.order.city : 
                            <Box>
                                <Box style={{textDecoration:"line-through"}}>{props.order.city}</Box>
                                <Box style={{color:"red",fontStyle:"italic" }}>{props.requested.city}</Box>
                            </Box>
                        }/>      
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="State" secondary={props.order.state === props.requested.state ? props.order.state : 
                            <Box>
                                <Box style={{textDecoration:"line-through"}}>{props.order.state}</Box>
                                <Box style={{color:"red",fontStyle:"italic" }}>{props.requested.state}</Box>
                            </Box>
                        }/>      
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Zip Code" secondary={props.order.zip_code === props.requested.zip_code ? props.order.zip_code : 
                            <Box>
                                <Box style={{textDecoration:"line-through"}}>{props.order.zip_code}</Box>
                                <Box style={{color:"red",fontStyle:"italic" }}>{props.requested.zip_code}</Box>
                            </Box>
                        }/>      
                    </ListItem>
                </List>
            </Box>
            <Box display="flex" style={{borderTop:"1px solid black"}}>
                <Box style={{minWidth: "80px", maxWidth:"80px", fontWeight:"bold"}}>Billing Address</Box>
                <List dense style={{borderLeft: "1px solid black"}}>
                    <ListItem>
                        <ListItemText  primary="Attention" secondary={props.order.billing_attention === props.requested.billing_attention ? props.order.billing_attention : 
                            <Box>
                                <Box style={{textDecoration:"line-through"}}>{props.order.billing_attention}</Box>
                                <Box style={{color:"red",fontStyle:"italic" }}>{props.requested.billing_attention}</Box>
                            </Box>
                        }/>                            
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Street Address" secondary={props.order.billing_address1 === props.requested.billing_address1 ? props.order.billing_address1 : 
                            <Box>
                                <Box style={{textDecoration:"line-through"}}>{props.order.billing_address1}</Box>
                                <Box style={{color:"red",fontStyle:"italic" }}>{props.requested.billing_address1}</Box>
                            </Box>
                        }/>      
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Street Address 2" secondary={props.order.billing_address2 || ""}/>
                    </ListItem>
                </List>
                <List dense>
                    <ListItem>
                        <ListItemText primary="City" secondary={props.order.billing_city === props.requested.billing_city ? props.order.billing_city : 
                            <Box>
                                <Box style={{textDecoration:"line-through"}}>{props.order.billing_city}</Box>
                                <Box style={{color:"red",fontStyle:"italic" }}>{props.requested.billing_city}</Box>
                            </Box>
                        }/>      
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="State" secondary={props.order.billing_state === props.requested.billing_state ? props.order.billing_state : 
                            <Box>
                                <Box style={{textDecoration:"line-through"}}>{props.order.billing_state}</Box>
                                <Box style={{color:"red",fontStyle:"italic" }}>{props.requested.billing_state}</Box>
                            </Box>
                        }/>      
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Zip Code" secondary={props.order.billing_zip_code === props.requested.billing_zip_code ? props.order.billing_zip_code : 
                            <Box>
                                <Box style={{textDecoration:"line-through"}}>{props.order.billing_zip_code}</Box>
                                <Box style={{color:"red",fontStyle:"italic" }}>{props.requested.billing_zip_code}</Box>
                            </Box>
                        }/>      
                    </ListItem>
                </List>
            </Box>
        </Box>
    )
}

export default CustomerInfo;