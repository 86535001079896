import React, { useEffect, useState } from 'react';
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText'
import CircularProgress from '@mui/material/CircularProgress';
import orderHelpers from '../../helpers/orders';
import DetailsTable from './DetailsTable';
import bulkHelpers from '../../helpers/bulk';
import Button from '@mui/material/Button';
import ShortItemsPopup from './ShortItemPopup';

function BulkOrderDetails(props) {
    const [orders, setOrders] = useState(null);
    const [pos, setPOs] = useState([]);
    const [internalPrice, setInternalPrice] = useState(0);
    const [externalPrice, setExternalPrice] = useState(0);
    const [openShortItems, setOpenShortItems] = useState(false);

    useEffect(() => {
        const getOrders = async() => {
            let out = [];
            let out_items = [];
            let j = 1;
            let i_price = 0;
            let ex_price = 0;
            for(let selected of props.selected) {
                let body = {internal_po_number: selected.internal_po_number, vendor_name: selected.vendor_name}
                let res = await orderHelpers.getBulkOrders(props.company + "_bulk", body, props.token);
                if(res.length === 0) {
                    continue
                }
                let res_out = res[0];
                res_out.items = [];
                let skus = [...new Set(res.map(obj => obj.internal_sku))];
                let i = 1;
                for(let sku of skus) {
                    let items = res.filter(obj => obj.internal_sku === sku);
                    let count = items.reduce((a, b) => a + b.quantity, 0);
                    let item = items[0];
                    let kentucky = items.filter(obj => obj.decision === "Kentucky").reduce((a, b) => a + b.quantity, 0);
                    let pennsylvania = items.filter(obj => obj.decision === "Pennsylvania").reduce((a, b) => a + b.quantity, 0);
                    let backordered = items.filter(obj => obj.decision === "Back Ordered").reduce((a, b) => a + b.quantity, 0);
                    let discontinued = items.filter(obj => obj.decision === "Discontinued").reduce((a, b) => a + b.quantity, 0);
                    let cancelled = items.filter(obj => obj.decision === "Cancel").reduce((a, b) => a + b.quantity, 0);

                    res_out.items.push({
                        id: i,
                        internal_sku: item.internal_sku,
                        external_sku: item.external_sku,
                        external_price: item.external_price || 0,
                        internal_price: item.internal_price || 0,
                        quantity: count,
                        kentucky: kentucky,
                        pennsylvania: pennsylvania,
                        backordered: backordered,
                        cancelled: cancelled,
                        discontinued: discontinued
                    });
                    out_items.push({
                        id: j,
                        internal_po_number: selected.internal_po_number,
                        internal_sku: item.internal_sku,
                        external_sku: item.external_sku,
                        external_price: item.external_price || 0,
                        internal_price: item.internal_price || 0,
                        quantity: count,
                        kentucky: kentucky,
                        pennsylvania: pennsylvania,
                        backordered: backordered,
                        cancelled: cancelled,
                        discontinued: discontinued
                    })
                    i_price += item.internal_price || 0;
                    ex_price += item.external_price || 0;
                    j++;
                    i++;
                }
                out.push(res_out);
            }
            setOrders({orders:out, items: out_items});
            setPOs([...new Set(out.map(obj=>obj.internal_po_number))])
            setInternalPrice(i_price);
            setExternalPrice(ex_price);
        }
        getOrders();
    }, [])

    function getDateRange() {
        console.log("getting dates")
        let sorted = [...new Set(orders.orders.map(obj => obj.order_date ? obj.order_date.substring(0, 10) : "N/A").filter(obj => obj !== "N/A"))]
        sorted.sort((a, b) => new Date(a) - new Date(b))
        if(sorted.length === 0) return "N/A";
        if(sorted.every(obj => obj === sorted[0])) return sorted[0];
        let first = sorted[0].split("-");
        let last = sorted[sorted.length-1].split("-");
        return `${first[1]}/${first[2]}/${first[0]}-${last[1]}/${last[2]}/${last[0]}`
    }
    
    const reAck = async() => {
        props.setLoading(true)
        props.toast.promise(ackHelper(), {
            success: "Acknowledgement Sent!",
            error: (err) => err.message
        })
    }

    const ackHelper = async() => {
        let error = null;
        try {
            for(let selected of props.selected) {
                let body = {internal_po_number: selected.internal_po_number, vendor_name: selected.vendor_name}
                await bulkHelpers.sendAck(JSON.stringify(body), props.token, props.company + "_bulk")
            }
        }
        catch(err) {
            console.log(err)
            error = err;
        }
        finally {
            props.setLoading(false);
            if(error) throw error;
        }
    }
    

    return(
        <Drawer open={props.openDetails} onClose={() => props.setOpenDetails(false)} anchor="bottom">
            {openShortItems ? <ShortItemsPopup {...props} open={openShortItems} handleClose={() => setOpenShortItems(false)} items={orders.items} po={orders.orders[0].internal_po_number} vendor_name={orders.orders[0].vendor_name} /> : null}
            {orders?.orders ? 
            <Box style={{height: "600px", width:"75%"}}  display="flex" sx={{m:"auto", mb:"80px", pt:"20px"}}>
                <List dense>
                    <Box display="flex" flexDirection="column" style={{height:"100px", overflowY:"hidden"}}>
                        <Box style={{margin:"auto", fontWeight:"bold",fontSize:"30px"}}>Orders:</Box>
                        <Box display="flex" flexDirection="column" style={{height:"100px", overflowY:"scroll"}}>
                            {pos.map(obj => {
                                return(<Box style={{margin:"auto",fontSize:"20px", width:"100%", textAlign:"center"}}>{obj}                
                                        {obj !== pos[pos.length - 1] ? <Divider flexItem style={{width:"100%"}} /> : null}
                                    </Box>)
                            })}
                        </Box>
                    </Box>
                    <ListItem>
                        <ListItemText primary="Order Dates" secondary={getDateRange()}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Vendor" secondary={orders.orders[0].vendor_name} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="External PO" secondary={orders.orders[0].external_po_number} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Total Int. Price" secondary={`$${internalPrice}`} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Total Ext. Price" secondary={`$${externalPrice}`} />
                    </ListItem>
                    {/* <ListItem>
                        <ListItemText primary="Acumatica Order" secondary={orders.acu_order_num || "N/A"}/>
                    </ListItem> */}
                    <Box>
                        <Button variant="contained" onClick={()=>reAck()}>Resend Ack</Button>
                        <Button variant="contained" color="success" onClick={()=>setOpenShortItems(true)}>Short Items</Button>
                    </Box>
                </List>
                <Divider sx={{ml:"10px", mr:"10px"}} orientation="vertical" flexItem />
                <Box style={{width:"100%"}}>
                    <DetailsTable {...props} items={orders.items} />
                </Box>
                
            </Box> :
            <Box display="flex" style={{height:"100px"}}>
                <CircularProgress style={{margin:"auto"}} />
            </Box>}
        </Drawer>
    )
}

export default BulkOrderDetails;