
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import orderHelpers from '../../helpers/orders';
import ScanFormsTable from './ScanFormsTable';

function ScanForms(props) {
    const [forms, setForms] = useState([]);
    const [refresh, setRefresh] = useState(false);


    useEffect(() => {
        const getForms = async() => {
            let forms = await orderHelpers.getScanForms(props.token, props.company);
            console.log(forms)
            setForms(forms.Contents.sort((a,b) => { return new Date(b?.LastModified).getTime() - new Date(a?.LastModified).getTime()}))
        }
        getForms()
    }, [refresh])

    const generateScanForms = async() => {
        props.toast.promise(orderHelpers.generateScanForms(props.token, props.company), {
            success: "Created New Scan Form",
            error: "Failed to Create Scan Form"
        })
    }

    const downloadData = async(key) => {
        props.toast.promise(orderHelpers.getScanForm(key, props.token, props.company), {
            success: (res) => {
                console.log(res);
                let a = document.createElement('a');
                a.href = res;
                a.target="_blank";
                a.setAttribute('download', key);
                a.click();
            },
            error: "Failed to Download Scan Form"
        })
    }


    return(
        <Box display="flex" flexDirection="column" className={props.isMobile ? "mobile-top" : "desktop-top"}>
            <Box className={props.isMobile ? "mobile-box" : "desktop-box"}>
                <h1>USPS Scan Forms</h1> 
                <Button onClick={()=>generateScanForms()}>Generate Today's Scan Form</Button>
                <ScanFormsTable refresh={refresh} setRefresh={setRefresh} forms={forms} {...props} downloadData={downloadData} />
            </Box>
        </Box>
    )
}

export default ScanForms