import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { CSVReader } from 'react-papaparse';
import Box from '@mui/material/Box';

import bulkHelpers from '../../helpers/bulk'
import OrderTable from './OrderTable';
import OrderDetails from './OrderDetails';
import { formatDate } from './OrderUtils';


const buttonRef = React.createRef();

function Lots(props) {

    const HEADERS = ["internal_po_number", "vendor_name", "internal_sku", "lot_number", "manufacturer", "expiration_date"]

    const [openTreatlyOrders, setOpenTreatlyOrders] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState({});
    const [file, setFile] = useState(null);
    const [error, setError] = useState("");
    const [uploads, setUploads] = useState(null);
    const [success, setSuccess] = useState(false);


    useEffect(() => {
        getOpenTreatlyOrders();
    }, []);

    const getOpenTreatlyOrders = async () => {
        props.setLoading(true);
        try {
            const openTreatlyOrdersResponse = await bulkHelpers.getOpenTreatlyOrders(props.company + "_bulk", props.token);
            const mappedOrders = mapOrders(openTreatlyOrdersResponse.data);
            setOpenTreatlyOrders(mappedOrders);
            props.setLoading(false);
        } catch (error) {
            props.toast.error("Failed to retrieve open Treatly orders");
            console.log(error);
            props.setLoading(false);            
        }
    };

    const mapOrders = orders => {
        return orders.map(order => {
            const mappedOrderItems = order.items.map(item => ({
                ...item,
                expiration_date: formatDate(item.expiration_date)
            }));

            return {
                ...order,
                upload_date: formatDate(order.upload_date),
                items: mappedOrderItems,
            };
        });
    };

    const displayOrderItems = internalPoNumber => {
        const newSelectedOrder = openTreatlyOrders.find(order => order.internal_po_number === internalPoNumber);

        if (!newSelectedOrder) {
            props.toast.error("An invalid order was selected");
        } else {
            setSelectedOrder(newSelectedOrder);
        }
    };

    const shortItem = async (itemInfo) => {
        const bodyString = JSON.stringify({
            items: [itemInfo.internal_sku],
            internal_po_number: selectedOrder.internal_po_number,
            vendor_name: itemInfo.vendor_name
        });

        const loadingToastId = props.toast.loading("Shorting item...");
        try {
            await bulkHelpers.saveItemShorts(bodyString, props.company + "_bulk", props.token);
            props.toast.dismiss(loadingToastId);
            props.toast.success("Successfully shorted item");
            removeItemFromState(itemInfo.internal_sku, selectedOrder.internal_po_number);
        } catch (error) {
            props.toast.dismiss(loadingToastId);
            props.toast.error("Failed to short item");
            console.log(error);
        }
    };

    const removeItemFromState = (internalSku, internalPoNumber) => {

        const order = openTreatlyOrders.find(treatlyOrder => treatlyOrder.internal_po_number === internalPoNumber);

        if (order.items.length === 1 && order.items[0].internal_sku === internalSku) {
            
            // if item is the only item in the order, remove the entire order
            const orders = openTreatlyOrders.filter(treatlyOrder => treatlyOrder.internal_po_number !== internalPoNumber);
            setSelectedOrder({});
            setOpenTreatlyOrders(orders);
        } else {

            // otherwise, just remove the item from the order
            const orders = openTreatlyOrders.map(treatlyOrder => {
                if (treatlyOrder.internal_po_number === internalPoNumber) {
                    const remainingItems = treatlyOrder.items.filter(item => item.internal_sku !== internalSku);
                    return {
                        ...treatlyOrder,
                        items: remainingItems
                    }
                } else {
                    return treatlyOrder;
                }
            });

            setOpenTreatlyOrders(orders);
        }
    };

    useEffect(() => {
        if (selectedOrder.internal_po_number) {
            displayOrderItems(selectedOrder.internal_po_number);
        }
    }, [openTreatlyOrders]);


    const handleOpenDialog = (e) => {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
            buttonRef.current.open(e);
        }
    };

    const formatUpload = (data) => {
        try {
            const dataArray = [];
            data.forEach(row =>{
                let d = row.data;
                if(d.production_date) {
                    d.production_date = new Date(d.production_date).toISOString();
                }
                if(d.expiration_date) {
                    d.expiration_date = new Date(d.expiration_date).toISOString();
                }
                console.log(d);
                dataArray.push(d)
            })
            return dataArray;
        }
        catch(err) {
            console.log(err);
            window.alert(err);
            return false;
        }

    }

    const handleOnFileLoad = (data, file) => {
        console.log('---------------------------');
        setFile(file);
        setSuccess(false);
        const up = formatUpload(data);
        if(!up) {
            setFile(null);
            return;
        }
        setUploads(up);
        console.log('---------------------------');
    };

    const handleOnError = (err, file, inputElem, reason) => {
        console.log('---------------------------');
        console.log(err);
        console.log('---------------------------');
        setError(`Error Uploading Order File: ${file}`)
    };


    const uploadFile = async () => {
        props.setLoading(true);
        setError("")
        setSuccess(false)
        props.toast.promise(uploadHelper(), {
            success: "Lots Uploaded!",
            error: "Failed to Upload Lots"
        })
        // if(upload === true) {
        //     setSuccess(true);
        //     handleRemoveFile()
        //     setTimeout(() => {
        //         // getUploads()
        //     }, 5000)
        // } 
        // else {
        //     setError(`Error Uploading Order File: ${upload}`);
        // }
        props.setLoading(false);
    }

    const uploadHelper = async() => {
        await bulkHelpers.uploadLots({data: uploads}, props.company + "_bulk", props.token)
    }

    const handleRemoveFile = (e) => {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
            buttonRef.current.removeFile(e);
            setFile(null);
            setUploads(null);
        }
    };

    const downloadTemplate = async() => {
        let csv = HEADERS;
        let blob = new Blob([csv], {type: "text/csv"})
        let uri = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.setAttribute("href", uri);
        a.setAttribute("download", "LOT_NUMBERS_TEMPLATE.csv");
        document.body.appendChild(a);
        a.click();
    }

    
    return (
        <Box display="flex" flexDirection="column" className={props.isMobile ? "mobile-top" : "desktop-top"}>
            <Box className={props.isMobile ? "mobile-box" : "desktop-box"}>
                <h1>Lots</h1> 
                <Button onClick={() => downloadTemplate()}>Get Template</Button>

                <h3>This process will only work if every individual SKU on the order has <u> ONLY ONE</u> lot number! </h3>
                <h5>If you have one SKU on an order that has multiple lot numbers, email the breakdown of which lot numbers went with which Box Label to Matt.</h5>
                <CSVReader
                        ref={buttonRef}
                        onFileLoad={handleOnFileLoad}
                        onError={handleOnError}
                        noClick
                        noDrag
                        config={{
                            header: true,
                            skipEmptyLines: true
                        }}
                        style={{marginTop:"20px", marginBottom:"20px"}}
                        >
                        {({ file_obj }) => (
                    <aside style={{marginTop:"20px", marginBottom:"20px"}}>
                        <Button variant='contained' onClick={handleOpenDialog} className={`${props.company} ${props.warehouse} secondary`}
                        // sx={{mr: '10px', mb: '10px'}}
                        >Browse file</Button>
                        <div>
                        {file_obj && file_obj.name}
                        </div>
                        {file !== null ? 
                        <Box 
                        // sx={{flexDirection: 'row', mt: '10px'}}
                        >
                            <Button variant='contained' onClick={uploadFile} className={`${props.company} ${props.warehouse} secondary`}
                            // sx={{mr: '10px'}}
                            >Upload</Button>
                            <Button variant='contained' sx={{ml:"30px"}} onClick={handleRemoveFile} className={`${props.company} ${props.warehouse} tertiary`}>Remove</Button>
                        </Box>
                        : null}
                    </aside>
                    )}
                </CSVReader>
                <Button onClick={getOpenTreatlyOrders} variant="contained">Refresh</Button>

                <OrderTable orders={openTreatlyOrders} displayOrderItems={displayOrderItems} />
                <OrderDetails selectedOrder={selectedOrder} setSelectedOrder={setSelectedOrder} shortItem={shortItem} />
            </Box>
        </Box>
    )
}

export default Lots;