import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';


function NewUser(props) {
    
    function permissionEmail() {
        let a = document.createElement('a');
        a.href=`mailto:furhaven.production@petwisebrands.com?subject=${props.user.attributes.name} User Permissions&body=${props.user.attributes.name} requires user permissions for IMS. Please let them know when their account is ready.`
        a.setAttribute("target", "_blank");
        a.click();
    }

    return(
        <Box className={props.isMobile ? "mobile-top" : "desktop-top"} display="flex" flexDirection="column">
            <Box display="flex" flexDirection="column" style={{width:"50%"}} className={props.isMobile ? "mobile-box": "desktop-box"}>
                <h2>New User</h2>
                <h4>Thank you for creating your account. An Admin will need to assign your account permissions. Please click the button below or send an email to furhaven.production@petwisebrands.com to request permissions.</h4>
                <h4>Once your permissions are active, you will need to log out and then back in.</h4>
                <Button variant="contained" color="success" onClick={() => permissionEmail()}>Request Permissions</Button>
            </Box>
        </Box>
    )
}

export default NewUser;
