import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import './lots.css';
import { isItemValid } from './OrderUtils';


const columns = [{
    field: 'internal_po_number',
    headerName: "PO",
    flex: 1,
    editable: false,
    minWidth: 100,
    maxWidth: 200
}, {
    field: 'upload_date',
    headerName: "Upload Date",
    flex: 1,
    editable: false,
    minWidth: 100,
    maxWidth: 200
}, {
    valueGetter: (value) => {
        const orderItems = value.row.items;
        const validItemsCount = orderItems.filter(isItemValid).length;
        return `${validItemsCount} / ${orderItems.length}`;
    },
    field: 'valid_items', 
    headerName: "Valid expiration date and lot number",
    flex: 1,
    editable: false,
    minWidth: 300,
    maxWidth: 300
}];


function OrderTable(props) {

    const rows = props.orders.map(order => ({
        ...order,
        id: order.internal_po_number
    }));

    const getRowClassName = params => {
        const orderItems = params.row.items;
        const validItemsCount = orderItems.filter(isItemValid).length;
        return validItemsCount === orderItems.length ? "green-table-row" : "red-table-row";
    };

    return (
        <Box className={props.isMobile ? "mobile-table-box" : "desktop-table-box"}>
            <DataGrid
                style={{width:"70%", marginLeft:"0%"}}
                rows={rows}
                className={props.isMobile ? "mobile-table" : "desktop-table"}
                columns={columns}
                rowsPerPageOption = {[25, 50, 100]}
                checkboxSelection = {false}
                getRowClassName={getRowClassName}
                onRowClick={event => props.displayOrderItems(event.id)}
            />
        </Box>
    );
};

export default OrderTable;