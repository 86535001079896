import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DownloadIcon from '@mui/icons-material/Download';

function BulkPullsheetsForm(props) {

     return(
        <Box className = {props.isMobile ? "mobile-box" : "desktop-box"} sx = {{display: 'flex', flexDirection: 'column'}}>
            <Box className = {props.isMobile ? "mobile-box" : "desktop-box"} sx = {{flexDirection: 'row', mb: '10px'}}>
                <TextField 
                    name = 'acumaticaOrderNum'
                    value = {props.acumaticaOrderNum}
                    label = 'Acumatica Order #'
                    onChange = {props.handleChange}
                    sx = {{ width: '20.2%', mr: "0.6%" }}
                />
                <Button 
                    onClick = {props.createBulkPullsheet} 
                    endIcon = {<DownloadIcon />}
                    size = 'large'
                    sx = {{mt: '10px', fontWeight: 'bold', color: '#84B375'}}>
                    Download
                </Button>
            </Box>
        </Box>
    )
}

export default BulkPullsheetsForm;