import React, { useEffect } from 'react';
import { BarChart, XAxis, YAxis, Tooltip, Bar, CartesianGrid, Legend} from 'recharts';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';



function HourlyBuildsTable(props) {

    useEffect(() => {
    }, [])   

    let first_index = Object.keys(props.hourlyBuilds)[0];
    let cardFont = 35;

    let generateGradientColors = (number) => { 
        let colors = [];
        let numColors = props.shift === 'Both' ? 24 : 12
        let hueStep = 360 / numColors;
    
        for (let i = 0; i < numColors; i++) {
            let hue = (i * hueStep) % 360;
            let saturation = 80; 
            let lightness = 45; 
            let color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
            colors.push(color);
        }
        if(props.shift === 'Second'){
            number = number - 12;
        }
        return colors[number];
    };
  
    return (
    <Box className={props.isMobile ? 'mobile-table-box' : 'desktop-table-box'} style={{ width: '70%' }}>
      <Box display="flex">
        <BarChart
          width={1200}
          height={800}
          data={Object.entries(props.hourlyBuilds).map(([key, values]) => ({ name: key, ...values }))}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend
            verticalAlign="left"
            content={() => {
              return (
                <ul>
                  {props.hourlyBuilds[first_index] &&
                  Object.keys(props.hourlyBuilds[first_index]).map((hour, index) => (
                    Object.values(props.hourlyBuilds).some((obj) => obj[hour] !== 0) && (
                      <li key={hour} style={{ color: generateGradientColors(index) }}>
                        <span
                          style={{
                            display: 'inline-block',
                            width: '10px',
                            height: '10px',
                            backgroundColor: generateGradientColors(index),
                          }}
                        ></span>
                        {`${hour}`}
                      </li>
                    )
                  ))}
                </ul>
              );
            }}
          />
          {props.hourlyBuilds[first_index] &&
            Object.keys(props.hourlyBuilds[first_index]).map((hour, index) => {
            const showBar = Object.values(props.hourlyBuilds).some((obj) => obj[hour] !== 0);
            return (
              showBar && (
                <Bar
                  key={hour}
                  dataKey={hour}
                  stackId="quantity"
                  fill={generateGradientColors(index)}
                />
              )
            );
          })}
        </BarChart>
        <Box display="flex" justifyContent="center">
        {props.totalBuilds !== undefined ? (
          <Card variant="elevation" elevation={0}>
              <CardContent>
              <Typography sx={{ fontSize: cardFont }} color="text.secondary" gutterBottom>
                  Total Builds: {props.totalBuilds}
              </Typography>
              </CardContent>
          </Card>
          ) : null}
      </Box>
      </Box>
    </Box>
  );
};
export default HourlyBuildsTable;