import React, { Component } from 'react';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import RadioGroup from '@mui/material/RadioGroup';

class PullsheetConfig extends Component {

    disableWarehouse = () => {
        if(this.props.admin === 1){
            return false
        } else {
            return true
        }
    }

    render(){
        return(
            <Box sx = {{display: 'flex', flexDirection: 'row'}}>
            <Box component = 'form'>
                {/* <Box sx = {{display: 'flex', flexDirection: 'column'}}>
                    <h3>{this.props.addUser ? 'New User' : 'Edit User'}</h3>
                    <TextField 
                        required
                        name='name'
                        label='Name'
                        onChange = {this.props.handleChange}
                        defaultValue = {this.props.addUser ? '' : this.props.selectedUser.name}
                        sx = {{marginBottom: '10px'}}
                    />
                    <TextField 
                        required
                        name='email'
                        label='Email'
                        onChange = {this.props.handleChange}
                        defaultValue = {this.props.addUser ? '' : this.props.selectedUser.email}
                        sx = {{marginBottom: '10px'}}
                    />
                </Box> */}
                <FormGroup
                    onChange={this.props.handleChange}
                >
                    {this.props.warehouse === "All" ? <FormControl>
                        <InputLabel id='warehouseInput'>Warehouse</InputLabel>
                        
                        <Select
                            required
                            name='warehouse'   
                            label="Warehouse"        
                            onChange={this.props.handleChange}
                            // defaultValue={this.props.warehouses[0]}
                            // disabled={this.disableWarehouse()}
                        >
                            {this.props.warehouses ? 
                            this.props.warehouses.map((obj, index) => {
                                return <MenuItem key={index} value={obj}>{obj.toString()[0].toUpperCase() + obj.substring(1)}</MenuItem>
                            }) : null }
                        </Select>
                    </FormControl>
                    :
                        null
                    }
                    <FormControl sx={{mt: '10px'}}>
                        <InputLabel id='carrierInput'>Carrier</InputLabel>
                        <Select
                            required
                            name='carrier'   
                            label="Carrier"        
                            onChange={this.props.handleChange}
                            defaultValue=''
                        >
                            <MenuItem value='fedex'>FedEx</MenuItem>
                            <MenuItem value='ups'>UPS</MenuItem>
                            <MenuItem value='usps'>USPS</MenuItem>
                            <MenuItem value="amazon">Amazon</MenuItem>
                        </Select>
                    </FormControl>
                    <RadioGroup onChange={this.props.handleChange} value={this.props.type}>
                        <FormControlLabel control={<Radio />} name="type" value='multiples' label="Multiples" />
                        <FormControlLabel control={<Radio />} name="type" value='presses' label="Presses" />
                        <FormControlLabel control={<Radio />} name="type" value='assembly' label="Assembly" />
                        <FormControlLabel control={<Radio />} name="type" value='non_assembly' label="Non-Assembly" />
                        <FormControlLabel control={<Radio />} name="type" value="warranties" label="Warranties" />
                        <FormControlLabel control={<Radio />} name="type" value="petsense" label="Petsense" />
                        <FormControlLabel control={<Radio />} name="type" value="sioc" label="SIOC" />
                        <FormControlLabel control={<Radio />} name="type" value="samples" label="Samples" />
                    </RadioGroup>
                </FormGroup>
                <Box sx = {{marginLeft: '100px'}}>
                    <Button variant='contained' onClick={this.props.showPullsheetTable} color="success" sx={{mt: '18px', ml: '5px'}}>Cancel</Button>
                    <Button disabled={(this.props.selectedWh === "" && this.props.warehouse === "All") || this.props.type === "" || this.props.carrier === ""} variant='contained' onClick={this.props.createPullsheet} color="success" sx={{mt: '18px', ml: '5px'}}>Submit</Button>
                </Box>
            </Box>
            
        </Box>
        )
    }
}

export default PullsheetConfig;