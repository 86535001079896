import axios from 'axios';
import getJWT from './token';

let api = `https://api.${process.env.REACT_APP_BASE_URL || "mattk.commerce-ims.com"}`;


const vendorHelpers = {
    
    getVendors: async function(company_id, token){
        const userToken = await getJWT(token);
        const url = `${api}/dropship/vendors`;
        try {
            let res = await axios.get(url, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` }})
            let data = res.data.map(obj => obj.vendor_name);
            return {
                success: true,
                vendors: data
            } ;
        } catch (err) {
            return {
                success: false, 
                error: err
            };
        }
    },

    getBulkVendors: async function(company_id, token){
        const userToken = await getJWT(token);
        const url = `${api}/bulk/vendors`;
        try {
            let res = await axios.get(url, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` }})
            let data = res.data.map(obj => obj.vendor_name);
            return {
                success: true,
                vendors: data
            } ;
        } catch (err) {
            return {
                success: false, 
                error: err
            };
        }
    }
}

export default vendorHelpers;