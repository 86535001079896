import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';

function EfficiencyReportQueryForm(props) {

     return(
        <Box className={props.isMobile ? "mobile-box" : "desktop-box"} display="flex" flexDirection="column">
        <Box display="flex">
            <FormControl sx={{minWidth:"300px", ml:"20px"}}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                        label="Start Date"
                        name='orderStartDate'
                        onChange={(e) => props.setStartDate(e)}
                        value={props.startDate ? new Date(props.startDate): null}
                        renderInput={(params) => <TextField {...params} />}
                        clearable={true}
                        disabled={props.isLoading} 
                    />
                </LocalizationProvider>
            </FormControl>
            <FormControl sx={{minWidth:"300px", ml:"20px"}}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                        label="End Date"
                        name='orderEndDate'
                        onChange={(e) => props.setEndDate(e)}
                        value={props.endDate ? new Date(props.endDate) : null}
                        renderInput={(params) => <TextField {...params} />}
                        clearable={true}
                        disabled={props.isLoading} 
                        />
                </LocalizationProvider>
            </FormControl>
        </Box>
        <Box className={props.isMobile ? "mobile-box" : "desktop-box"} display="flex" sx={{mt:"20px"}}>
            <FormControl sx={{minWidth:"300px", ml:"20px", mr:"20px"}}>
                <InputLabel id="warehouse_label">Warehouse</InputLabel>
                <Select
                    value={props.warehouse}
                    name='warehouse'   
                    label="Warehouse"        
                    onChange={(e) => props.setWarehouse(e.target.value)}
                    defaultValue={""}
                    disabled={props.isLoading}
                >
                    <MenuItem value="KY">Kentucky</MenuItem>
                    <MenuItem value="PA">Pennsylvania</MenuItem>
                    <MenuItem value="TX">Texas</MenuItem>
                </Select>
            </FormControl>
            {props.warehouse === 'KY' || props.warehouse === 'PA' ? <FormControl sx={{minWidth:"160px", ml:"0px", mr:"10px"}}>
                <InputLabel id="shift_label">Shift</InputLabel>
                <Select
                    value={props.shift.length > 0 ? props.shift : "Both"} 
                    name='shift'   
                    label="Shift"        
                    onChange={(e) => props.setShift(e.target.value)}
                    defaultValue={"Both"}
                    disabled={props.isLoading}
                >
                    <MenuItem value="First">First</MenuItem>
                    <MenuItem value="Second">Second</MenuItem>
                    <MenuItem value="Both">Both</MenuItem>
                </Select>
            </FormControl> : null }
            <Button onClick={() => props.search()} disabled={props.isLoading} variant="contained" color="success" size="large" sx={{ ml: props.isMobile ? "40px" : "0", marginLeft: "20px" }}>Search</Button>    
        </Box>  
        <Box className={props.isMobile ? "mobile-box" : "desktop-box"} display="flex" sx={{ mt: props.isMobile ? "20px" : "0", ml: props.isMobile ? "0" : "20px" }} >
        </Box>
      </Box>
    );
  };

export default EfficiencyReportQueryForm;