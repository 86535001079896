import {React, cloneElement, useEffect, useRef} from 'react';
import {Auth} from 'aws-amplify';
import {useLocation, Navigate} from 'react-router-dom';
import {useAuthenticator} from '@aws-amplify/ui-react';
import Box from '@mui/material/Box';
import IMSToolbar from './Toolbar/IMSToolbar';
import NewUser from './NewUser';
import toast, {Toaster} from "react-hot-toast";

function RequireAuth(props) {
    const location = useLocation();
    const {route} = useAuthenticator((context) => [context.route]);

    const config = useRef(props.config)["current"];
    const currentCompany = useRef(props.company)["current"];
    const setCompanies = props.setCompanies;
    const setWarehouses = props.setWarehouses;
    const setCurrentCompany = props.setCurrentCompany;
    const setCurrentWarehouse = props.setCurrentWarehouse;
    const setToken = props.setToken;
    const setUser = props.setUser;
    const setScopes = props.setScopes;

    useEffect(() => {
        const logUser = async() => {
            let auth_user = await Auth?.currentAuthenticatedUser()
            let cognito_groups = auth_user.signInUserSession?.idToken?.payload["cognito:groups"] || []
            let companies = cognito_groups.filter(obj => Object.keys(config).includes(obj));
            let warehouses = {};
            for(let company of companies) {
                warehouses[company] = cognito_groups.filter(obj => config[company].includes(obj));
            }
            let scopes = cognito_groups.filter(function(entry) {
                if(companies.includes(entry)) return false;
                for(let company in config) {
                    if(config[company].includes(entry)) return false;
                }
                return true;
            });
            setScopes(scopes);
            companies.sort();
            setCompanies(companies);
            let current_company = currentCompany || companies[0];
            setCurrentCompany(current_company);
            setWarehouses(warehouses);
            if(warehouses[current_company]) {
                if(warehouses[current_company].length > 1) setCurrentWarehouse("All");
                else setCurrentWarehouse(warehouses[current_company][0]);
            }
            else {
                setCurrentWarehouse("");
            }
            setToken(Auth);
            setUser(auth_user);
        }
        logUser();
    }, [currentCompany, config, setCompanies, setWarehouses, setCurrentCompany, setCurrentWarehouse, setToken , setUser, setScopes])


    if(route !== "authenticated") {
        return <Navigate to="/login" state={{from: location}} replace />
    }
    
    return (
        <Box style={{maxWidth:"100%", overflowX:"hidden"}}>
            <Toaster />
            <Box className={props.isMobile ? "mobile-top" : "desktop-top"}>
                <IMSToolbar is_sandbox={props.is_sandbox} openSubMenus={props.openSubMenus} handleSubMenuClick={props.handleSubMenuClick} isMobile={props.isMobile} clearUser={props.clearUser} isLoading={props.isLoading} setLoading={props.setLoading} company={props.company} companies={props.companies} setCurrentCompany={props.setCurrentCompany} setCurrentWarehouse={props.setCurrentWarehouse} user={props.user} groups={props.scopes} warehouses={props.warehouses} warehouse={props.warehouse} route={props.route} />
                    { props.scopes.length > 0 ?
                        cloneElement(props.children, {toast: toast, is_sandbox: props.is_sandbox, isLoading: props.isLoading, isMobile:props.isMobile, setLoading: props.setLoading, config: props.config, company: props.company, warehouse: props.warehouse, warehouses: props.warehouses[props.company], user: props.user, token: props.token, scopes: props.scopes, companies: props.companies})
                        :
                        <NewUser isMobile={props.isMobile} user={props.user} />
                    }
                
            </Box>
        </Box>
    )
    
}

export default RequireAuth;
