import React, {useRef, useState, useEffect} from 'react';
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import DetailsPallet from './DetailsPallet';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText'
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography'
import orderHelpers from '../../helpers/orders';
import TransferDialog from './TransferDialog';

function OrderDetails(props) { 

    const order = props.order || {};
    const containerRef = useRef({});

    const [status, setStatus] = useState({});
    const [transferOpen, setTransferOpen] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        setStatus(order.order_status === "order_created" ? {name: "Order Created", class: "created"} :
        order.order_status === "order_acknowledged" ? {name: "Order Acknowledged", class: "acknowledged"} :
        order.order_status === "order_built" ? {name: "Items Built", class: "built"} :
        order.order_status === "order_staged" ? {name: "Order Staged", class: "staged"} :
        order.order_status === "order_shipped" ? {name: "Order Shipped", class: "shipped"} : 
        order.order_status === "order_cancelled" ? {name: "Order Cancelled", class: "cancelled"} :
        order.order_status === "order_production_hold" ? {name: "Order Production Hold", class: "onHold"} : {})
    }, [order.order_status])

    const transfer = async(items) => {
        props.setLoading(true);
        let out = [];
        for(let item in items) {
            if(items[item]) {
                out.push(item);
            }
        }
        await orderHelpers.transferItems(out, order.internal_po_number, props.company, props.token);
        props.setLoading(false);
        setTransferOpen(false);
        console.log("Order transferred!");
    }

    return (
        <Drawer open = {Object.keys(order).length > 0} onClose = {props.setOpenDetails} anchor = "bottom">
            <TransferDialog {...props} items = {order.items} open = {transferOpen} setOpen = {setTransferOpen} transfer = {transfer} />
            <Box style = {{height: "600px"}}  display = "flex" sx = {{m:"auto", mb:"80px", pt:"20px"}}>
                <List dense>
                    <ListItem>
                        <ListItemText 
                            primary = "Order Number" 
                            primaryTypographyProps = {{variant:"h4"}} 
                            secondaryTypographyProps = {{variant:"h5"}} 
                            secondary = {order.internal_po_number || "N/A"} />
                    </ListItem>
                    <ListItem>
                        <ListItemText 
                            primary = "Order Date" 
                            secondary = {order.order_date ? order.order_date.substring(0, 10) : "N/A"}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText 
                            primary = "Vendor" 
                            secondary = {order.vendor_name || "N/A"} />
                    </ListItem>
                    <ListItem>
                        <ListItemText disableTypography 
                            primary = {<Typography variant = "body2">Order Status</Typography>}
                            secondary = {<Typography variant = "body2" className = {status.class}>{status.name}</Typography>}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText 
                            primary = "Acumatica Order" 
                            secondary = {order.acu_order_num || "N/A"}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText 
                            primary = "ASN Status" 
                            secondary = {order.shipments ? order.shipments[0]?.vendor_status === "asn_sent" ?  "Sent" : "Not Sent" : "N/A" }
                            secondaryTypographyProps  = {{ sx: {color: order.shipments ? order.shipments[0]?.vendor_status === "asn_sent" ? "#64D124" : "#DD2700" : "#DD2700" }}}/>
                    </ListItem>
                    {
                        order.order_status !== "order_cancelled" ?
                        <Button sx = {{mt: "10px"}} disabled = {props.isLoading} variant = "contained" onClick = {()=>props.createBulkPullsheet(order)} >
                            Download Bulk Pullsheet
                        </Button>
                        : null
                    }
                </List>
                <Divider sx = {{ml:"10px", mr:"10px"}} orientation = "vertical" flexItem />
                <Box display = "flex" flexDirection = "column">
                    <h3>Customer Info</h3>
                    <Box display = "flex">
                        <List dense>
                            <ListItem>
                                <ListItemText 
                                    primary = "Street Address" 
                                    secondary = {order.address1}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText 
                                    primary = "Street Address 2" 
                                    secondary = {order.address2 || "N/A"}/>
                            </ListItem>
                        </List>
                        <List dense>
                            <ListItem>
                                <ListItemText 
                                    primary = "City" 
                                    secondary = {order.city} />
                            </ListItem>
                            <ListItem>
                                <ListItemText 
                                    primary = "State" 
                                    secondary = {order.state} />
                            </ListItem>
                            <ListItem>
                                <ListItemText 
                                    primary = "Zip Code" 
                                    secondary = {order.zip_code} />
                            </ListItem>
                        </List>
                    </Box>
                </Box>
                <Divider sx = {{ml:"10px", mr:"10px"}} orientation = "vertical" flexItem />
                {order.shipments ? 
                    <Box display = "flex" flexDirection = "column" >
                        <Tabs value = {props.tab} onChange = {props.setTab}>
                            {order.shipments.map((shipment, index) => {
                                return(
                                    <Tab 
                                        value = {index} 
                                        name = {index} 
                                        key = {index} 
                                        label = {`Pallet ${index + 1}`} 
                                    />
                                )
                            })}
                        </Tabs>
                        {order.shipments.map((shipment, index) => {
                            return(
                                <Box ref = {containerRef[index]} key = {index} display = "flex" flexDirection = "column" style = {{overflowX:"hidden"}}>
                                    <Slide mountOnEnter unmountOnExit container = {containerRef[index]?.current} direction = {props.direction} in = {props.tab === index}>
                                        <Box>
                                        {props.tab === index ? <DetailsPallet {...props} status = {status} internal_po_number = {order.internal_po_number} palletNum = {props.tab} shipment = {shipment} />  : null}
                                        </Box>
                                    </Slide>
                                </Box>
                            )
                        })}
                        {/* // {order.shipments.length > props.tab ? <DetailsPallet PalletNum={props.tab} shipment={order.shipments[props.tab]} /> : null} */}
                    </Box> 
                : null
                }
            </Box>
        </Drawer>
    )
}

export default OrderDetails;


