import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import UploadsTable from './UploadsTable';
import bulkHelpers from '../../helpers/bulk';
import Alert from '@mui/material/Alert';


function BulkFileUpload(props) {

    const [error, setError] = useState("");
    const [uploads, setUploads] = useState([]);
    const [selected, setSelected] = useState([]);
        

    const deleteUpload = async() => {
        if(window.confirm(`Delete Upload #${selected[0]}?`)) {
            props.setLoading(true);
            await bulkHelpers.deleteUpload(props.company + "_bulk", {upload_id:selected[0]}, props.token)
            props.setLoading(false);
        }
    }

    const handleSelect = (e) => {
        setSelected(e)
    }

    const getUploads = async () => {
        props.setLoading(true);
        console.log('getting uploads')
        let uploads = await bulkHelpers.getUploads(props.company + "_bulk", {}, props.token)
        setUploads(uploads);
        props.setLoading(false);
    }

        return(
            <Box display="flex" flexDirection="column" className={props.isMobile ? "mobile-top" : "desktop-top"}>
                <Box className={props.isMobile ? "mobile-box" : "desktop-box"}>
                {error !== "" ? <Alert style={{marginTop:"24px", color:"white", width:"80%"}} severity="error" className={`${props.company} ${props.warehouse} error`}>{error}</Alert> : null}
                    <h1>Bulk Uploads</h1> 
                    {selected.length === 1 ? 
                        <Box style={{marginTop:"50px", border: "1px solid black", width:"60%", padding:"20px", paddingTop:"0"}}>
                            <h3>Upload ID: {selected[0]}</h3>
                            <Box display="flex">
                                {props.scopes?.includes("admin") ? <Button style={{marginLeft:"20px"}} onClick={deleteUpload} color="error" variant="contained">Delete</Button> : null}
                            </Box>

                        </Box>
                        : null}
                        <UploadsTable 
                            uploads={uploads}
                            getUploads={getUploads}
                            company={props.company + "_bulk"}
                            warehouse={props.warehouse}
                            token={props.token}
                            isMobile={props.isMobile}
                            setLoading={props.setLoading}
                            setError={setError}
                            handleSelect={handleSelect}
                            selected={selected}
                            is_bulk={true}
                        />
                    </Box>
            </Box>
        )
    
}

export default BulkFileUpload