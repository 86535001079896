import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import {lighten } from '@mui/material/styles';

const columns = [ 
    {
        field: 'id',
        headerName: "ID",
        flex: 1,
        editable: false,
        minWidth: 100
    },
    {
        field: 'internal_sku',
        headerName: 'Internal SKU',
        flex: 1,
        editable: false,
        minWidth: 200
    }, 
    {
        field: "unit_price",
        headerName: "Unit Price",
        flex: 1,
        editable: false,
        minWidth: 100
    },
    {
        field: 'external_price',
        headerName: 'Total Price',
        flex: 1,
        editable: false,
        minWidth: 100
    },
    {
        headerName: 'Quantity',
        field: "quantity",
        sortable: false,
        editable: false,
        minWidth: 100,
    }

]


function DetailsTable(props) {

    return(
        <Box className={props.isMobile ? "mobile-table-box" : "desktop-table-box"} style={{width:"100%"}}>
            <DataGrid
                style={{width:"100%"}}
                rows={props.items}
                className={props.isMobile ? "mobile-table" : "desktop-table"}
                columns={columns}
                rowsPerPageOption = {[25, 50, 100]}
                checkboxSelection = {false}
                getRowClassName={(params) => params.row.class}
                sortModel = {[{field:'id', sort: 'desc'}]}
            />
        </Box>   
    )


}

export default DetailsTable;