import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import WarehousePriorityTable from './WarehousePriorityTable';
import { CSVReader } from 'react-papaparse';
import orderHelper from '../../helpers/orders';
import Alert from '@mui/material/Alert';
import fileHelpers from '../../helpers/files';

const buttonRef = React.createRef();

function WarehousePriority(props) {

    const [vendors, setVendors] = useState([]);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        const getVendors = async() => {
            props.setLoading(true);
            let vendors = await orderHelper.getVendorPriority(props.company, props.token);
            vendors = vendors.sort((a, b) => {
                let textA = a.vendor_name.toLowerCase();
                let textB = b.vendor_name.toLowerCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            })
            for(let i = 0; i < vendors.length; i++) {
                vendors[i].id = i;
                vendors[i].key = i;
            }
            setVendors(vendors);
            props.setLoading(false);
        }
        getVendors();
    }, [refresh])

    const updateAll = async(warehouse) => {
        for(let vendor of vendors) {
            if(vendor.warehouse !== warehouse)
                await updateWarehouse(vendor.vendor_name, warehouse, false);
        }
        setRefresh(!refresh);
    }

    const updateWarehouse = async(vendor_name, warehouse, refreshAtDone=true) => {
        await orderHelper.updateVendorPriority(props.company, props.token, JSON.stringify({vendor_name: vendor_name, warehouse:warehouse}))
        if(refreshAtDone) setRefresh(!refresh);
    }

        return(
            <Box display="flex" flexDirection="column" className={props.isMobile ? "mobile-top" : "desktop-top"}>
                <Box className={props.isMobile ? "mobile-box" : "desktop-box"}>
                    <h1>Warehouse Priority</h1> 
                    {vendors.length > 0 ? 
                    <Box display="flex" style={{marginTop:"10px"}}>
                        <Button variant="contained" size="large" style={{backgroundColor: "#0099FF", marginRight:"20px"}} onClick={()=>updateAll("Kentucky")}>Set All Kentucky</Button>
                        <Button variant="contained" size="large" style={{backgroundColor: "#CEB08D"}} onClick={()=>updateAll("Pennsylvania")}>Set All Pennsylvania</Button>
                    </Box>
                    : null}
                    <WarehousePriorityTable 
                        vendors={vendors}
                        updateWarehouse={updateWarehouse}
                        {...props}
                    />
                </Box>
            </Box>
        )
    
}

export default WarehousePriority